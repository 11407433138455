import React, { useState, useEffect, useCallback } from "react";
import {
  CheckboxToggleIcon,
  NoOfferFileIcon,
  SearchIcon,
} from "../../../assets/svgs/svgs";
import axios from "axios";
import {
  AddOfferAmazonAffiliate,
  GetUserEventsAndDiscountsAPICALL,
} from "../../../api/api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Loader } from "../../../components";
import { CircularProgress } from "@mui/material";
import DotLoading from "../../../components/ui/Loading/DotLoading";
const ListAmazonDialog = ({ handleClose }) => {
  const { id } = useParams();
  const [data, setData] = useState([]); // Example state for the data array
  const [keyword, setKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [resultCount, setResultCount] = useState(0);
  // Utility function to debounce the search input
  const [selectedCards, setSelectedCards] = useState([]);
  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const debouncedSearch = useCallback(
    debounce((searchTerm, currentPage) => {
      // alert("bharat");
      listAmazonAffiliateOffer(searchTerm, currentPage);
    }, 1000),
    []
  );
  const handleSearchButton = () => {
    debouncedSearch(keyword, page);
  };

  useEffect(() => {
    // if (page !== 0) {
    keyword && debouncedSearch(keyword, page);
    // } else {
    // alert("baby");
    // }
    setSelectedCards([]);
  }, [page]);

  const listAmazonAffiliateOffer = async (searchTerm, currentPage) => {
    setIsLoading(true);
    try {
      const res = await GetUserEventsAndDiscountsAPICALL(
        searchTerm,
        currentPage
      );
      console.log(res);
      setData(res?.data);
      setTotalPages(res?.totalPages);
      setResultCount(res?.totalResultCount);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const handleInputChange = (e) => {
    console.log(e);
    setKeyword(e.target.value);
    setPage(1); // Reset to first page on new search
  };
  const handleEnterInput = (e) => {
    console.log(e);
    if (e.which === 13) {
      setPage(1);
      listAmazonAffiliateOffer(keyword, 1);
    }
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };
  const handleCardSelect = (offer) => {
    console.log(offer);
    // setSelectedCards((prevSelected) => {
    //   if (prevSelected.includes(affiliateId)) {
    //     return prevSelected.filter((id) => id !== affiliateId);
    //   } else {
    //     return [...prevSelected, affiliateId];
    //   }
    // });
    setSelectedCards((prevSelected) => {
      if (prevSelected.includes(offer)) {
        return prevSelected.filter(
          (dd) => dd.affiliateId !== offer?.affiliateId
        );
      } else {
        return [...prevSelected, offer];
      }
    });

    console.log(selectedCards);
  };
  const handleContinue = async () => {
    if (selectedCards.length > 0) {
      // Call the API with the selected cards
      console.log("Selected Cards:", selectedCards);

      const response = await AddOfferAmazonAffiliate({
        offers: selectedCards,
        userId: id,
      });
      console.log(response);
      toast.success(response.message);
      handleClose();
    } else {
      alert("Please select at least one item.");
    }
  };

  const renderPageButtons = () => {
    const pageButtons = [];
    const startPage = Math.max(1, page - 3); // Show 2 pages before the current page
    const endPage = Math.min(totalPages, page + 3); // Show 2 pages after the current page

    // Add the first page
    if (startPage > 1) {
      pageButtons.push(
        <button
          key={1}
          className={`px-3 py-2 rounded ${
            page === 1 ? "bg-[#2CCCD3]" : "bg-white"
          }`}
          onClick={() => handlePageChange(1)}
        >
          <p
            className={`text-[10px] h-[15px] ${
              page === 1 ? "text-white" : "text-[#687A7D]"
            }`}
          >
            1
          </p>
        </button>
      );

      if (startPage > 2) {
        pageButtons.push(
          <span key="start-ellipsis" className="px-3 py-2 text-[#687A7D]">
            ...
          </span>
        );
      }
    }

    // Add page buttons for the current range
    for (let pageNum = startPage; pageNum <= endPage; pageNum++) {
      pageButtons.push(
        <button
          key={pageNum}
          className={`px-3 py-2 rounded ${
            pageNum === page ? "bg-[#2CCCD3]" : "bg-white"
          }`}
          onClick={() => handlePageChange(pageNum)}
        >
          <p
            className={`text-[10px] h-[15px] ${
              pageNum === page ? "text-white" : "text-[#687A7D]"
            }`}
          >
            {pageNum}
          </p>
        </button>
      );
    }

    // Add the last page
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageButtons.push(
          <span key="end-ellipsis" className="px-3 py-2 text-[#687A7D]">
            ...
          </span>
        );
      }

      pageButtons.push(
        <button
          key={totalPages}
          className={`px-3 py-2 rounded ${
            page === totalPages ? "bg-[#2CCCD3]" : "bg-white"
          }`}
          onClick={() => handlePageChange(totalPages)}
        >
          <p
            className={`text-[10px] h-[15px] ${
              page === totalPages ? "text-white" : "text-[#687A7D]"
            }`}
          >
            {totalPages}
          </p>
        </button>
      );
    }

    return pageButtons;
  };
  return (
    <div className="min-h-[50vh]">
      <p className="text-xl font-semibold capitalize text-center mt-4">
        Search your deal
      </p>
      <div className="relative w-full bg-white flex items-center gap-2.5 my-[15px] min-w-[60vw]">
        <SearchIcon className="absolute left-3 w-6 h-6 text-gray-500" />

        <input
          className="flex-1 h-12 pl-12 py-2 px-3.5 active:ring-offset-transparent"
          type="text"
          placeholder="Search a keyword (e.g -Shoes, protein, etc)"
          value={keyword}
          onChange={handleInputChange}
          onKeyDown={handleEnterInput}
        />
        <button
          className={`bg-[#2cccd3] flex justify-center items-center  px-8 py-2 h-12 rounded-lg cursor-pointer text-white ${
            isLoading ? "cursor-not-allowed" : ""
          }`}
          onClick={handleSearchButton}
        >
          {isLoading ? <DotLoading /> : "Search"}
        </button>
      </div>

      {isLoading ? (
        <div className="text-center flex flex-col justify-center items-center h-full">
          <DotLoading />
        </div>
      ) : data.length !== 0 ? (
        <div className="grid grid-cols-4 gap-3 mt-4 overflow-y-scroll max-h-[50vh]">
          {data.map((offer) => (
            <Card
              key={offer.affiliateId}
              className="bg-white p-4 rounded shadow"
              {...offer}
              isSelected={selectedCards.some(
                (selectedOffer) =>
                  selectedOffer.affiliateId === offer.affiliateId
              )}
              onSelect={() => handleCardSelect(offer)}
            />
          ))}
        </div>
      ) : (
        ""
      )}
      {!isLoading && data.length !== 0 ? (
        <div className="flex justify-start items-center mt-4 w-full overflow-y-scroll">
          <button
            className="p-1 mr-2 bg-white text-[#2CCCD3] rounded"
            onClick={() => handlePageChange(page - 1)}
            disabled={page === 1}
          >
            <KeyboardArrowLeftIcon fontSize="small" className="text-[10px]" />
          </button>
          <div className="flex items-center justify-center gap-2">
            {renderPageButtons()}
          </div>
          <button
            className="p-1 ml-2 bg-white text-[#2CCCD3] rounded"
            onClick={() => handlePageChange(page + 1)}
            disabled={page === totalPages}
          >
            <KeyboardArrowRightIcon fontSize="small" className="text-[10px]" />
          </button>
          {resultCount && (
            <span>
              <p className="font-semibold text-sm whitespace-nowrap text-[#687A7D]">{`${data?.length} showing`}</p>
            </span>
          )}
        </div>
      ) : !isLoading ? (
        <div
          className="
        flex flex-col mx-auto py-[10vh] justify-between items-center gap-6 "
        >
          <NoOfferFileIcon />
          <p className="text-center text-base font-semibold text-[#687A7D]">
            Search results with show here
          </p>
        </div>
      ) : (
        ""
      )}
      {selectedCards.length !== 0 && (
        <div className="flex justify-end gap-5 items-center mt-4">
          <p className="text-base font-semibold">{`${selectedCards.length} items selected`}</p>
          <button
            className="py-3 px-4 bg-[#2CCCD3] text-white rounded text-base font-bold"
            onClick={handleContinue}
            disabled={selectedCards.length === 0}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

const Card = ({
  caption,
  poster,
  currentPrice,
  discount,
  listingPrice,
  isSelected,
  onSelect,
  affiliateId,
}) => {
  const handleCheckboxChange = (e) => {
    console.log("input clicking");
    e.stopPropagation();
    // onSelect();
  };

  return (
    <div
      className={`flex flex-col relative bg-white p-4 gap-3 rounded-lg shadow cursor-pointer`}
      onClick={onSelect}
    >
      <input
        type="checkbox"
        checked={isSelected}
        onChange={handleCheckboxChange}
        className={`self-end h-6 w-6 hidden absolute right-2.5 top-2.5 ${
          isSelected ? " text-teal-600 border-red-300 rounded-full" : ""
        }`}
        id={affiliateId}
      />
      <CheckboxToggleIcon
        className={`self-end h-6 w-6 absolute right-2.5 top-2.5`}
        checked={isSelected}
        height={25}
        width={25}
      />
      <img
        src={poster}
        className="h-[50px] w-[80px] self-center"
        alt="poster"
      />
      <p
        className="text-xs text-center font-medium text-[#2C3030]"
        title={caption}
      >
        {caption?.length > 18 ? caption.slice(0, 18) + "...." : caption}
      </p>
      <div className="flex items-center justify-center gap-2">
        {currentPrice && (
          <span className="text-sm font-bold text-[#2C3030]">{`$${currentPrice}`}</span>
        )}
        {discount && (
          <span className="text-sm font-bold text-[#F6285F]">{`-${discount}%`}</span>
        )}
      </div>
      {listingPrice && (
        <div className="flex justify-center items-center text-center">
          <span className="text-xs font-normal text-[#2C3030]">{`MSRP.$${listingPrice}`}</span>
        </div>
      )}
    </div>
  );
};

export default ListAmazonDialog;
