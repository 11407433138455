import React from "react";
import RadioButton from "./RadioButton";
import { getTextColor } from "../../../../utils/utils";
import DotLoading from "../../../../components/ui/Loading/DotLoading";

const RadioButtonContainer = ({
  loading,
  data = [],
  selectedData,
  name = "Radio Buttons",
  onSelect,
}) => {
  const handleRadioSelect = (id) => {
    if (onSelect) {
      onSelect(id);
    }
  };

  return (
    <div className="grid grid-cols-2 gap-3 md:max-w-[70vh]">
      {loading ? (
        <div className="flex flex-col col-span-2 justify-items-center items-center w-full ">
          <DotLoading />
        </div>
      ) : data?.length === 0 ? (
        <p className="text-center col-span-3 font-montserrat text-sm w-full opacity-65">{`No ${name} Their!`}</p>
      ) : (
        <>
          {data?.map((item, idx) => (
            <RadioButton
              key={item?.id || idx}
              buttonText={item?.name}
              buttonBgColor={item?.color || "#E8F0F2"}
              buttonColor={getTextColor(item?.color || "#E8F0F2")}
              isChecked={selectedData === item?.id}
              onClick={() => handleRadioSelect(item?.id)}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default RadioButtonContainer;
