import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import Loader from "../../../components/atoms/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../../components";
import SoftDialog from "../../../components/molecules/dialogs";
import {
  GetAdsManagerUsersById,
  GetUserEventsAndDiscounts,
  UpdateCouponStatus,
  UpdateEventStatus,
  updateRankOfDiscountCoupon,
} from "../../../api/api";
import ViewEventDetails from "./viewEventDetails";
import ViewCoupon from "./ViewCoupouns";
import CreateTicketForm from "./CreateTicket";
import FormComponent from "./CreateDiscount";
import {
  CalendarIcon,
  CouponsIcon,
  OfferIcon,
  SlideLeftIcon,
} from "../../../assets/svgs/svgs";
import CardComponent from "../../../components/DiscountCard/DisountCardComponent";
import { SuccessFulllStep, SuccessFulllStep1 } from "./SuccessIndicaters";
import EditDiscountForm from "./editDiscount";
import EditTicketForm from "./editTicket";
import AcceptRejectModel from "../../admasterUsers/Accept_RejectModel";
import ListAmazonDialog from "./ListAmazonDialog";
import ViewAmazonAffiliateCoupon from "./ViewAmazonAffiliateCoupon";

import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import SortableCard from "./SortableDiscountCard";
import { toast } from "react-toastify";
import PageHeading from "../../../components/ui/Heading/PageHeading/PageHeading";
import classNames from "classnames";
import EstuModal from "../../../components/ui/Modal/EstuModal/EstuModal";
// import sortableKeyboardCoordinates from "@dnd-kit/modifiers";

const DiscountCouponsPage = () => {
  const { id } = useParams();
  const [Data, setData] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [user, setUser] = useState("");
  const [showFormDiscount, setShowFormDiscount] = useState(false);
  const [showFormTicket, setShowFormTicket] = useState(false);
  const [showListAmazon, setShowListAmazon] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [formDataDiscount, setFormDataDiscount] = useState({
    headline: "",
    caption: "",
    website: "",
    callToAction: "",
    featureCoupon: true,
    couponCode: "",
    validFrom: "",
    validTo: "",
    isQuantityCoupon: true,
    poster: null,
    quantityLeft: 0,
    userId: id,
    status: "active",
    industry: [],
    // description: "",
    howToRedeem: "",
  });
  const [formDataTicket, setFormDataTicket] = useState({
    headline: "",
    description: "",
    // where: "",
    when: "",
    tillDate: "",
    time: "",
    poster: null,
    callToAction: "",
    isPaidEvent: "",
    userId: id,
    website: "",
    status: "active",
    location: {
      address1: " ",
      city: "",
      country: "",
      state: "",
      zipCode: "",
    },
  });
  const location = useLocation();
  console.log(location);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [stepTicket, setStepTicket] = useState(0);
  const [active, setActive] = useState(
    (location?.state?.from === "event" && 1) || 0
  );
  const [view, setView] = useState({
    view: false,
    viewData: 0,
    data: {},
    user: user,
  });
  const [showEditDiscountForm, setShowEditDiscountForm] = useState(false);
  const [showEditTicketForm, setShowEditTicketForm] = useState(false);
  const [update, setUpdate] = useState(false);
  const [showApproveModel, setShowApproveModel] = useState(false);
  const [showRejectionModel, setShowRejectionModel] = useState(false);
  const [selectedDataValue, setSelectedDataValue] = useState({});
  const navigate = useNavigate();
  // const [Username, setUsername] = useState("Username");

  const getUser = async () => {
    try {
      const res = await GetAdsManagerUsersById(id);
      setUser(res);
    } catch (error) {
      console.error(error);
    }
  };

  const approveData = async () => {
    if (active === 0) {
      try {
        const res = await UpdateCouponStatus(selectedDataValue.id, true);
        setShowApproveModel(false);
        setUpdate((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await UpdateEventStatus(selectedDataValue.id, true);
        setShowApproveModel(false);
        setUpdate((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const rejectData = async () => {
    if (!rejectMessage) {
      return toast.info("Please mention reject reason");
    }

    if (active === 0) {
      try {
        const res = await UpdateCouponStatus(
          selectedDataValue.id,
          false,
          rejectMessage
        );
        setShowRejectionModel(false);
        setUpdate((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await UpdateEventStatus(
          selectedDataValue.id,
          false,
          rejectMessage
        );
        setShowRejectionModel(false);
        setUpdate((prev) => !prev);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const resetFomDetails = () => {
    setFormDataDiscount({
      headline: "",
      caption: "",
      website: "",
      callToAction: "",
      featureCoupon: true,
      couponCode: "",
      validFrom: "",
      validTo: "",
      isQuantityCoupon: true,
      poster: null,
      quantityLeft: 0,
      userId: id,
      status: "active",
      industry: [],
      // description: "",
      howToRedeem: "",
    });
    setFormDataTicket({
      headline: "",
      description: "",
      // where: "",
      when: "",
      tillDate: "",
      time: "",
      poster: null,
      callToAction: "",
      isPaidEvent: "",
      userId: id,
      website: "",
      status: "active",
      location: {
        address1: " ",
        city: "",
        country: "",
        state: "",
        zipCode: "",
      },
    });
  };

  const updateRankDiscountCard = async (data) => {
    // console.log(data);
    if (active === 0) {
      //discount coupons api is only allowed
      try {
        const response = await updateRankOfDiscountCoupon(data);
        // console.log(response);
        toast.success(response?.message);
        getAPI();
      } catch (error) {
        console.log(error);
        toast.error(
          error?.response?.data?.error
            ? error?.response?.data?.error
            : "Something wrong with rank change"
        );
      }
    } else {
      toast.info("discount coupons can only change rank as of now");
    }
    // console.log(active);
  };
  const handleDragEnd = (event) => {
    const { active: draggedActive, over } = event;

    // console.log("event", event);
    const cdata = event?.active?.data?.current;

    // console.log(active);
    if (active === 0) {
      if (draggedActive.id !== over.id) {
        setData((items) => {
          const oldIndex = items.findIndex(
            (item) => item.id === draggedActive.id
          );
          const newIndex = items.findIndex((item) => item.id === over.id);
          return arrayMove(items, oldIndex, newIndex);
        });

        // Make API call to update the order in the backend
        updateRankDiscountCard({
          couponId: cdata?.cardData?.id,
          currentUser: cdata?.cardData?.userId,
          rank: event?.over?.data?.current?.cardRank,
        });
      }
    } else {
      toast.info("Cannot rearrange other than discount coupon");
      return;
    }
  };

  useEffect(() => {
    setShowFormDiscount(false);
    setShowFormTicket(false);
    setShowListAmazon(false);
    setView({ ...view, view: false });
  }, [showEditDiscountForm, showEditTicketForm]);

  useEffect(() => {
    getUser();
    setRejectMessage("");
    // console.log(user);
  }, [id]);

  const getAPI = async () => {
    setDataLoading(true);
    try {
      const res = await GetUserEventsAndDiscounts(active, id);
      setData(res);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setDataLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
    // setUsername(localStorage.getItem("user_name") || "Username");
  }, [
    showFormDiscount,
    active,
    showFormTicket,
    showEditDiscountForm,
    showEditTicketForm,
    view,
    update,
  ]);

  return (
    <div className="px-4 flex flex-col md:gap-6 gap-4 outlet overflow-y-scroll justify-start">
      <div
        className={classNames(
          "flex md:flex-row flex-row justify-between items-center gap-4"
        )}
      >
        <div className="flex flex-row justify-start items-center gap-2">
          {location?.state?.lastPage && (
            <SlideLeftIcon
              onClick={() => navigate(location?.state?.lastPage)}
              className="cursor-pointer"
            />
          )}

          <PageHeading text={"Back"} />
        </div>
        <div>
          {active === 0 ? (
            <Button
              onClick={(_) => setShowFormDiscount(true)}
              bgColor="#2CCCD3"
              color="#fff"
              borderRadius="10px"
              icon={<CouponsIcon />}
              text={"Create discount coupon"}
            />
          ) : active === 1 ? (
            <Button
              onClick={(_) => setShowFormTicket(true)}
              bgColor="#2CCCD3"
              color="#fff"
              borderRadius="10px"
              icon={<CalendarIcon />}
              text={"Create new event"}
            />
          ) : (
            <Button
              onClick={(_) => setShowListAmazon(true)}
              bgColor="#2CCCD3"
              color="#fff"
              borderRadius="10px"
              icon={<OfferIcon />}
              text={"List a deal"}
            />
          )}
        </div>
      </div>
      <hr />
      <div className="flex-1 flex flex-col gap-4">
        <div className={`flex flex-row gap-3 items-center overflow-x-scroll`}>
          <div
            onClick={(_) => setActive(0)}
            className={`${style.buttonTab} ${
              active === 0 ? style.active : ""
            }  cursor-pointer`}
          >
            Discount coupons
          </div>
          <div
            onClick={(_) => setActive(1)}
            className={`${style.buttonTab} ${
              active === 1 ? style.active : ""
            } cursor-pointer`}
          >
            Hosted event
          </div>
          {user?.amazonAffiliate && (
            <div
              onClick={(_) => setActive(2)}
              className={`${style.buttonTab} ${
                active === 2 ? style.active : ""
              }  cursor-pointer whitespace-nowrap`}
            >
              Amazon Affiliate deals
            </div>
          )}
        </div>
        <div className="px-4 flex-1 overflow-y-scroll bg-white">
          <DndContext
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            // modifiers={[sortableKeyboardCoordinates]}
          >
            {loading ? (
              <div className={`flex flex-row justify-center align-center`}>
                <Loader />
              </div>
            ) : (
              <>
                {dataLoading ? (
                  <Loader />
                ) : (
                  <SortableContext
                    items={Data.map((item) => item.id)}
                    strategy={rectSortingStrategy}
                  >
                    <div
                      className={
                        Data?.length > 0
                          ? `md:flex md:flex-row grid grid-cols-1 gap-2 align-center h-auto py-3 flex-wrap`
                          : `flex flex-row gap-2 align-center py-3 h-600`
                      }
                    >
                      {Data.length > 0 ? (
                        Data?.map((cardData, idx) => (
                          <SortableCard
                            key={cardData.id}
                            cardData={cardData}
                            cardIndexRank={idx + 1}
                            //   fetchData={fetchData}

                            type={active}
                            setShowApproveModel={setShowApproveModel}
                            setSelectedDataValue={setSelectedDataValue}
                            setShowRejectionModel={setShowRejectionModel}
                            view={setView}
                            typeLabel={active === 2 ? "amazon" : ""}
                          />
                        ))
                      ) : (
                        //     <CardComponent
                        //       cardData={cardData}
                        //       type={active}
                        //       setShowApproveModel={setShowApproveModel}
                        //       setSelectedDataValue={setSelectedDataValue}
                        //       setShowRejectionModel={setShowRejectionModel}
                        //       view={setView}
                        //       typeLabel={active === 2 ? "amazon" : ""}
                        //     />
                        //   ))
                        <EmptyCardComponent active={active} />
                      )}
                    </div>
                  </SortableContext>
                )}
              </>
            )}
          </DndContext>
          <EstuModal
            widthFull={true}
            title={step === 0 ? "Create new discount coupon" : ""}
            open={showFormDiscount}
            handleClose={(_) => {
              setShowFormDiscount(false);
              if (step === 1) {
                setStep(0);
                setFormDataDiscount({
                  headline: "",
                  caption: "",
                  website: "",
                  callToAction: "",
                  featureCoupon: true,
                  couponCode: "",
                  validFrom: "",
                  validTo: "",
                  isQuantityCoupon: true,
                  poster: null,
                  quantityLeft: 0,
                  userId: id,
                  status: "active",
                  industry: [],
                  // description: "",
                  howToRedeem: "",
                });
              }
            }}
          >
            {step === 0 ? (
              <FormComponent
                setStep={setStep}
                formData={formDataDiscount}
                setFormData={setFormDataDiscount}
              />
            ) : (
              <SuccessFulllStep />
            )}
          </EstuModal>
          <EstuModal
            title={step === 0 ? "Create new Event" : ""}
            open={showFormTicket}
            handleClose={(_) => {
              setShowFormTicket(false);
              if (stepTicket === 1) {
                setStepTicket(0);
                setFormDataTicket({
                  headline: "",
                  description: "",
                  // where: "",
                  when: "",
                  tillDate: "",
                  time: "",
                  poster: null,
                  callToAction: "",
                  isPaidEvent: "",
                  userId: id,
                  website: "",
                  status: "active",
                  location: {
                    address1: " ",
                    city: "",
                    country: "",
                    state: "",
                    zipCode: "",
                  },
                });
              }
            }}
          >
            {stepTicket === 0 ? (
              <CreateTicketForm
                setStep={setStepTicket}
                step={stepTicket}
                formData={formDataTicket}
                user={user}
                setFormData={setFormDataTicket}
              />
            ) : (
              <SuccessFulllStep1 />
            )}
          </EstuModal>
          <EstuModal
            title={step === 0 ? "List amazon affiliate deals" : ""}
            open={showListAmazon}
            handleClose={(_) => {
              setShowListAmazon(false);
              if (step === 1) {
                setStep(0);
                setFormDataDiscount({
                  headline: "",
                  caption: "",
                  website: "",
                  callToAction: "",
                  featureCoupon: true,
                  couponCode: "",
                  validFrom: "",
                  validTo: "",
                  isQuantityCoupon: true,
                  poster: null,
                  quantityLeft: 0,
                  userId: id,
                  status: "active",
                  industry: [],
                  howToRedeem: "",
                });
                // console.log("bharat");
              }
            }}
          >
            <ListAmazonDialog
              handleClose={(_) => {
                setShowListAmazon(false);
                getAPI();
              }}
            />
          </EstuModal>
          <EstuModal
            handleClose={(_) => setView({ ...view, view: false })}
            title={
              active === 0
                ? "Coupon details"
                : active === 1
                ? "Event details"
                : "Affiliate coupon details"
            }
            open={view?.view}
          >
            {active === 0 ? (
              <ViewCoupon
                data={view.data}
                user={user}
                setView={setView}
                setFormDataDiscount={setFormDataDiscount}
                update={setUpdate}
                setEdit={setShowEditDiscountForm}
              />
            ) : active === 1 ? (
              <ViewEventDetails
                data={view.data}
                setView={setView}
                setFormDataDiscount={setFormDataTicket}
                update={setUpdate}
                setEdit={setShowEditTicketForm}
              />
            ) : (
              <ViewAmazonAffiliateCoupon
                data={view.data}
                user={user}
                setView={setView}
                update={setUpdate}
              />
            )}
          </EstuModal>
          <EstuModal
            handleClose={(_) => {
              setShowEditDiscountForm(false);
              resetFomDetails();
            }}
            title={"Edit Discount Coupon"}
            open={showEditDiscountForm}
          >
            <EditDiscountForm
              formData={formDataDiscount}
              setFormData={setFormDataDiscount}
              show={setShowEditDiscountForm}
              reset={resetFomDetails}
            />
          </EstuModal>
          <EstuModal
            handleClose={(_) => {
              setShowEditTicketForm(false);
              resetFomDetails();
            }}
            title={"Edit Ticket"}
            open={showEditTicketForm}
          >
            <EditTicketForm
              formData={formDataTicket}
              setFormData={setFormDataTicket}
              reset={resetFomDetails}
              show={setShowEditTicketForm}
            />
          </EstuModal>
          <AcceptRejectModel
            title={`Approve ${active === 0 ? "Coupons" : "Event"}`}
            handleClose={(_) => setShowApproveModel(false)}
            open={showApproveModel}
            onClick={approveData}
          />
          <AcceptRejectModel
            title={`Reject ${active === 0 ? "Coupons" : "Event"}`}
            showReject={true}
            handleClose={(_) => setShowRejectionModel(false)}
            open={showRejectionModel}
            onClick={rejectData}
            rejectMessage={rejectMessage}
            setRejectMessage={setRejectMessage}
            reasonAdd={true}
          />{" "}
        </div>
      </div>
    </div>
  );
};

export default DiscountCouponsPage;

const EmptyCardComponent = ({ active }) => {
  return (
    <div className={style.emptycardContainer}>
      {active == 0
        ? "No Discounts Created"
        : active == 1
        ? "No Events Created"
        : "No Amazon Affiliate deals"}
    </div>
  );
};
