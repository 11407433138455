import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const superAdmins = ["alex@themagnetic.co", "bharatrav8077@gmail.com"];
  //   const email = ["social@estulife.com"];
  const marketing = ["social@estulife.com"]; // Marketing
  const visitors = ["vitor.serafim@v4company.com"];
  const [userEmail, setUserEmail] = useState();

  //check if user can access specific feature or route in future
  const canAccess = () => {
    if (superAdmins.includes(userEmail)) {
      return true;
    }
    const department = userDepartment(userEmail);
    if (department === "MARKETING") {
      return true;
    }
    return email.includes(userEmail) ? true : false;
  };

  const userDepartment = (email) => {
    switch (email || userEmail) {
      case "alex@themagnetic.co":
        return "SUPERADMIN";
      case "bharatrav8077@gmail.com":
        return "SUPERADMIN";
      case "social@estulife.com":
        return "MARKETING";
      case "vitor.serafim@v4company.com":
        return "VISITOR";
      default:
        return "ADMIN"; // Default role for all users
    }
  };

  const value = {
    canAccess,
    userDepartment,
    setUserEmail,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
