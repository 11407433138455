import React, { useEffect, useRef, useState } from "react";
import {
  createCampaignAPI,
  getCampaignCodeAPI,
  getCampaignListAPI,
  getMatchingTransactionsAPI,
  processTransactionAPI,
  toggleActiveStatusCampaignAPI,
  updateCampaignAPI,
} from "../../api/api";
import UsersIcon from "../../assets/svgs/user_admaster.svg";
import { ImageUpload } from "../adsmaster/discountCoupons/imageUpload";
import campaignOffer from "../../assets/svgs/campaignOffer.svg";
import { Box } from "@mui/material";
import bg_logo_estu from "../../assets/imgs/bg_logo_estu.png";

import dayjs from "../../utils/dayjs";
import { toast } from "react-toastify";
import ViewCampaignInsight from "../adsmaster/ViewCampaignInsight";
import classNames from "classnames";

import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import Preview from "./components/Preview";
import OpenAndEdit from "./components/OpenAndEdit";
import TransactionCodesManager from "./components/TransactionCodesManager";
import { useSearchParams } from "react-router-dom";
import { tabs } from "./config";
import CustomerReportTab from "../../components/dashboard/CustomerReportTab";
import { FaSpinner } from "react-icons/fa";
import DotLoading from "../../components/ui/Loading/DotLoading";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";

const CampaignManagement = () => {
  // type have 2 values percentage and fixed
  const [searchParams, setSearchParams] = useSearchParams();
  const newParams = new URLSearchParams(searchParams);
  let stateActiveTab = newParams.get("type") || tabs[0].value;
  const [active, setActive] = useState(stateActiveTab);
  const [openModal, setOpenModal] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const [loading, setLoading] = useState(false);
  const [cData, setCData] = useState([]);
  const [tData, setTData] = useState([]);
  const [codes, setCodes] = useState([]);
  const [isDateValid, setIsDateValid] = useState(true);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [viewCampInsight, setViewCampInsight] = useState(false);
  const [isUpdateState, setIsUpdateState] = useState(false);

  const [dropdown, setDropdown] = useState(null);

  const dropdownRef = useRef(null);

  const [formDataC, setFormDataC] = useState({
    name: "",
    description: "",
    banner: "",
    transactionCode: "",
    startDate: dayjs()?.startOf("day")?.format("YYYY-MM-DD"),
    endDate: dayjs()?.endOf("day")?.format("YYYY-MM-DD"),
    discountPercentage: "",
    maxDiscountAmount: "",
    totalBudget: "",
    headline: "",
    minOrderAmount: "",
    type: "percentage",
  });

  const handleDescriptionClick = (field, event, rowData) => {
    const rect = event.target.getBoundingClientRect();
    setDropdown({
      field,
      data: rowData[field],
      position: {
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      },
    });
  };

  const closeDropdown = () => {
    setDropdown(null);
  };
  const getAPI = async () => {
    setLoading(true);

    try {
      const res = await getCampaignListAPI();

      if (res.status === "success") {
        setCData(res?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getCodesAPI = async () => {
    setLoading(true);

    try {
      const res = await getCampaignCodeAPI();
      if (res.status === "success") {
        setCodes(res?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const getTransactionAPI = async () => {
    setLoading(true);

    try {
      const res = await getMatchingTransactionsAPI();
      // if (res.status === "success") {
      setTData(res?.data);
      // }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleProcess = async (id) => {
    setLoading(true);
    try {
      const response = await processTransactionAPI({ id: id });
      if (response.status === "success") {
        toast.success(
          response?.message || "Transaction processed successfully!"
        );
        getTransactionAPI();
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to process!");
    } finally {
      setLoading(false);
    }
  };

  const setTab = (type) => {
    setActive(type);
    setSearchParams({ type });
  };
  useEffect(() => {
    if (active === tabs[2]?.value) {
      //tabs[2].value ==transactions
      getTransactionAPI();
    } else {
      getAPI();
      getCodesAPI();
    }
  }, [active]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handlePreview = (data) => {
    setIsPreviewOpen(true);
    setSelectedCampaign(data);
  };
  const handleBannerUpload = async (event) => {
    await ImageUpload(event, setFormDataC, "banner");
  };
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setViewMode(false);
    setFormDataC({
      name: "",
      description: "",
      banner: "",
      transactionCode: "",
      startDate: dayjs()?.startOf("day")?.format("YYYY-MM-DD"),
      endDate: dayjs()?.endOf("day")?.format("YYYY-MM-DD"),
      discountPercentage: "",
      maxDiscountAmount: "",
      totalBudget: "",
      headline: "",
      minOrderAmount: "",
      type: "percentage",
    });
    setIsUpdateState(false);
    setSelectedCampaign(null);
  };

  const handleStartDateChange = (newValue) => {
    const formattedStartDate = dayjs(newValue)
      ?.startOf("day")
      ?.format("YYYY-MM-DD");
    if (
      formDataC.endDate &&
      dayjs(formattedStartDate).isAfter(
        dayjs(formDataC.endDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(false);
      toast.error("Start date cannot be greater than end date.");
    }
    setFormDataC({
      ...formDataC,
      startDate: formattedStartDate,
    });
    if (
      formattedStartDate &&
      formDataC.endDate &&
      !dayjs(formattedStartDate).isAfter(
        dayjs(formDataC.endDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(true);
    }
  };

  const handleEndDateChange = (newValue) => {
    const formattedEndDate = dayjs(newValue)
      ?.endOf("day")
      ?.format("YYYY-MM-DD");
    if (
      formDataC.startDate &&
      dayjs(formattedEndDate).isBefore(
        dayjs(formDataC.startDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(false);
      toast.error("End date cannot be before start date.");
    }
    setFormDataC({
      ...formDataC,
      endDate: formattedEndDate,
    });

    if (
      formattedEndDate &&
      formDataC.startDate &&
      !dayjs(formattedEndDate).isBefore(
        dayjs(formDataC.startDate)?.format("YYYY-MM-DD")
      )
    ) {
      setIsDateValid(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isDateValid) {
      toast.error("Start date and end date must be set.");
      return;
    }
    // console.log(formDataC);

    const APIData = {
      ...formDataC,
      ...(formDataC.type === "fixed"
        ? { fixedAmount: formDataC.fixedAmount }
        : {
            discountPercentage: formDataC.discountPercentage,
            maxDiscountAmount: formDataC.maxDiscountAmount,
          }),
    };
    if (formDataC.type === "fixed") {
      delete APIData.discountPercentage;
      delete APIData.maxDiscountAmount;
    } else if (formDataC.type === "percentage") {
      delete APIData.fixedAmount;
    }
    console.log(APIData);

    try {
      if (!isUpdateState) {
        const response = await createCampaignAPI(APIData);
        if (response?.status == "success") {
          toast.success(response?.message || "Successfully created");
          getAPI();
        }
      } else {
        const response = await updateCampaignAPI(APIData);
        if (response?.status == "success") {
          toast.success(response?.message || "Successfully created");
          getAPI();
        }
      }

      handleCloseModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleView = (campaign) => {
    setSelectedCampaign(campaign);
    setViewCampInsight(true);
  };
  const handleEdit = (campaign) => {
    setFormDataC(campaign);
    setSelectedCampaign(campaign);
    setIsUpdateState(true);
    handleOpenModal();
  };
  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setSelectedCampaign(null);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 50 },

    {
      field: "banner",
      headerName: "Logo",
      width: 80,
      renderCell: (params) => {
        return (
          <div
            className={
              "flex flex-row gap-2 align-center justify-center p-2 items-center"
            }
          >
            {params.row.banner ? (
              <div className="flex items-center justify-center shrink-0">
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row.banner}`}
                  className="w-10 h-10 rounded-sm"
                />
              </div>
            ) : (
              <div className="flex items-center justify-center">
                <img src={UsersIcon} className="w-10 h-10 rounded-full" />
              </div>
            )}
          </div>
        );
      },
    },
    { field: "name", headerName: "Name", width: 100 },
    { field: "headline", headerName: "Headline", width: 120 },
    { field: "description", headerName: "Description", width: 120 },
    {
      field: "maxDiscountAmount",
      headerName: "Max Discount Amount",
      width: 120,
      renderCell: (params) => (
        <p className="text-center w-full">
          {params?.row?.maxDiscountAmount || "---"}
        </p>
      ),
    },
    {
      field: "discountPercentage",
      headerName: "Discount (%)",
      width: 100,
      renderCell: (params) => (
        <p className="text-center w-full">
          {params?.row?.discountPercentage || "---"}
        </p>
      ),
    },
    {
      field: "fixedAmount",
      headerName: "Fixed Amount",
      width: 100,
      renderCell: (params) => (
        <p className="text-center w-full">
          {params?.row?.fixedAmount || "---"}
        </p>
      ),
    },
    {
      field: "minOrderAmount",
      headerName: "Min Order Amount",
      width: 150,
      renderCell: (params) => (
        <p className="text-center w-full">
          {params?.row?.minOrderAmount || "---"}
        </p>
      ),
    },
    {
      field: "totalBudget",
      headerName: "Total Budget",
      width: 120,
      renderCell: (params) => (
        <p className="text-center">{params?.row?.totalBudget || "---"}</p>
      ),
    },
    {
      field: "transactionCode",
      headerName: "Transaction Code",
      width: 130,
      renderCell: (params) => (
        <p className="text-center">{params?.row?.transactionCode || "---"}</p>
      ),
    },

    {
      field: "startDate",
      headerName: "Start Date (EST)",
      width: 120,
    },
    {
      field: "endDate",
      headerName: "End Date (PST)",
      width: 120,
    },
    {
      field: "view",
      headerName: "Edit",
      width: 100,
      renderCell: (params) => (
        <button
          className="bg-blue-500 text-white px-2 shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
          onClick={() => handleEdit(params.row)}
          style={{ eight: "nolineHrmal" }}
        >
          Edit
        </button>
      ),
    },
    {
      field: "insight",
      headerName: "Insight",
      width: 120,
      renderCell: (params) => (
        <button
          className="bg-purple-500 text-white px-2 mx-auto shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
          onClick={() => handleView(params.row)}
          style={{ lineHeight: "normal" }}
        >
          View Insight
        </button>
      ),
    },
    {
      field: "isActive",
      headerName: "Status",
      width: 120,
      renderCell: (params) => {
        const data = params.row;
        return (
          <button
            className={classNames(
              ` text-white px-2 mx-auto shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center`,
              { "bg-[#79D32C]": params?.row?.isActive },
              { "bg-[#dd2222]": !params?.row?.isActive },
              { "cursor-not-allowed": params?.row?.status === "Expired" }
            )}
            onClick={() => {
              if (params?.row?.status === "Expired") {
                toast.info("Expired Campaign, please change date range!");
              } else {
                toggleActiveStatus(
                  params?.row,
                  params?.row?.isActive ? false : true
                );
              }
            }}
            style={{ lineHeight: "normal" }}
          >
            {params?.row?.isActive ? "Active" : "Inactive"}
          </button>
        );
      },
    },
    {
      field: "preview",
      headerName: "Preview",
      width: 120,
      renderCell: (params) => {
        const data = params.row;
        return (
          <button
            className="bg-[#2CCCD3] text-white px-2 shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
            onClick={() => handlePreview(params?.row)}
            style={{ lineHeight: "normal" }}
          >
            Preview
          </button>
        );
      },
    },
    // {
    //   field: "isEstuFriday",
    //   headerName: "ESTU Friday",
    //   width: 110,
    //   renderCell: (params) => {
    //     return (
    //       <div className="flex w-full h-full justify-center items-center">
    //         <SwitchToggle
    //           isToggled={params?.row?.isEstuFriday}
    //           setIsToggled={() => {}}
    //           onChange={() => handleEstuFridayToggle(params?.row)}
    //           title="Toggle ESTU Friday Offer"
    //           rounded={true}
    //         />
    //       </div>
    //     );
    //   },
    // },
  ];

  const tColumns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "name", headerName: "Name", width: 100 },

    {
      field: "description",
      headerName: "Description",
      width: 140,
      renderCell: (params) => (
        <p
          onClick={(e) => handleDescriptionClick("description", e, params.row)}
        >
          {params?.row?.description}
        </p>
      ),
    },
    {
      field: "amount",
      headerName: "Amount",
      width: 100,
      renderCell: (params) => (
        <p className="text-center">{params?.row?.amount || "---"}</p>
      ),
    },
    { field: "campaign", headerName: "Campaign", width: 100 },
    { field: "txnId", headerName: "Txn ID", width: 80 },
    {
      field: "transactionTimestamp",
      headerName: "Transaction Time",
      width: 150,
    },
    { field: "activationTimestamp", headerName: "Activation Time", width: 150 },
    { field: "expiryTimestamp", headerName: "Expiry Time", width: 150 },
    {
      field: "process",
      headerName: "Process",
      width: 120,
      renderCell: (params) => (
        <button
          className="bg-[#2cccd3] text-white px-2 mx-auto shadow-md min-w-[80px] h-auto py-2 rounded inline-flex items-center justify-center"
          onClick={() => handleProcess(params.id)}
          style={{ lineHeight: "normal" }}
        >
          Process
        </button>
      ),
    },
  ];

  // const handleEstuFridayToggle = async (row) => {
  //   try {
  //     const res = await toggleEstuFridayAPI(row?.id, !row?.isEstuFriday); //NOTE:this api is same as update api
  //     console.log(res);

  //     if (res?.status === 200) {
  //       toast.success(
  //         `Campaign changed to ${
  //           !row?.isEstuFriday ? "Happy Friday Offer" : "ESTU Exclusive Deal"
  //         }`
  //       );
  //       const updatedData = cData.map((item) =>
  //         item.id === row.id
  //           ? { ...item, isEstuFriday: !item.isEstuFriday }
  //           : item
  //       );
  //       setCData(updatedData);
  //     }
  //   } catch (error) {
  //     console.error("Error updating ESTU Friday status:", error);
  //   }
  // };
  const toggleActiveStatus = async (data, newStatus) => {
    try {
      const res = await toggleActiveStatusCampaignAPI(data, newStatus);
      console.log(res);
      if (res?.status) {
        if (newStatus) {
          toast.success(res?.data?.message);

          console.log(cData);
        } else {
          toast.info(res?.data?.message);
        }
        setCData((prevCData) =>
          prevCData.map((item) =>
            item.id === data.id ? { ...item, ...res?.data?.data } : item
          )
        );
      } else {
        toast.error("Failed to update status!");
      }
    } catch (err) {
      console.error(err);
      toast?.error(err);
    }
  };

  const HeadingActionButton = () => {
    return active == tabs[0]?.value ? (
      <div className="self-start w-fit">
        <button
          className="bg-[#2cccd3]  text-white px-4 py-2 rounded-lg mb-4 cursor-pointer hover:shadow-md"
          onClick={handleOpenModal}
        >
          Create Campaign
        </button>
      </div>
    ) : (
      ""
    );
  };

  return (
    <WrapperOutlet
      headingText={"Campaign Management"}
      headingChildren={<HeadingActionButton />}
      // headingChildOnSameLine={true}
    >
      <div className="flex justify-between items-center">
        <div className={`flex flex-row gap-2 items-center`}>
          {tabs.map((tab, index) => (
            <CustomerReportTab
              key={index}
              setActiveTab={() => setTab(tab.value)}
              activeTab={active}
              tab={tab}
            />
          ))}
        </div>
      </div>

      {loading ? (
        <DotLoading />
      ) : active === "codes" ? (
        <TransactionCodesManager />
      ) : (
        <Box className="bg-white rounded-lg shadow-md p-4 flex-1">
          <CDataGrid
            rows={active === "transactions" ? tData : cData}
            columns={active === "transactions" ? tColumns : columns}
            loading={loading}
            pagination
          />
        </Box>
      )}
      {openModal && (
        <OpenAndEdit
          handleSubmit={handleSubmit}
          viewMode={viewMode}
          setFormData={setFormDataC}
          formData={formDataC}
          isUpdateState={isUpdateState}
          handleCloseModal={handleCloseModal}
          handleEndDateChange={handleEndDateChange}
          handleStartDateChange={handleStartDateChange}
          handleBannerUpload={handleBannerUpload}
          codes={codes}
        />
      )}
      {viewCampInsight && (
        <ViewCampaignInsight
          open={viewCampInsight}
          handleClose={() => setViewCampInsight(false)}
          id={selectedCampaign?.id}
        />
      )}
      {isPreviewOpen && (
        <Preview
          handleClosePreview={handleClosePreview}
          logo={bg_logo_estu}
          selectedCampaign={selectedCampaign}
          campaignOffer={campaignOffer}
        />
      )}

      {dropdown && (
        <div
          ref={dropdownRef}
          className="absolute bg-white shadow-md rounded-lg p-4 max-w-[80vw] md:max-w-[50vw] text-gray-700"
          style={{
            top: dropdown.position.top,
            left: dropdown.position.left,
            minWidth: dropdown.position.width,
            zIndex: 1000,
          }}
        >
          <p className="whitespace-pre-wrap">{dropdown.data}</p>
        </div>
      )}
    </WrapperOutlet>
  );
};

export default CampaignManagement;
