import React, { useEffect, useState } from "react";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import Group from "./Group";
import SoftDialog from "../../../components/molecules/dialogs";
import AddCouponAndBrand from "./AddCouponAndBrand";
import {
  getAdmasterUsersAsBrands,
  getCouponsForBrandAPI,
  getUsersAsBrandsAPI,
} from "../../../api/api";
import EstuModal from "../../../components/ui/Modal/EstuModal/EstuModal";
const GroupsContainer = ({ getAPI, groups = [] }) => {
  const [users, setUsers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedBrandId, setSelectedBrandId] = useState(null);
  const [showModalAddCouponAndBrand, setShowModalAddCouponAndBrand] =
    useState(false);
  const [brands, setBrands] = useState([]);
  const [coupons, setCoupons] = useState([]);
  //TODO: UI for dragnad drop page

  const getUsersAsBrands = async () => {
    const response = await getUsersAsBrandsAPI({
      groupId: selectedGroup,
      pageSize: 16,
    });
    setBrands(response?.data ? response?.data : []);
    console.log(response);
  };
  const getCouponsForBrand = async () => {
    if (!selectedBrandId) {
      return setCoupons([]);
    }

    const response = await getCouponsForBrandAPI(
      selectedBrandId,
      selectedGroup
    ); // active coupons
    setCoupons(response?.data ? response?.data : []); //
    console.log(response);
  };
  const fetchAdsManagerUser = async () => {
    try {
      const res = await getAdmasterUsersAsBrands();
      if (res.status === "success") {
        setUsers(res.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    // Fetch initial items (adjust the endpoint)
    fetchAdsManagerUser();
  }, []);

  useEffect(() => {
    selectedGroup && getUsersAsBrands();
    selectedGroup && getCouponsForBrand();
  }, [selectedGroup]);
  useEffect(() => {
    selectedGroup && getCouponsForBrand();
  }, [selectedBrandId]);
  return (
    <>
      <div className="bg-white rounded-lg p-4 w-full mt-5 flex flex-col gap-1">
        <p className="text-[14px] text-[#344767] font-semibold">
          Drag and reorder the list
        </p>
        <SortableContext
          items={showModalAddCouponAndBrand ? [] : groups}
          strategy={verticalListSortingStrategy}
        >
          {groups?.map((group, idx) => {
            return (
              <Group
                openModal={showModalAddCouponAndBrand}
                sethOpenModal={setShowModalAddCouponAndBrand}
                selectedGroup={selectedGroup}
                setSelectedGroup={setSelectedGroup}
                getAPI={getAPI}
                indexId={idx}
                key={group.id}
                {...group}
              />
            );
          })}
        </SortableContext>
      </div>

      <EstuModal
        title={"Add Coupon or Brand to group"}
        open={showModalAddCouponAndBrand}
        handleClose={(_) => {
          // setStep(0);
          setShowModalAddCouponAndBrand(false);
          //   setEditGroup(false);
        }}
      >
        <AddCouponAndBrand
          selectedGroup={selectedGroup}
          selectedGroupId={selectedGroup}
          setSelectedGroup={setSelectedGroup}
          brands={brands}
          setBrands={setBrands}
          coupons={coupons}
          setCoupons={setCoupons}
          setShowModalAddCouponAndBrand={setShowModalAddCouponAndBrand}
          selectedBrandId={selectedBrandId}
          setSelectedBrandId={setSelectedBrandId}
          users={users}
        />
        {/* {console.log("bbbbbbbbb", selectedGroup)} */}
      </EstuModal>
    </>
  );
};

export default GroupsContainer;
