import React, { useEffect, useState } from "react";
import { getPricingTableDataAPI, savePricingDataAPI } from "../../../api/api";
import DotLoading from "../../../components/ui/Loading/DotLoading";
import InputComponent from "../../../components/atoms/InputComponent";
import { toast } from "react-toastify";

const PricingUI = () => {
  const [data, setData] = useState([]);
  const [pageLoading, setPageLoading] = useState(false);
  const [loadingValues, setLoadingValues] = useState(true);

  const getAPI = async (data) => {
    if (data?.type === "initialCall") {
      setPageLoading(true);
    } else {
      setLoadingValues(true);
    }

    try {
      const response = await getPricingTableDataAPI();
      console.log(response);

      setData(response?.data || {});
    } catch (error) {
      console.log(error);
    } finally {
      setPageLoading(false);
      setLoadingValues(false);
    }
  };
  useEffect(() => {
    getAPI({ type: "initialCall" });
  }, []);
  console.log(data);
  return (
    <div className="w-full h-full">
      <div className="border border-[#E3E3E3] rounded-lg min-h-[30rem] px-4 md:px-9 py-4 md:py-9 flex flex-col md:gap-10 gap-6">
        <h4 className="font-montserrat md:text-xl text-lg font-semibold text-[#2C3030]">
          Set the Price for the partners who boost coupons or events:
        </h4>
        <div>
          <UpdateTable
            data={data}
            setLoadingValues={setLoadingValues}
            parentWrapperAPI={getAPI}
          />
        </div>
        <hr />
        <div className="flex flex-col md:gap-6 gap-4">
          {data?.CPC || data?.CPA || data?.CPM || loadingValues ? (
            <p className="font-montserrat md:text-xl text-lg font-semibold text-[#2C3030]">
              Present Values:
            </p>
          ) : (
            <p className="w-full font-montserrat text-center">
              No Data Available!
            </p>
          )}
          {/* <p className="font-montserrat text-base font-bold text-[#2C3030]">
              {data?.CPC
                ? `Cost per mile (CPC): $${data?.CPC}`
                : data?.CPM
                ? `Cost Per Click (CPM): $${data?.CPM}`
                : data?.CPA
                ? `Cost Per Acquisition (CPA): $${data?.CPA}`
                : null}
            </p> */}
          {loadingValues ? (
            <div className="text-base">
              <DotLoading />
            </div>
          ) : (
            <>
              {data?.CPC ? (
                <p className="font-montserrat text-base font-bold text-[#2C3030]">{`Cost per mile (CPC): $${data?.CPC}`}</p>
              ) : (
                ""
              )}
              {data?.CPM ? (
                <p className="font-montserrat text-base font-bold text-[#2C3030]">{`Cost Per Click (CPM): $${data?.CPM}`}</p>
              ) : (
                ""
              )}
              {data?.CPA ? (
                <p className="font-montserrat text-base font-bold text-[#2C3030]">{`Cost Per Acquisition (CPA): $${data?.CPA}`}</p>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

const UpdateTable = ({ data, setLoadingValues, parentWrapperAPI }) => {
  const [selectedKey, setSelectedKey] = useState(Object.keys(data)[0] || "CPC");
  const [inputValues, setInputValues] = useState({
    CPM: data?.CPM || 0,
    CPC: data?.CPC || 0,
    CPA: data?.CPA || 0,
  });

  const [error, setError] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingValues(true);
    try {
      // Call the API to save the updated pricing data
      const response = await savePricingDataAPI({
        key: selectedKey,
        value: inputValues[selectedKey],
      });
      console.log("Pricing data saved successfully:", response);
      toast.success(response?.message || "Pricing data saved successfully");
      parentWrapperAPI();
    } catch (error) {
      console.error("Error saving pricing data:", error);
      setError("An error occurred while saving the pricing data.");
    } finally {
      setLoadingValues(false);
    }
  };

  const handleRadioChange = (e) => {
    const newKey = e.target.value;
    setSelectedKey(newKey);
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue === "" || (!isNaN(inputValue) && Number(inputValue) >= 0)) {
      setInputValues((prevValues) => ({
        ...prevValues,
        [selectedKey]: inputValue,
      }));
    }
    // setInputValue(e.target.value);
  };

  return (
    <div className="flex flex-col md:gap-8 gap-4 relative">
      <form
        className="w-full h-full flex flex-col gap-6 md:gap-8"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-row flex-wrap md:gap-8 gap-4 rounded_custom-radio-group">
          <label className="flex flex-row gap-2 justify-start items-center font-montserrat text-base font-semibold text-[#2C3030]">
            <input
              type="radio"
              name="pricingType"
              value="CPM"
              checked={selectedKey === "CPM"}
              onChange={handleRadioChange}
              className="rounded_custom-radio"
            />
            Cost Per Mille (CPM)
          </label>
          <label className="flex flex-row gap-2 justify-start items-center font-montserrat text-base font-semibold text-[#2C3030]">
            <input
              type="radio"
              name="pricingType"
              value="CPC"
              checked={selectedKey === "CPC"}
              onChange={handleRadioChange}
              className="rounded_custom-radio"
            />
            Cost Per Click (CPC)
          </label>
          <label className="flex flex-row gap-2 justify-start items-center font-montserrat text-base font-semibold text-[#2C3030]">
            <input
              type="radio"
              name="pricingType"
              value="CPA"
              checked={selectedKey === "CPA"}
              onChange={handleRadioChange}
              className="rounded_custom-radio"
            />
            Cost Per Acquisition (CPA)
          </label>
        </div>

        <div className="flex md:flex-row flex-col gap-4 max-w-[40rem]">
          <InputComponent
            widthFull
            name="value"
            placeholder={"Enter amount(USD)"}
            value={inputValues[selectedKey] || ""}
            onChange={handleInputChange}
            type={"number"}
          />
          <button className="rounded-lg text-nowrap bg-[#2cccd3] hover:bg-opacity-90 active:bg-opacity-70 text-white font-montserrat text-base px-4 py-2">
            Update Value
          </button>
        </div>
      </form>

      {error && (
        <div className="text-red-500 font-montserrat text-base mt-4">
          {error}
        </div>
      )}
    </div>
  );
};

export default PricingUI;
