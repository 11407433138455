import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.css";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { SelectIcon } from "../../assets/svgs/selectIcon";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/atoms/Loader";
import {
  createAnInstituteAPI,
  GetInstituteList,
  ToogleInstitute,
} from "../../api/api";
import dayjs from "dayjs";
import { AddSharp, CreateOutlined } from "@mui/icons-material";
import SoftDialog from "../../components/molecules/dialogs";
import { CheckIcon } from "../../assets/svgs/svgs";
import GenericFormComponent from "../../components/Form/Form";
import formConfig from "./formConfig";
import GenericFormField from "../../components/Form/FormFields";
import { set } from "date-fns";
import TextComponent from "../../components/atoms/TextComponent";
import { toast } from "react-toastify";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import DotLoading from "../../components/ui/Loading/DotLoading";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";

const InstituteList = () => {
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState([]);
  const [step, setStep] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({ name: "", domains: [] });

  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(true);

  const updateInstitute = async (updatedRow) => {
    console.log(updatedRow, data);
    try {
      const res = await ToogleInstitute({
        ...updatedRow,
        status: updatedRow.isActive,
        collegeId: updatedRow.id,
      });
      console.log("response", res);
      const updateRows = data.map((item) =>
        item.id == updatedRow.id ? { ...updatedRow } : { ...item }
      );
      setData(updateRows);
      console.log(res);
      toast.success(res?.message);
    } catch (err) {
      console.log("err", err);
      setData(data);
    }
  };

  const setStatus = async (row, id) => {
    try {
      const updatedData = data.map((item) =>
        item.id === id ? { ...row } : item
      );

      setData(updatedData);
      await updateInstitute(row);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      editable: true,
      resizable: true,
    },
    {
      field: "country",
      headerName: "Country",
      width: 120,
      editable: false,
      resizable: true,
    },
    {
      field: "domains",
      headerName: "Domains",
      width: 220,
      renderCell: (param) => {
        let data = param.row.domains;
        return (
          <div
            className={`flex flex-row gap-1 overflow-x-auto items-center w-full h-full justify-center scrollbar cursor-pointer`}
          >
            {data?.map((data1) => (
              <Chip label={data1} className="cursor" />
            ))}
          </div>
        );
      },
      editable: true,
      resizable: true,
    },

    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 150,
      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD,HH:mm:ss")}
        </p>
      ),
      resizable: true,
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      renderCell: (param) => {
        const data = param.row;

        const row = {
          ...data,
          isActive: !data.isActive,
        };

        return (
          <div className={`flex flex-row gap-2`}>
            <Switch
              onChange={(_) => setStatus(row, data.id)}
              checked={data.isActive ? true : false}
            />
          </div>
        );
      },
      resizable: false,
    },
  ];

  const getAPI = async () => {
    try {
      const res = await GetInstituteList();
      setData(res);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleAddInstituteButtonClick = () => {
    // Add new institute code here
    setShowForm(true);
  };

  useEffect(() => {
    getAPI();
  }, []);

  const handleSelectionChange = (selection) => {
    if (!selectAll) {
      setSelectedData(selection.selectionModel);
    } else {
      setSelectedData([]);
    }
  };

  const handleButtonClick = () => {
    if (selectAll) {
      setSelectAll(false);
      const newSelectionModel = data.map((row) => row.id);
      setSelectedData(newSelectionModel);
    } else {
      setSelectedData([]);
      setSelectAll(true);
    }
  };

  const HeadingComponent = () => {
    return (
      <div className={`flex flex-row align-center items-center gap-2`}>
        <button
          onClick={handleAddInstituteButtonClick}
          className={`${style.selectRows} ${
            !selectAll ? style.rowsActive : ""
          }`}
        >
          <AddSharp />
          Create An Institute
        </button>
        <button
          onClick={handleButtonClick}
          className={`${style.selectRows} ${
            !selectAll ? style.rowsActive : ""
          }`}
        >
          <SelectIcon />
          Select All
        </button>
      </div>
    );
  };
  return (
    <WrapperOutlet
      headingText={"Institute List"}
      headingChildren={<HeadingComponent />}
    >
      <div className={`flex flex-column gap-2 align-center h-full`}>
        <Box className="bg-white rounded-lg shadow-md p-4 flex-1">
          {loading ? (
            <DotLoading />
          ) : (
            <CDataGrid
              rows={data}
              columns={columns}
              loading={loading}
              rowCount={data?.length}
              checkboxSelection={!selectAll}
              onSelectionModelChange={handleSelectionChange}
              selectionModel={selectedData}
              pagination
              onCellFocusOut={() => console.log("akljsldkf")}
              onCellEditCommit={(params) => {
                console.log("params", params, typeof params.value);
                let updatedRow = data.filter((item) =>
                  item.id === params.id ? item : null
                );
                console.log(updatedRow);
                if (
                  params.field == "domains" &&
                  typeof params.value == "string"
                ) {
                  updatedRow = {
                    ...updatedRow[0],
                    domains: params.value.split(","),
                  };
                } else {
                  updatedRow = {
                    ...updatedRow[0],
                    [params.field]: params.value,
                  };
                }
                const updatedRows = data.map((item) => {
                  if (item.id === params.id) {
                    return {
                      ...updatedRow,
                    };
                  }
                  return item;
                });

                updateInstitute(updatedRow);
                console.log(updatedRow);
                setData(updatedRows);
              }}
            />
          )}
        </Box>
      </div>
      <SoftDialog
        title={step === 0 ? "Create new Institute" : ""}
        open={showForm}
        handleClose={(_) => {
          setStep(0);
          setShowForm(false);
          setFormData({ name: "", domains: [] });
        }}
      >
        {step === 0 ? (
          <div className="md:w-[600px] w-full">
            <FormComponent
              setStep={setStep}
              step={step}
              formData={formData}
              setFormData={setFormData}
              getAPI={getAPI}
            />
          </div>
        ) : (
          <SuccessFullStep />
        )}
      </SoftDialog>
    </WrapperOutlet>
  );
};

const FormComponent = ({
  formData,
  setFormData,
  setStep,
  submitText = "Submit",
  getAPI,
}) => {
  const [domainsList, setDomainsList] = useState([]);
  const [inputDomain, setInputDomain] = useState(
    formData?.domains?.join(" ") || ""
  );
  const [errorMessages, setErrorMessages] = useState({
    name: "",
    domains: "",
  });
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      domains: domainsList,
    }));
  }, [domainsList, setFormData]);

  const handleInputChange = (e) => {
    if (e.target?.name === "domains") {
      console.log(e.target, inputDomain);
      setInputDomain(e.target.value);
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [e.target.name]: e.target.value,
      }));
    }
    setErrorMessages((prev) => ({ ...prev, [e.target.name]: "" }));
  };
  const handleKeyDown = (e) => {
    if (e.key === " " || e.key === "," || e.keyCode === 13) {
      console.log(e.key);
      e.preventDefault();
      addDomain();
    }
  };

  const handleBlurDomain = () => {
    addDomain();
  };

  const addDomain = () => {
    const trimmedDomain = inputDomain.trim();
    // Regular expression to match common domain formats
    const domainPattern = /^[a-zA-Z0-9._%+-]+\.([a-z]{2,})$/;

    if (!domainPattern.test(trimmedDomain)) {
      setErrorMessages((prev) => ({
        ...prev,
        domains: "Please enter a valid domain format (e.g., example.com)",
      }));
      return;
    }
    if (formData?.domains?.includes(trimmedDomain)) {
      setErrorMessages((prev) => ({
        ...prev,
        domains: "You cannot add duplicate domain",
      }));
      return;
    }
    if (
      trimmedDomain &&
      !domainsList.includes(trimmedDomain) &&
      !formData.domains.includes(trimmedDomain)
    ) {
      setFormData((prev) => ({
        ...prev,
        domains: [...prev.domains, trimmedDomain],
      }));
      setInputDomain("");
      setErrorMessages((prev) => ({ ...prev, ["domains"]: "" }));
    }
  };

  const handleDelete = (domainToDelete) => {
    setFormData((prev) => ({
      ...prev,
      ["domains"]: formData?.domains?.filter(
        (domain) => domain !== domainToDelete
      ),
    }));
  };

  const handleSubmit = async () => {
    const name = formData.name || "";
    const domains = formData.domains || [];

    let hasError = false;
    const newErrorMessages = { name: "", domain: "" };

    if (!name) {
      newErrorMessages.name = "Please enter your name.";
      hasError = true;
    }
    if (domains.length === 0) {
      newErrorMessages.domain = "Please add at least one domain.";
      hasError = true;
    }

    setErrorMessages(newErrorMessages);

    if (hasError) return;
    console.log("Processed Form Data:", formData);

    try {
      const response = await createAnInstituteAPI(formData);
      console.log(response);

      setStep(1);
      getAPI();
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit form");
    }
  };

  return (
    <div className={`flex flex-col gap-2 align-start mt-[15px]`}>
      <GenericFormField
        key={"name"}
        field={formConfig?.[0]}
        formData={formData}
        setFormData={setFormData}
        handleInputChange={handleInputChange}
      />
      {errorMessages.name && (
        <div className="text-red-500 text-sm">{errorMessages.name}</div>
      )}
      <div>
        <label forhtml={"domain"}>
          Domain
          <TextComponent
            type="textarea"
            name={"domains"}
            placeholder={formConfig[1].placeholder}
            value={inputDomain}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            onBlur={handleBlurDomain}
            rows={4}
          />
        </label>
        {errorMessages.domains && (
          <div className="text-red-500 text-sm">{errorMessages.domains}</div>
        )}
      </div>

      <div className="mt-2 flex flex-wrap gap-2">
        {formData?.domains?.map((domain, index) => (
          <Chip
            key={index}
            label={domain}
            onDelete={() => handleDelete(domain)}
            className="cursor-pointer"
          />
        ))}
      </div>
      <div
        onClick={handleSubmit}
        style={{
          // backgroundColor: "#2CCCD3",
          color: "white",
          borderRadius: "10px",
          display: "flex",
          width: "100%",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
        className={`gap-[10px] align-items-center ${
          !formData?.name || formData?.domains?.length === 0
            ? "cursor-not-allowed bg-gray-300"
            : "cursor-pointer bg-[#2CCCD3] hover:drop-shadow-xl"
        }  text-center align-center p-3 select-none`}
      >
        {submitText ? submitText : "Continue"}
      </div>
    </div>
  );
};

const SuccessFullStep = () => {
  return (
    <div
      className={`flex flex-col p-5 gap-10 w-100`}
      style={{ alignItems: "center" }}
    >
      <div className={"w-[100px] h-[100px] mx-3"}>
        <CheckIcon />
      </div>
      <div className={style.successtext}>Institute created successfully!</div>
    </div>
  );
};

export default InstituteList;
