export const WaitListIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.4"
      d="M11.6847 5.18016C11.6381 5.1735 11.5914 5.1735 11.5447 5.18016C10.5114 5.14683 9.69141 4.30016 9.69141 3.26016C9.69141 2.20016 10.5514 1.3335 11.6181 1.3335C12.6781 1.3335 13.5447 2.1935 13.5447 3.26016C13.5381 4.30016 12.7181 5.14683 11.6847 5.18016Z"
      fill="#2cccd3"
    />
    <path
      opacity="0.4"
      d="M13.8597 9.79978C13.1131 10.2998 12.0664 10.4864 11.0997 10.3598C11.3531 9.81311 11.4864 9.20644 11.4931 8.56644C11.4931 7.89978 11.3464 7.26644 11.0664 6.71311C12.0531 6.57979 13.0997 6.76644 13.8531 7.26644C14.9064 7.95978 14.9064 9.09978 13.8597 9.79978Z"
      fill="#2cccd3"
    />
    <path
      opacity="0.4"
      d="M4.29359 5.18016C4.34026 5.1735 4.38693 5.1735 4.43359 5.18016C5.46693 5.14683 6.28693 4.30016 6.28693 3.26016C6.28693 2.20016 5.42693 1.3335 4.36026 1.3335C3.30026 1.3335 2.43359 2.1935 2.43359 3.26016C2.44026 4.30016 3.26026 5.14683 4.29359 5.18016Z"
      fill="#2cccd3"
    />
    <path
      opacity="0.4"
      d="M4.36479 8.56657C4.36479 9.21324 4.50479 9.82657 4.75813 10.3799C3.81813 10.4799 2.83813 10.2799 2.11813 9.80657C1.06479 9.10657 1.06479 7.96657 2.11813 7.26657C2.83146 6.78657 3.83812 6.59324 4.78479 6.69991C4.51146 7.25991 4.36479 7.89324 4.36479 8.56657Z"
      fill="#2cccd3"
    />
    <path
      d="M8.07923 10.58C8.0259 10.5733 7.9659 10.5733 7.9059 10.58C6.67923 10.54 5.69922 9.53333 5.69922 8.29333C5.69922 7.02667 6.71923 6 7.99257 6C9.25923 6 10.2859 7.02667 10.2859 8.29333C10.2859 9.53333 9.31257 10.54 8.07923 10.58Z"
      fill="#2cccd3"
    />
    <path
      d="M5.91516 11.9598C4.90849 12.6331 4.90849 13.7398 5.91516 14.4064C7.06184 15.1731 8.94184 15.1731 10.0885 14.4064C11.0952 13.7331 11.0952 12.6264 10.0885 11.9598C8.9485 11.1931 7.0685 11.1931 5.91516 11.9598Z"
      fill="#2cccd3"
    />
  </svg>
);

export const AdsIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Vector"
      d="M4.01371 10.6711L3.40013 12.2807C3.32002 12.4912 3.17253 12.6733 2.97786 12.8021C2.78318 12.9309 2.55085 13 2.31269 13H0.283312C0.246093 13 0.209229 12.9932 0.174834 12.9799C0.140438 12.9665 0.109186 12.9469 0.0828678 12.9222C0.0565498 12.8975 0.0356823 12.8682 0.0214625 12.8359C0.00724272 12.8036 -5.04107e-05 12.769 2.62254e-07 12.7341C1.31794e-05 12.7012 0.00653969 12.6686 0.019247 12.638L5.17352 0.169808C5.19412 0.119788 5.23037 0.0767936 5.2775 0.0464838C5.32464 0.0161739 5.38045 -3.13921e-05 5.43758 3.08931e-07H7.56242C7.61957 -8.15456e-05 7.6754 0.0161045 7.72254 0.0464209C7.76969 0.0767373 7.80593 0.119759 7.82648 0.169808L12.9808 12.638C12.9935 12.6686 13 12.7012 13 12.7341C13.0001 12.769 12.9928 12.8036 12.9785 12.8359C12.9643 12.8682 12.9435 12.8975 12.9171 12.9222C12.8908 12.9469 12.8596 12.9665 12.8252 12.9799C12.7908 12.9932 12.7539 13 12.7167 13H10.6677C10.4295 13 10.1972 12.9309 10.0025 12.8021C9.80784 12.6733 9.66035 12.4912 9.58024 12.2807L9.03056 10.8355C8.99206 10.7296 8.12981 8.18362 8.0101 8.18362M7.99239 7.887L6.75752 4.73112C6.73754 4.67999 6.70132 4.63585 6.65375 4.60466C6.60618 4.57348 6.54956 4.55676 6.49153 4.55676C6.43351 4.55676 6.37688 4.57348 6.32931 4.60466C6.28174 4.63585 6.24552 4.67999 6.22554 4.73112L5.0053 7.887"
      fill="#2cccd3"
    />
  </svg>
);

export const Logo = (props) => (
  <svg
    width="73"
    height="28"
    viewBox="0 0 73 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.8469 14H0V18.6662H16.8469V14Z" fill="#2cccd3" />
    <path d="M16.8469 23.334H0V28.0002H16.8469V23.334Z" fill="#2cccd3" />
    <path
      d="M33.6946 18.666H16.8477V23.3335H31.8719L33.6946 18.666Z"
      fill="#2cccd3"
    />
    <path d="M16.8469 4.66602H0V9.33226H16.8469V4.66602Z" fill="#2cccd3" />
    <path d="M16.8469 4.66602H0V9.33226H16.8469V4.66602Z" fill="#2cccd3" />
    <path d="M16.8469 14H0V18.6662H16.8469V14Z" fill="#2cccd3" />
    <path d="M16.8469 23.334H0V28.0002H16.8469V23.334Z" fill="#2cccd3" />
    <path
      d="M33.6946 4.66624H16.8477V0H31.8719L33.6946 4.66624Z"
      fill="#2cccd3"
    />
    <path
      d="M33.6946 4.66624H16.8477V0H31.8719L33.6946 4.66624Z"
      fill="#2cccd3"
    />
    <path
      d="M33.6946 18.666H16.8477V23.3335H31.8719L33.6946 18.666Z"
      fill="#2cccd3"
    />
    <path
      d="M32.5518 14.0002H16.8477V9.33398H32.5518L30.5874 11.6665L32.5518 14.0002Z"
      fill="#2cccd3"
    />
    <path
      d="M43.9034 21.019L45.3183 19.5737L45.3434 19.543V19.2908H39.5831V15.1571H43.8758V13.466H39.5831V9.1761H42.3316H42.6753H45.3434V7.43506H42.6753H37.7266V21.019H42.5084H43.9034Z"
      fill="#212322"
    />
    <path
      d="M71.3096 10.0146H73.0018V20.7579H71.3096V19.2831C70.5243 20.3162 69.1771 21.0114 67.7382 21.0114C65.4878 21.0114 63.8984 19.4751 63.8984 16.7343V10.0146H65.5907V16.7343C65.5907 18.5457 66.6444 19.4521 68.1296 19.4521C69.384 19.4521 70.6071 18.6302 71.3096 17.4294V10.0146Z"
      fill="#212322"
    />
    <path
      d="M58.0477 16.4184V11.4897H61.9088V10.015H58.0477V6.98096H57.8043L56.3555 8.45956V16.4607C56.3555 19.0517 57.5948 21.0104 60.2981 21.0104C60.926 21.0121 61.551 20.926 62.1559 20.7544V19.2182C61.6315 19.367 61.0907 19.4478 60.5465 19.4588C58.6473 19.4588 58.0477 17.9777 58.0477 16.4184Z"
      fill="#212322"
    />
    <path
      d="M52.1829 14.7131L50.5521 14.2279C49.5611 13.9539 49.0656 13.5123 49.0656 12.8197C49.0656 11.7674 50.1181 11.3244 51.1706 11.3244C51.8369 11.3355 52.4982 11.445 53.1337 11.6496L54.2565 10.4974C53.4687 10.0404 52.4225 9.75879 51.2747 9.75879C49.1484 9.75879 47.332 10.8764 47.332 12.7723C47.332 14.3725 48.0947 15.3839 49.9124 15.8895L51.7489 16.4157C52.5956 16.6487 53.2354 17.0481 53.2354 17.8905C53.2354 18.8813 52.1001 19.4702 50.7579 19.4702C49.7882 19.4702 48.3218 19.1335 47.4148 18.5856V20.1871C48.0132 20.6083 49.457 21.0077 50.6349 21.0077C53.1112 21.0077 54.9489 19.9349 54.9489 17.8905C54.9489 16.3351 54.2677 15.2341 52.1829 14.7131Z"
      fill="#212322"
    />
  </svg>
);

export const KYCERRORSICONS = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic:twotone-error">
      <path
        id="Vector"
        opacity="0.3"
        d="M8.0013 2.66699C5.05464 2.66699 2.66797 5.05366 2.66797 8.00033C2.66797 10.947 5.05464 13.3337 8.0013 13.3337C10.948 13.3337 13.3346 10.947 13.3346 8.00033C13.3346 5.05366 10.948 2.66699 8.0013 2.66699ZM8.66797 11.3337H7.33464V10.0003H8.66797V11.3337ZM8.66797 8.66699H7.33464V4.66699H8.66797V8.66699Z"
        fill="#2cccd3"
      />
      <path
        id="Vector_2"
        d="M7.99203 1.33301C4.31203 1.33301 1.33203 4.31967 1.33203 7.99967C1.33203 11.6797 4.31203 14.6663 7.99203 14.6663C11.6787 14.6663 14.6654 11.6797 14.6654 7.99967C14.6654 4.31967 11.6787 1.33301 7.99203 1.33301ZM7.9987 13.333C5.05203 13.333 2.66536 10.9463 2.66536 7.99967C2.66536 5.05301 5.05203 2.66634 7.9987 2.66634C10.9454 2.66634 13.332 5.05301 13.332 7.99967C13.332 10.9463 10.9454 13.333 7.9987 13.333ZM7.33203 9.99967H8.66536V11.333H7.33203V9.99967ZM7.33203 4.66634H8.66536V8.66634H7.33203V4.66634Z"
        fill="#2cccd3"
      />
    </g>
  </svg>
);

export const LogoutIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 22H5C3.89543 22 3 21.1046 3 20V4C3 2.89543 3.89543 2 5 2H10"
      stroke="#FF3B30"
      strokeOpacity="0.64"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 16L21 12L17 8"
      stroke="#FF3B30"
      strokeOpacity="0.64"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 12H9"
      stroke="#FF3B30"
      strokeOpacity="0.64"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
