import React, { useEffect, useState } from "react";
import CommentContainer from "../Comment/CommentContainer";
import AddInputContainer from "../AddInputContainer/AddInputContainer";
import RadioButtonContainer from "../RadioButtonContainer/RadioButtonContainer";
import {
  addPriorityForTicketAPI,
  addStatusForTicketAPI,
  getPriorityListAPI,
  getStatusListAPI,
  updateSupportTIcketTagsAPI,
} from "../../../../api/api";
import { toast } from "react-toastify";
import ColorTagCreator from "../../../../components/ui/ColorTagCreator/ColorTagCreator";

const Priority = ({ ticket, onChange = () => {} }) => {
  //   console.log(ticket);

  const [statusErrorMessage, setStatusErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPriorityId, setSelectedPriorityId] = useState(
    ticket?.data?.priority?.id
  );
  const handleAddPriority = async (data) => {
    if (!data?.color) {
      toast.error("Please select a color.");
      return;
    } else if (!data?.name) {
      toast.error("Please enter a priority name.");
      return;
    }

    try {
      const response = await addPriorityForTicketAPI(data);
      //   console.log(response);
      if (response?.status === "success") {
        toast.success(response?.data?.message || "Priority added successfully");
        // getAPI();
        setData((prev) => [...prev, response?.data]);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const handleInputChange = (value) => {
    setStatusErrorMessage("");
  };
  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await getPriorityListAPI();
      const fetchedData = response.data || [];
      setData(fetchedData);
    } catch (error) {
      console.error(error);
      setStatusErrorMessage("Failed to fetch priority.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectPriority = async (priorityId) => {
    if (priorityId === selectedPriorityId) {
      return toast.info("please select another priority");
    }
    try {
      const response = await updateSupportTIcketTagsAPI(ticket?.data?.id, {
        priority: priorityId,
      });
      if (onChange) onChange();
      setSelectedPriorityId(priorityId);
      // console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("Failed to update priority");
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  return (
    <div className="flex flex-col min-h-[400px] md:min-w-[400px] min-w-[80vw] max-h-[500px] md:gap-4 gap-4">
      <RadioButtonContainer
        data={data}
        name="Priority"
        loading={loading}
        selectedData={selectedPriorityId}
        onSelect={handleSelectPriority}
      />
      <hr />
      <ColorTagCreator
        heading="Add custom priority"
        headingCss="font-normal font-montserrat text-sm font-bold text-[#2C3030]"
        buttonText="Add Priority"
        iconVisible={true}
        buttonVisible={true}
        onSubmit={handleAddPriority}
        onChange={handleInputChange}
        placeholder="Priority title"
        placeholderCss={
          "placeholder:font-montserrat placeholder:text-xs placeholder:font-normal placeholder:text-[#8F8F8F]"
        }
        errorMessage={statusErrorMessage}
      />
      <hr />
      <CommentContainer />
    </div>
  );
};

export default Priority;
