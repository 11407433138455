import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import SearchIconRound from "../../../../assets/imgs/search_rainbow_round.png";
import { ChromePicker } from "react-color";
import { getTextColor } from "../../../../utils/utils";
const TagCreator = ({
  iconVisible = true,
  buttonVisible = true,
  onSubmit,
  onChange,
  placeholder = "Search...",
  buttonText = "Search",
  errorMessage = "",
  placeholderCss = "",
}) => {
  const [inputValue, setInputValue] = useState("");
  const [bgColor, setBgColor] = useState("#F2F8FA");
  const [showPicker, setShowPicker] = useState(false);

  const pickerRef = useRef(null);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      if (onSubmit({ color: bgColor, name: inputValue })) {
        setInputValue("");
        setBgColor("#F2F8FA");
      }
    }
  };

  const handleColorChange = (color) => {
    setBgColor(color?.hex);
  };

  const handleClickOutside = (e) => {
    if (pickerRef.current && !pickerRef.current.contains(e.target)) {
      setShowPicker(false);
    }
  };

  useEffect(() => {
    if (showPicker) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showPicker]);
  return (
    <div>
      <div className="flex items-center gap-2 justify-between">
        {iconVisible && (
          <div
            className="flex items-center max-w-9 max-h-9"
            // style={{ backgroundColor: bgColor }}
          >
            <img
              src={SearchIconRound}
              alt="logo"
              className="cursor-pointer"
              onClick={() => setShowPicker(!showPicker)}
            />
          </div>
        )}
        {showPicker && (
          <div ref={pickerRef} className="absolute z-10 mt-2">
            <ChromePicker
              color={bgColor}
              onChange={handleColorChange} // Change the input's background color
            />
          </div>
        )}

        <div className="flex-1 relative">
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder={placeholder}
              value={inputValue}
              onChange={handleInputChange}
              style={{
                backgroundColor: bgColor,
                color: getTextColor(bgColor),
                border: errorMessage ? "1px solid red" : "",
              }}
              className={classNames(
                "bg-[#F2F8FA] w-full p-2.5 outline-none rounded-lg",
                placeholderCss
              )}
            />
          </form>
        </div>

        {/* Button */}
        {buttonVisible && (
          <button
            onClick={handleFormSubmit}
            className="bg-[#2cccd3] text-white rounded-lg text-center p-2.5 cursor-pointer font-montserrat text-xs font-bold h-full"
          >
            {buttonText}
          </button>
        )}
      </div>
      {errorMessage && (
        <span className="text-red-500 text-xs mt-1 mx-auto w-fit block">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default TagCreator;
