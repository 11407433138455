import React, { useState, useEffect, useRef } from "react";
import style from "./style.module.css";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Loader from "../../components/atoms/Loader";
import { getUserWaitList } from "../../api/api";
import { useNavigate } from "react-router-dom";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function UserWaitList() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    { field: "id", headerName: "Id", width: 10 },
    { field: "firstName", headerName: "First Name", width: 200 },
    { field: "lastName", headerName: "Last Name", width: 200 },
    { field: "email", headerName: "Email Address", width: 350 },
    { field: "school", headerName: "School Name", width: 300 },
    { field: "updatedAt", headerName: "Last Updated", width: 200 },
  ];

  const getAPI = async () => {
    const data = await getUserWaitList();
    console.log("res", data);
    setLoading(false);
    setData(data);
  };

  useEffect(() => {
    getAPI();
  }, []);

  return (
    <div className={style.container}>
      {loading ? (
        <div className={`flex flex-row justify-center align-center`}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={`flex flex-column gap-2 align-center`}>
            <Box sx={{ height: 590, width: "100%" }}>
              {data?.length > 0 ? (
                <DataGrid
                  rows={data}
                  columns={columns}
                  //   components={{
                  //     Toolbar: CustomToolbar,
                  //   }}
                  pagination
                  pageSize={10}
                />
              ) : null}
            </Box>
          </div>
        </>
      )}
    </div>
  );
}
