import React, { useEffect, useRef, useState } from "react";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import EmptyImage from "../../assets/imgs/empty-image.png";
import { Box } from "@mui/material";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";
import { WaitingWatchDotIcon } from "../../assets/svgs/svgs";
import ColorButton from "../../components/atoms/Button/ColorButton/ColorButton";
import EstuModal from "../../components/ui/Modal/EstuModal/EstuModal";
import {
  dummyData,
  priorityStyles,
  statusStyles,
  activity,
  allStatus,
  allIssueTypes,
} from "./config";
import Activity from "./components/Activity/Activity";
import ImagePreview from "../../components/ui/Modal/ImagePreview/ImagePreview";
import { getSupportTicketsAPI } from "../../api/api";
import Status from "./components/Status/Status";
import IssueType from "./components/IssueType/IssueType";
import { getTextColor } from "../../utils/utils";
import ImageGridPreview from "../../components/ui/Modal/ImagePreview/ImageGridPreview";
import Priority from "./components/Priority/Priority";
const SupportTicketManagement = () => {
  const [selectedData, setSelectedData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAttachments, setSelectedAttachments] = useState(null);

  const dropdownRef = useRef(null);

  const column = [
    { field: "id", headerName: "Ticket ID", width: 80 },
    {
      field: "updatedAt",
      headerName: "Received on",
      width: 100,
    },

    {
      field: "subject",
      headerName: "Subject",
      width: 90,
      renderCell: (params) => (
        <p
          className="max-w-full text-ellipsis whitespace-nowrap overflow-hidden"
          onClick={(e) => handleDescriptionClick("subject", e, params.row)}
        >
          {params.row.subject}
        </p>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      width: 85,
      renderCell: (params) => (
        <p
          className="whitespace-nowrap overflow-hidden text-ellipsis max-w-full"
          onClick={(e) => handleDescriptionClick("description", e, params.row)}
        >
          {params.row.description}
        </p>
      ),
    },
    { field: "category", headerName: "Category", width: 90 },
    {
      field: "issueType",
      headerName: "Issue Type",
      width: 140,
      renderCell: (params) => (
        <ColorButton
          color={"#1573C3"}
          bgColor={"#D7F4FF"}
          onClick={() => handleCellClick("issueType", params.row)}
        >
          {params.row?.issueType?.name || "N/A"}
        </ColorButton>
      ),
    },
    {
      field: "attachments",
      headerName: "Screenshot",
      width: 90,
      renderCell: (params) => (
        <div className="relative w-full h-[64px]">
          {params?.attachments ? (
            <img
              src={`${process.env.REACT_APP_IMAGE_BASEURL}${params?.image}`}
              className="w-[64px] h-[64px] absolute left-0 bottom-0 p-2 rounded-10"
              alt={"logo"}
              onClick={() => handleAttachmentsClick(params.row.attachments)}
            />
          ) : (
            <img
              src={EmptyImage}
              className="w-[64px] auto absolute left-0 top-[50%] -translate-y-[50%] right-0 p-2 rounded-10"
              alt={"logo"}
              onClick={() => handleAttachmentsClick(params.row.attachments)}
            />
          )}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        console.log(params.row.status?.name?.toLowerCase());
        const key = params.row.status?.name?.toLowerCase();
        const { bgColor, color } = statusStyles[key] || {};
        return (
          <ColorButton
            color={getTextColor(params?.row?.status?.color)}
            bgColor={params?.row.status?.color || bgColor}
            onClick={() => handleCellClick("status", params.row)}
          >
            {params.row.status?.name || "N/A"}
          </ColorButton>
        );
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 100,
      renderCell: (params) => {
        const key = params.row.priority?.name?.toLowerCase();
        const { bgColor } = priorityStyles[key] || {};
        return (
          <ColorButton
            color={getTextColor(params?.row.priority?.color)}
            bgColor={params?.row.priority?.color || bgColor}
            onClick={() => handleCellClick("priority", params.row)}
          >
            {params.row.priority?.name || "N/A"}
          </ColorButton>
        );
      },
    },
    {
      field: "activity",
      headerName: "Activity",
      width: 120,
      renderCell: (params) => (
        <div className="flex flex-col w-full h-full items-center justify-center gap-1">
          <ColorButton
            color={"#D5583C"}
            bgColor={"#E8F0F2"}
            onClick={() => handleCellClick("activity", params.row)}
          >
            <WaitingWatchDotIcon />
          </ColorButton>
        </div>
      ),
    },
  ];

  const [data, setData] = useState([]);

  const handleCellClick = (field, rowData) => {
    if (["issueType", "status", "activity", "priority"].includes(field)) {
      setSelectedData({ field, data: rowData });
      setModalOpen(true);
    }
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedData(null);
  };

  const handleDescriptionClick = (field, event, rowData) => {
    const rect = event.target.getBoundingClientRect();
    setDropdown({
      field,
      data: rowData[field],
      position: {
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      },
    });
  };

  const closeDropdown = () => {
    setDropdown(null);
  };

  const handleAttachmentsClick = (attachments) => {
    setSelectedAttachments(attachments || []);
  };

  const handleCloseAttachmentsPreview = () => {
    setSelectedAttachments(null);
  };

  const getAPI = async () => {
    try {
      setLoading(true);
      const response = await getSupportTicketsAPI();
      setData(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getAPI();
  }, []);
  // const backgroundColor = "#333333"; // Dark background
  // const textColor = getTextColor(backgroundColor);
  // console.log(`Background Color: ${backgroundColor}, Text Color: ${textColor}`);

  return (
    <WrapperOutlet headingText={"Support Tickets"}>
      <Box className="bg-white rounded-lg shadow-md p-4 flex-1">
        <CDataGrid
          columns={column}
          rows={data}
          loading={loading}
          // onCellClick={(params) => handleCellClick(params.field, params.row)}
        />
      </Box>
      {dropdown && (
        <div
          ref={dropdownRef}
          className="absolute bg-white shadow-md rounded-lg p-4 max-w-[80vw] md:max-w-[50vw] text-gray-700"
          style={{
            top: dropdown.position.top,
            left: dropdown.position.left,
            minWidth: dropdown.position.width,
            zIndex: 1000,
          }}
        >
          <p className="whitespace-pre-wrap">{dropdown.data}</p>
        </div>
      )}
      <EstuModal
        open={modalOpen}
        bgColor="white"
        handleClose={handleCloseModal}
        title={
          selectedData ? `${selectedData?.field?.toUpperCase()}` : "Details"
        }
      >
        <div className="">
          {selectedData?.field === "status" ? (
            <Status ticket={selectedData || {}} onChange={getAPI} />
          ) : selectedData?.field === "issueType" ? (
            <IssueType ticket={selectedData || {}} onChange={getAPI} />
          ) : selectedData?.field === "priority" ? (
            <Priority ticket={selectedData || {}} />
          ) : selectedData?.field === "activity" ? (
            <Activity selectedActivity={selectedData?.data || {}} />
          ) : (
            <p>{`${selectedData?.field}: ${
              selectedData?.data[selectedData.field]
            }`}</p>
          )}
        </div>
      </EstuModal>

      {selectedAttachments && (
        <ImageGridPreview
          images={selectedAttachments}
          handleClose={handleCloseAttachmentsPreview}
        />
      )}
    </WrapperOutlet>
  );
};

export default SupportTicketManagement;
