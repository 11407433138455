import React from "react";
import PageHeading from "../Heading/PageHeading/PageHeading";
import classNames from "classnames";

const WrapperOutlet = ({
  children,
  headingText,
  headingChildren = null,
  headingChildOnSameLine = false, //Note for fellow developer : applicable for mobile only
}) => {
  return (
    <div className="px-4 flex flex-col md:gap-6 gap-4 outlet justify-start">
      <div
        className={classNames(
          "flex md:flex-row justify-between items-center gap-4",
          { "flex-row": headingChildOnSameLine },
          { "flex-col": !headingChildOnSameLine }
        )}
      >
        <PageHeading text={headingText} className=" self-start" />

        {headingChildren}
      </div>
      <hr />
      {children}
    </div>
  );
};

export default WrapperOutlet;
