import React from "react";

const Comment = ({ comment, date }) => {
  const formattedDate = date
    ? new Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }).format(new Date(date))
    : null;
  return (
    <div className="flex flex-col gap-1">
      <div className="px-2.5 py-2 rounded-lg border-[#E1E1E1] border-1 w-full">
        <p className="text-[#2C3030] font-montserrat text-xs font-normal">
          {comment}
        </p>
      </div>
      {formattedDate && (
        <p className="text-[#526163] font-montserrat text-[10px] font-medium">
          {formattedDate}
        </p>
      )}
    </div>
  );
};

export default Comment;
