import React, { useState, useEffect } from "react";
import style from "./Profile.module.css";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { GetAllUsersProfile } from "../../../api/api";
import CampaignPreviewComponent from "../dialogs/CampaignPreview";
import ApproveCampaginDialog from "../dialogs/ApproveCampagin/ApproveCampagin";
import RejectCampaginDialog from "../dialogs/RejectCampagin/RejectCampagin";
import { generateImageUrl } from "../../../utils/func";
import { useNavigate } from "react-router-dom";

export default function AdsProfileComponent() {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [showCampagin, setShowCampagin] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const [openingArea, setOpeningArea] = useState("all");
  const [showReject, setShowReject] = useState(false);
  const [selectedCampaginId, setSelectedCampaginId] = useState(0);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        const data = params.row;
        return (
          <div
            style={{ alignItems: "flex-start", justifyContent: "start" }}
            className={`flex gap-4 align-center`}
          >
            <div className={style.campaginName_image}>
              {data?.profilePhoto && (
                <img src={generateImageUrl(data?.profilePhoto)} />
              )}
            </div>
            <div className={`flex flex-col gap-2 align-start`}>
              <div className={style.campaginName_title}>
                {data.instituteName}
              </div>
              <div className={style.campaginName_des}>{data.email}</div>
            </div>
          </div>
        );
      },
    },
    { field: "instituteName", headerName: "Institute Name", width: 200 },
    { field: "phoneNumber", headerName: "Phone Number", width: 200 },
    { field: "email", headerName: "Email Address", width: 200 },
    { field: "createdAt", headerName: "Registered On", width: 200 },
  ];

  const getAPI = async () => {
    try {
      const res = await GetAllUsersProfile();
      setData(res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      <div
        style={{ fontWeight: 500 }}
        className={`flex gap-2 align-center px-3 title_threads_top font-light`}
      >
        <p onClick={(_) => navigate("/ads")}>Ads manager</p> /{" "}
        <span onClick={(_) => navigate("/ads")} style={{ fontWeight: "bold" }}>
          Profiles
        </span>
      </div>
      <div className={style.container}>
        <CampaignPreviewComponent
          open={showCampagin}
          approveCampagin={setShowApprove}
          rejectCampagin={setShowReject}
          dataCampagin={setData}
          campaginId={selectedCampaginId}
          openingArea={openingArea}
          handleClose={(_) => setShowCampagin(false)}
        />
        <ApproveCampaginDialog
          open={showApprove}
          data={data}
          handleClose={(_) => setShowApprove(false)}
        />
        <RejectCampaginDialog
          open={showReject}
          data={data}
          handleClose={(_) => setShowReject(false)}
        />
        <Box sx={{ height: "100%", width: "100%" }}>
          {data?.length > 0 ? (
            <DataGrid
              onCellClick={(params) => {
                if (params.field === "name") {
                  setSelectedCampaginId(params.row.id);
                  setShowCampagin(true);
                  if (params.row.status === "APPROVED") {
                    setOpeningArea("approved");
                  } else if (params.row.status === "REJECTED") {
                    setOpeningArea("rejected");
                  } else {
                    setOpeningArea("all");
                  }
                }
              }}
              rows={data}
              pageSize={10}
              columns={columns}
              pagination
            />
          ) : null}
        </Box>
      </div>
    </div>
  );
}
