import classNames from "classnames";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import {
  ApprovedIcon,
  CancelIcon,
  CouponsDashIcon,
  ErrorIcon,
  EventsDashIcon,
  StudyIcon,
  UserIcon,
  WaitingIcon,
  WaitingWatchIcon,
  WatchExclamatoryIcon,
} from "../../assets/svgs/svgs";

import { NotInterested } from "@mui/icons-material";

const ChipCard = ({ data_obj, type = "" }) => {
  const navigate = useNavigate();

  const handleChipClick = () => {
    if (type === "partner") {
      // console.log(type, data_obj);
      switch (data_obj?.name?.toUpperCase()) {
        case "APPROVED":
          navigate("/estu-ads?status=APPROVED", {
            state: { from: "dashboard" },
          });
          break;
        case "PENDING":
          navigate("/estu-ads?status=PENDING", {
            state: { from: "dashboard" },
          });
          break;
        case "REJECTED":
          navigate("/estu-ads?status=REJECTED", {
            state: { from: "dashboard" },
          });
          break;
        default:
          console.log(
            "SOMETHING WRONG WITH DATA PLEASE CHECK AND ASK BACKEND DEVELOPER WHY CHANGED DATA"
          );
          navigate("/estu-ads", {
            state: { from: "dashboard" },
          });
          break;
      }
      // navigate("/estu-ads");
    } else if (type == "coupon") {
      // console.log(type, data_obj);
      switch (data_obj?.name?.toUpperCase()) {
        case "TOTALCOUPONS":
          navigate("/coupons-management?type=2");
          break;
        case "PENDINGCOUPONS":
          navigate("/coupons-management?filter=PENDING&type=2"); // pending coupons
          break;
        case "EXPIREDCOUPONS":
          navigate("/coupons-management?status=EXPIRED&type=2"); // pending coupons
          break;
        default:
          navigate("/coupons-management?type=2");
          break;
      }
    } else if (type === "event") {
      switch (data_obj?.name?.toUpperCase()) {
        case "TOTALEVENTS":
          navigate("/coupons-management?type=1");
          break;
        case "PENDINGEVENTS":
          navigate("/coupons-management?filter=PENDING&type=1"); // pending coupons
          break;
        case "EXPIREDEVENTS":
          // console.log(type, data_obj);
          navigate("/coupons-management?status=EXPIRED&type=1"); // pending coupons
          break;
        default:
          navigate("/coupons-management?type=1");
          break;
      }
    } else if (type === "i20_students") {
      switch (data_obj?.name?.toUpperCase()) {
        case "APPROVED":
          navigate("/international-student", {
            state: { from: "dashboard", activeTab: 0, currStatus: "ACCEPT" }, //kyc
          });
          break;
        case "PENDING":
          navigate("/international-student", {
            state: { from: "dashboard", activeTab: 1, currStatus: "PENDING" }, //non-kyc
          });
          break;
        case "REJECTED":
          navigate("/international-student", {
            state: { from: "dashboard", activeTab: 2, currStatus: "REJECTI20" }, //non-kyc
          });
          break;
        default:
          console.log(
            "SOMETHING WRONG WITH DATA PLEASE CHECK AND ASK BACKEND DEVELOPER WHY CHANGED DATA"
          );
          navigate("/international-student", {
            state: { from: "dashboard" },
          });
          break;
      }
    } else if (data_obj?.name === "total_customers") {
      navigate("/customer-reports?type=all", {
        //currently not implemented only navigating to default one
        state: { from: "dashboard" },
      });
    } else if (data_obj?.name === "freemium_customers") {
      navigate("/customer-reports?type=freemium");
    } else if (data_obj?.name === "banking_customers") {
      navigate("/customer-reports?type=kyc");
    } else if (data_obj?.name === "non_kyc_customers") {
      navigate("/customer-reports?type=nonkyc");
    } else if (data_obj?.name === "kyc_freemium_customers") {
      navigate("/customer-reports?type=kycfreemium");
    } else if (data_obj?.name === "waitlistStudents") {
      navigate("/waitlist", { state: { from: "dashboard" } });
    } else if (data_obj?.name === "internationalStudents") {
      navigate("/international-student", { state: { from: "dashboard" } });
    } else if (data_obj?.name === "totalInstitutions") {
      navigate("/instituteList", { state: { from: "dashboard" } });
    } else {
      toast.info("No route Defined yet!");
    }
  };

  return (
    <div
      onClick={handleChipClick}
      className="w-full px-6 py-[26.5px] bg-white shadow-sm hover: cursor-pointer hover:shadow rounded-lg hover:scale-105 transition-transform flex flex-row gap-4 justify-start items-center"
    >
      <span
        className={classNames(
          "w-12 h-12 rounded-lg hover:scale-105 transition-all duration-300 flex flex-col justify-center items-center overflow-hidden shadow-sm",
          { "bg-[#12A58C]": data_obj?.name?.toLowerCase() === "approved" },
          { "bg-[#E0AF00]": data_obj?.name?.toLowerCase() === "pending" },
          { "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "rejected" },
          { "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "expired" },
          { "bg-[#2CCCD3]": type !== "partner" },
          {
            "bg-[#E0AF00]": data_obj?.name?.toLowerCase() === "pendingcoupons",
          },
          { "bg-[#E0AF00]": data_obj?.name?.toLowerCase() === "pendingevents" },

          {
            "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "expiredcoupons",
          },
          { "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "expiredevents" },
          {
            "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "rejectedcoupons",
          },
          {
            "bg-[#F6285F]": data_obj?.name?.toLowerCase() === "rejectedevents",
          },
          {
            "bg-[#E0AF00]":
              data_obj?.name?.toLowerCase() === "waitliststudents",
          },
          {
            "bg-[#C0F]":
              data_obj?.name?.toLowerCase() === "internationalstudents",
          },
          {
            "bg-[#2CCCD3]":
              data_obj?.name?.toLowerCase() === "freemium_customers",
          },
          {
            "bg-[#12A58C]":
              data_obj?.name?.toLowerCase() === "banking_customers",
          },
          {
            "bg-[#dcde4a]":
              data_obj?.name?.toLowerCase() === "non_kyc_customers",
          },
          {
            "bg-[#C0F]":
              data_obj?.name?.toLowerCase() === "kyc_freemium_customers",
          }
        )}
      >
        {type === "partner" ? (
          data_obj?.name?.toLowerCase() === "approved" ? (
            <ApprovedIcon />
          ) : data_obj?.name?.toLowerCase() === "pending" ? (
            <WaitingIcon />
          ) : (
            <CancelIcon />
          )
        ) : (
          ""
        )}
        {type === "coupon" ? (
          data_obj?.name === "totalCoupons" ? (
            <CouponsDashIcon />
          ) : data_obj?.name === "pendingCoupons" ? (
            <WaitingWatchIcon />
          ) : (
            <ErrorIcon />
          )
        ) : (
          ""
        )}
        {type === "event" ? (
          data_obj?.name === "totalEvents" ? (
            <EventsDashIcon />
          ) : data_obj?.name === "pendingEvents" ? (
            <WaitingIcon />
          ) : (
            <WatchExclamatoryIcon />
          )
        ) : (
          ""
        )}
        {type === "i20_students" ? (
          data_obj?.name?.toLowerCase() === "approved" ? (
            <ApprovedIcon />
          ) : data_obj?.name?.toLowerCase() === "pending" ? (
            <WaitingIcon />
          ) : (
            <CancelIcon />
          )
        ) : (
          ""
        )}
        {type === "estu_app" ? (
          data_obj?.name === "freemium_customers" ? (
            <EventsDashIcon />
          ) : data_obj?.name === "banking_customers" ? (
            <UserIcon />
          ) : data_obj?.name === "non_kyc_customers" ? (
            <StudyIcon />
          ) : data_obj?.name === "kyc_freemium_customers" ? (
            <EventsDashIcon />
          ) : data_obj?.name === "totalEstuAppUsers" ? (
            <ApprovedIcon />
          ) : data_obj?.name === "waitlistStudents" ? (
            <WaitingIcon />
          ) : data_obj?.name === "totalInstitutions" ? (
            <StudyIcon />
          ) : data_obj?.name === "internationalStudents" ? (
            <>
              <NotInterested className="text-white" />
              <p className="text-white font-bold">SSN</p>
            </>
          ) : (
            <WatchExclamatoryIcon />
          )
        ) : (
          ""
        )}
      </span>
      <div className="flex flex-col gap-0">
        <p className="text-[#344767] font-montserrat md:text-2xl text-[15px] font-bold text-center md:text-left leading-[27.5px] p-0 m-0 [font-style:normal]">
          {data_obj?.total || 0}
        </p>
        <p className="text-[#2C3030] text-center font-montserrat md:text-[15px] text-[10px] font-semibold leading-5 uppercase p-0 m-0 [font-style:normal]">
          {data_obj?.desc}
          {type === "i20_students" ? " Customer" : ""}
        </p>
      </div>
    </div>
  );
};

export default ChipCard;
