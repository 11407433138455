import React, { useEffect, useState } from "react";
import CSV from "../csv/CSV";
import CSVDashboard from "./dashboard/CSVDashboard.jsx";
import classNames from "classnames";
import StartStop from "./startStop/StartStop.jsx";
import { useLocation } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet.jsx";

const Comms = () => {
  // const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  // Retrieve the active tab from localStorage, defaulting to 0 if not found
  const savedTab = localStorage.getItem("activeTab");
  const [activeTab, setActiveTab] = useState(
    savedTab ? parseInt(savedTab, 10) : 0
  );
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = () => {
    setRefreshKey((prevKey) => prevKey + 1); // Incrementing key to remount component
  };

  useEffect(() => {
    // Save the active tab to localStorage whenever it changes
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  //   if (activeTab === 1) return <CSV />;
  return (
    <WrapperOutlet
      headingText={"COMMS Dashboard"}
      headingChildOnSameLine={true}
      headingChildren={
        <button
          onClick={handleRefresh}
          className="flex items-center justify-center p-2 border rounded bg-white shadow-sm hover:bg-gray-100 transition-colors"
          aria-label="Refresh"
          title="refresh page"
        >
          <RefreshIcon />
        </button>
      }
    >
      {/* data */}
      <div className="flex-1">
        <div
          className={`flex flex-row gap-3 overflow-x-scroll no-scrollbar`}
          style={{ alignItems: "center" }}
        >
          <div
            onClick={(_) => setActiveTab(0)}
            className={classNames(
              "cursor-pointer whitespace-nowrap h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#2C3030] font-montserrat text-base font-bold leading-4 [letter-spacing:_0.34px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
              {
                "font-montserrat border-b border-b-tab text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.34px;] text-center":
                  activeTab === 0,
              }
            )}
          >
            COMMS Dashboard
          </div>
          <div
            onClick={(_) => setActiveTab(1)}
            className={classNames(
              "cursor-pointer whitespace-nowrap h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#2C3030] font-montserrat text-base font-bold leading-4 [letter-spacing:_0.34px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
              {
                "font-montserrat border-b border-b-tab text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.34px;] text-center":
                  activeTab === 1,
              }
            )}
          >
            COMMS management
          </div>
          <div
            onClick={(_) => setActiveTab(2)}
            className={classNames(
              "cursor-pointer whitespace-nowrap h-[43px] py-3 px-[19px] flex flex-row justify-center items-center border-b gap-1 text-[#2C3030] font-montserrat text-base font-bold leading-4 [letter-spacing:_0.34px] text-center border-b-white transition-[0.2s_all_ease-in-out]",
              {
                "font-montserrat border-b border-b-tab text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.34px;] text-center":
                  activeTab === 2,
              }
            )}
          >
            Opt-out List
          </div>
        </div>
        {activeTab === 0 && <CSVDashboard key={`dashboard-${refreshKey}`} />}
        {activeTab === 1 && <CSV key={`dashboard-${refreshKey}`} />}
        {activeTab === 2 && <StartStop key={`dashboard-${refreshKey}`} />}
      </div>
    </WrapperOutlet>
  );
};

export default Comms;
