import classNames from "classnames";

const CustomerReportTab = ({ setActiveTab, activeTab, tab }) => {
  return (
    <div
      title={tab.title || ""}
      onClick={(_) => setActiveTab(tab.value)}
      className={classNames(
        "cursor-pointer w-fit h-[43px] whitespace-nowrap py-3 px-1 flex flex-row justify-center items-center gap-1 text-[#67748e] font-montserrat text-[14px] font-semibold leading-4 [letter-spacing:_0.34px] text-center transition-[0.2s_all_ease-in-out]",
        {
          "font-montserrat border-b !border-b-tab text-base transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.34px] text-center":
            activeTab === tab.value,
        }
      )}
    >
      {tab.text}
    </div>
  );
};

export default CustomerReportTab;
