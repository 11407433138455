import React, { useState } from "react";
import Comment from "./Comment";
import DotLoading from "../../../../components/ui/Loading/DotLoading";
import SearchBoxWithControls from "../../../../components/ui/SearchBoxWithControl/SearchBoxWithControl";

const CommentContainer = ({ initialComments = [], loading = false }) => {
  const [comments, setComments] = useState(initialComments);
  const [errorMessage, setErrorMessage] = useState("");
  const handleSearchSubmit = (inputValue) => {
    console.log(inputValue);

    if (!inputValue.trim()) {
      setErrorMessage("Comment cannot be empty");
      return false;
    }
    setComments((prevComments) => [
      { comment: inputValue, createdAt: new Date() },
      ...prevComments,
    ]);
    console.log("Search submitted");
    setErrorMessage("");
    return true;
    //note for fellow developer, im passing true false because in SearchBoxWithControl i hv added
    // a condition to return true from onSubmit method  if true then inputValue return "".
  };

  const handleInputChange = (value) => {
    setErrorMessage("");
    console.log("Input changed:", value);
  };
  return (
    <div className="flex flex-col gap-2 pb-5">
      <p className="font-montserrat text-sm font-semibold text-[#2C3030]">
        Comments
      </p>
      <SearchBoxWithControls
        iconVisible={false}
        buttonVisible={false}
        onSubmit={handleSearchSubmit}
        onChange={handleInputChange}
        placeholder="Add a comment"
        placeholderCss={
          "placeholder:font-montserrat placeholder:text-sm placeholder:text-[#2C3030]"
        }
        buttonText="Add Status"
        errorMessage={errorMessage}
      />
      {loading ? (
        <div className="my-auto">
          <DotLoading />
        </div>
      ) : comments?.length === 0 ? (
        <p className="text-start w-full font-montserrat text-xs self-center my-auto">
          No comments yet
        </p>
      ) : (
        <div className="flex flex-col gap-2 h-full max-h-[70%] overflow-y-scroll w-full">
          {comments?.map(({ comment, createdAt }, idx) => (
            <Comment key={idx} comment={comment} date={createdAt} />
          ))}
        </div>
      )}
    </div>
  );
};

export default CommentContainer;
