import { Arrow, Line } from "../../assets/svgs/svgs";
import CustomButton from "../atoms/Button/Button";

const CardComponent = ({
  cardData,
  type,
  view,
  setShowRejectionModel,
  setSelectedDataValue,
  setShowApproveModel,
  typeLabel,
}) => {
  // Function to stop propagation of drag events for buttons and paragraphs
  const stopPropagation = (e) => {
    // console.log("bharat");
    e.stopPropagation();
  };
  return (
    <div className="flex flex-col gap-6 items-start p-2 bg-[#f8f8f8] rounded rounded-3 w-full md:w-[217px] md:max-w[217px]">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center gap-2">
          <div
            className={`w-4 h-4 rounded-full ${
              cardData?.status === "active" ? "bg-green-500" : "bg-gray-700"
            }`}
          ></div>
          <div className="font-semibold text-gray-700 text-sm">
            {cardData?.status}
          </div>
        </div>
        {typeLabel !== "amazon" && (
          <div className="text-gray-700 text-sm font-semibold">
            {cardData?.validTo}
          </div>
        )}
      </div>
      <div className="text-gray-700 text-md truncate overflow-hidden whitespace-nowrap w-full">
        {cardData?.headline}
      </div>
      <div className="overflow-hidden w-[100%] flex justify-center">
        <Line />
      </div>
      {cardData?.isStatus?.toLowerCase() === "pending" ? (
        <div className={`flex flex-row gap-2`}>
          {" "}
          <CustomButton
            onClick={(_) => {
              setShowApproveModel(true);
              setSelectedDataValue(cardData);
            }}
            onPointerDown={stopPropagation}
          >
            Approve
          </CustomButton>
          <CustomButton
            onClick={(_) => {
              setShowRejectionModel(true);
              setSelectedDataValue(cardData);
            }}
            onPointerDown={stopPropagation}
            color="red"
            variant={"contained"}
          >
            Reject
          </CustomButton>
        </div>
      ) : (
        cardData?.isStatus
      )}
      <div
        onClick={() =>
          view((prevData) => ({
            ...prevData,
            view: true,
            viewData: type,
            data: cardData,
          }))
        }
        className="flex flex-col items-center gap-3"
      >
        <div className="flex flex-row gap-2 justify-between items-center">
          <p
            className="text-gray-700 text-sm cursor-pointer hover:text-blue-500 !z-50"
            onPointerDown={stopPropagation}
          >
            See {type === 0 ? "coupon" : type === 1 ? "event" : "Amazon Coupon"}{" "}
            details
          </p>
          <div>
            <Arrow />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardComponent;
