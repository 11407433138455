import { useEffect, useState } from "react";
import { getReferralsListAPI } from "../../api/api";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Loader } from "../../components";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";

const ReferralsPage = () => {
  const [loading, setLoading] = useState(false);
  const [cData, setCData] = useState([]);

  const getAPI = async () => {
    setLoading(true);

    try {
      const res = await getReferralsListAPI();

      if (res.status === "success") {
        setCData(res?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "referrer_name", headerName: "Referrer Name", width: 150 },

    {
      field: "ref_code",
      headerName: "referral code",
      width: 100,
    },
    { field: "customer_name", headerName: "Customer Name", width: 120 },
    {
      field: "date_of_join",
      headerName: "Joining Date (EST)",

      renderCell: (params) => (
        <p className="text-center" title="yyyy-mm-dd time format EST">
          {dayjs(params?.row?.date_of_join)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD") || "---"}
        </p>
      ),
      width: 140,
    },
  ];

  return (
    <WrapperOutlet headingText={"Referrals Management"}>
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <div>
            Loading... <Loader />
          </div>
        </div>
      ) : (
        <Box className="bg-white rounded-lg shadow-md p-4 flex-1">
          <CDataGrid
            rows={cData.map((item, index) => {
              if (!item.hasOwnProperty("id")) {
                return { ...item, id: index };
              }
              return item;
            })}
            columns={columns}
            pagination
            loading={loading}
          />
        </Box>
      )}
    </WrapperOutlet>
  );
};
export default ReferralsPage;
