import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  createCampaignCodeAPI,
  deleteCampaignCodeAPI,
  getCampaignCodeAPI,
  updateCampaignCodeAPI,
} from "../../../../api/api";
import { FaSpinner, FaPlus, FaTrash, FaEdit, FaSearch } from "react-icons/fa";
import DeleteConfirmation from "../../../../components/DeleteConfirmation/DeleteConfirmation";
import TextInputField from "../../../../components/ui/TextInputField/TextInputField";
import SearchBox from "../../../../shared/SearchBoxes/SearchBox";

const TransactionCodesManager = () => {
  const [codes, setCodes] = useState([]);
  const [newCode, setNewCode] = useState({ code: "", name: "" });
  const [selectedCodeId, setSelectedCodeId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState({ code: "", name: "" });
  const [search, setSearch] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);

  const getCodes = async () => {
    setLoading(true);
    try {
      const response = await getCampaignCodeAPI();
      setCodes(response?.data || []);
    } catch (error) {
      console.error("Error fetching codes:", error);
      toast.error("Failed to fetch transaction codes");
    } finally {
      setLoading(false);
    }
  };

  const createCode = async () => {
    setSubmitting(true);
    if (!newCode.name) {
      setError({
        name: newCode.name ? "" : "Name is required.",
      });
      setSubmitting(false);
      return;
    }
    setError({ name: "" });

    try {
      await createCampaignCodeAPI(newCode);
      toast.success("Transaction code added successfully");
      getCodes();
      resetForm();
    } catch (error) {
      console.error("Error creating code:", error);
      toast.error("Failed to create transaction code");
    } finally {
      setSubmitting(false);
    }
  };

  const updateCode = async () => {
    setSubmitting(true);
    if (!newCode.name) {
      setError({
        name: newCode.name ? "" : "Name is required.",
      });
      setSubmitting(false);
      return;
    }
    setError({ code: "", name: "" });

    try {
      await updateCampaignCodeAPI({ id: selectedCodeId, data: newCode });
      toast.success("Transaction code updated successfully");
      getCodes();
      resetForm();
    } catch (error) {
      console.error("Error updating code:", error);
      toast.error("Failed to update transaction code");
    } finally {
      setSubmitting(false);
    }
  };

  const deleteCode = async () => {
    setSubmitting(true);
    try {
      await deleteCampaignCodeAPI({ id: selectedCodeId });
      toast.success("Transaction code deleted successfully");
      getCodes();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error deleting code:", error);
      toast.error("Failed to delete transaction code");
    } finally {
      setSubmitting(false);
    }
  };

  const resetForm = () => {
    setNewCode({ code: "", name: "" });
    setSelectedCodeId(null);
    setError({ code: "", name: "" });
  };

  const filteredAndSortedCodes = () => {
    const searchLower = search?.toLowerCase();

    let filteredCodes = codes.filter(
      (code) =>
        code?.name?.toLowerCase()?.includes(searchLower) ||
        code?.code?.toLowerCase()?.includes(searchLower)
    );

    return filteredCodes;
  };

  useEffect(() => {
    getCodes();
  }, []);

  const handleBlur = (field) => {
    if (!newCode[field]) {
      setError((prev) => ({
        ...prev,
        [field]: `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required.`,
      }));
    } else {
      setError((prev) => ({
        ...prev,
        [field]: "",
      }));
    }
  };

  return (
    <div className="p-6 rounded-lg shadow-lg flex flex-col gap-6 h-[90%] bg-white text-gray-800 w-full mx-auto">
      <h3 className="font-bold text-2xl text-start">
        Transaction Codes (Name-code)
      </h3>

      <div className="flex relative flex-col sm:flex-row gap-4">
        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />

        <input
          type="text"
          placeholder="Search by name or code"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="pl-10 border p-3 rounded-lg focus:outline-none focus:ring-2 ring-[#2cccd3] w-full sm:w-1/2 border-gray-300"
        />
        {/* <SearchBox
          onSearch={setSearch}
          placeholder={"Search by name or code"}
          data={search}
          active={true}
          setActive={() => {}}
        /> */}
      </div>

      {loading ? (
        <div className="flex justify-center items-center">
          <FaSpinner className="animate-spin text-[#2cccd3] text-4xl" />
        </div>
      ) : (
        <div className="overflow-y-scroll max-h-[50%]">
          <ul className="mb-6">
            {filteredAndSortedCodes()?.map((code) => (
              <li
                key={code.id}
                className="flex justify-between items-center p-4 rounded-md shadow-sm mb-3 bg-gray-50 hover:shadow-md transition-shadow"
              >
                <span className="text-lg">
                  {code.name}{" "}
                  <span className="text-gray-400">({code.code})</span>
                </span>
                <div className="flex gap-3">
                  <button
                    className="bg-yellow-500 text-white px-3 py-1 rounded-lg hover:bg-yellow-600 transition-transform transform hover:scale-105"
                    onClick={() => {
                      setNewCode({ code: code.code, name: code.name });
                      setSelectedCodeId(code.id);
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 transition-transform transform hover:scale-105"
                    onClick={() => {
                      setSelectedCodeId(code.id);
                      setIsModalOpen(true);
                    }}
                  >
                    <FaTrash />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="justify-self-end">
        {" "}
        <div className="flex flex-col sm:flex-row gap-4 mb-4">
          <TextInputField
            value={newCode.name}
            onChange={(e) => setNewCode({ ...newCode, name: e.target.value })}
            onBlur={() => handleBlur("name")}
            placeholder="Name"
            error={error.name}
            name="name"
          />
          <TextInputField
            value={newCode.code}
            onChange={(e) => setNewCode({ ...newCode, code: e.target.value })}
            placeholder="Code"
            error={error.code}
            name="code"
          />
        </div>
        {!isModalOpen ? (
          <div className="flex gap-3 pt-4">
            <button
              className={`bg-[#2cccd3] text-white px-5 py-2 rounded-lg transition-transform transform hover:scale-105 ${
                !newCode.name || submitting
                  ? "opacity-50 cursor-not-allowed"
                  : "hover:bg-[#1aa7a7]"
              }`}
              onClick={selectedCodeId ? updateCode : createCode}
              disabled={!newCode.name || submitting}
            >
              {submitting ? (
                <FaSpinner className="animate-spin inline mr-2" />
              ) : selectedCodeId ? (
                "Update Code"
              ) : (
                "Add Code"
              )}
            </button>

            {selectedCodeId && (
              <button
                className="bg-gray-400 text-white px-5 py-2 rounded-lg hover:bg-gray-500 transition-transform transform hover:scale-105"
                onClick={resetForm}
                disabled={submitting}
              >
                Cancel
              </button>
            )}
          </div>
        ) : (
          ""
        )}
      </div>

      {isModalOpen && (
        <DeleteConfirmation
          onDelete={deleteCode}
          onCancel={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default TransactionCodesManager;
