import React from "react";

const TextInputField = ({
  value,
  onChange,
  onBlur,
  placeholder,
  error,
  name,
}) => {
  return (
    <div className="flex gap-1 justify-start flex-col w-full">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={`border p-3 rounded-lg focus:outline-none focus:ring-2 ring-[#2cccd3] w-full border-gray-300 ${
          error ? "border-red-500" : ""
        }`}
      />
      {error && <div className="text-red-500 mt-2 text-sm pl-2">{error}</div>}
    </div>
  );
};

export default TextInputField;
