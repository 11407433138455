import React, { useEffect, useState } from "react";
import { Loader } from "../../components";
import {
	getCouponsList,
	getEventsList,
	getSponsorsAPI,
	UpdateBoostedSponsorApproveAPI,
	UpdateBoostedSponsorRejectAPI,
	updateCouponGlobalRank,
	UpdateCouponStatus,
	UpdateEventStatus,
} from "../../api/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AcceptRejectModel from "../admasterUsers/Accept_RejectModel";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";
import PricingUI from "../../contexts/components/PricingUI/PricingUI";
import Tabs from "../../components/Tabs";
import { columns } from "./config";
import ConvenienceFee from "../../components/ConvenienceFee";

const CouponsPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const newParams = new URLSearchParams(searchParams);
	let stateActiveTab = newParams.get("type")
		? parseInt(newParams.get("type"))
		: 2 || 2;
	let queryFilter = newParams.get("status") || "";
	let queryStatusPending = newParams.get("filter") || "";
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(stateActiveTab);
	const [page, setPage] = useState(1);
	const [pageLoading, setPageLoading] = useState(false);
	const [Data, setData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [statusFilter, setStatusFilter] = useState("");

	const [showApproveModel, setShowApproveModel] = useState(false);
	const [showRejectionModel, setShowRejectionModel] = useState(false);
	const [selectedDataValue, setSelectedDataValue] = useState({});
	const [update, setUpdate] = useState(0);
	const [rejectMessage, setRejectMessage] = useState("");

	const filterModel = queryFilter
		? {
				items: [
					{
						columnField: "status",
						operatorValue: "equals",
						value: queryFilter,
					},
				],
		  }
		: { items: [] };

	const handleTabChange = (type) => {
		setActiveTab(type);
		setSearchParams({ type });
	};

	const handleCellEditCommit = async ({ id, field, row, ...props }) => {
		// Validate input to allow only numbers
		if (!/^\d*$/.test(props?.value)) {
			toast.error("Please enter a valid number");

			return; // If not a number, do not proceed
		}

		try {
			//  update globalRank
			if (activeTab === 0 || activeTab === 2) {
				// Update for active Coupons
				await updateCouponGlobalRank({
					couponId: id,
					rank: parseInt(props?.value, 10),
				});
			} else {
				// Update for Events
				toast("Event Global Rank is not currently active");
				// await UpdateEventStatus(id, true, { globalRank: parseInt(value, 10) });
			}

			// If API call is successful, update state to trigger re-render
			setUpdate((prev) => prev + 1);
			toast.success("Global Rank updated successfully");
		} catch (error) {
			console.error("Error updating global rank:", error);
			toast.error("Failed to update Global Rank");
		}
	};

	const approveData = async () => {
		if (activeTab === 0 || activeTab === 2) {
			//Discounts Active Coupons
			try {
				const res = await UpdateCouponStatus(selectedDataValue.id, true);
				setShowApproveModel(false);
				// console.log(res);
				toast.success(res?.message);
				setUpdate((prev) => prev + 1);
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			}
		} else if (activeTab === 1) {
			try {
				const res = await UpdateEventStatus(selectedDataValue.id, true);
				setShowApproveModel(false);
				setUpdate((prev) => prev + 1);
				toast.success(res?.message);
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			}
		} else if (activeTab === 3 || activeTab === 4) {
			try {
				const res = await UpdateBoostedSponsorApproveAPI(
					selectedDataValue.id,
					true
				);
				setShowApproveModel(false);
				setUpdate((prev) => prev + 1);
				toast.success(res?.message);
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			}
		}
	};

	const rejectData = async () => {
		if (!rejectMessage) {
			toast.error("Please enter a reason for rejection");
			return;
		}

		if (activeTab === 0 || activeTab === 2) {
			try {
				const res = await UpdateCouponStatus(
					selectedDataValue.id,
					false,
					rejectMessage
				);
				setShowRejectionModel(false);
				setUpdate((prev) => prev + 1);
				toast.success(res?.message);
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			} finally {
				setRejectMessage("");
			}
		} else if (activeTab === 1) {
			try {
				const res = await UpdateEventStatus(
					selectedDataValue.id,
					false,
					rejectMessage
				);
				setShowRejectionModel(false);
				setUpdate((prev) => prev + 1);
				toast.success(res?.message);
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			} finally {
				setRejectMessage("");
			}
		} else if (activeTab === 3 || activeTab === 4) {
			//boosted 3 event 4 deals
			try {
				const res = await UpdateBoostedSponsorRejectAPI(
					selectedDataValue.id,
					false,
					rejectMessage
				);
				setShowRejectionModel(false);
				setUpdate((prev) => prev + 1);
				toast.success(res?.message);
			} catch (error) {
				console.log(error);
				toast.error("Something went wrong!");
			} finally {
				setRejectMessage("");
			}
		}
	};

	const getAPI = async (tab) => {
		setPageLoading(true);
		if (tab === 0 || tab === 2) {
			//coupons
			let params = {};

			if (tab === 0) {
				// Case for tab === 0 //active only
				params = { expired: "none", random: true }; //Note:- Added random true to remove globalRank Concept
			} else if (tab === 2) {
				params = { random: true };
			}
			try {
				const response = await getCouponsList(params);
				if (response?.status) {
					setData(response.data || []);
					setFilteredData(response.data || []);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setPageLoading(false);
			}
		} else if (tab === 1) {
			//events
			try {
				const response = await getEventsList({});
				// console.log(response);
				if (response?.status) {
					setData(response.data || []);
					setFilteredData(response.data || []);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setPageLoading(false);
			}
		} else if (tab === 3 || tab === 4) {
			try {
				const response = await getSponsorsAPI({
					type: activeTab === 3 ? "event" : "deal",
				});
				// console.log(response);
				if (response?.status) {
					setData(response.data || []);
					setFilteredData(response.data || []);
				}
			} catch (error) {
				console.log(error);
			} finally {
				setPageLoading(false);
			}
		} else {
			//activeTab -===5 and im calling its api in their component
			setPageLoading(false);
		}
	};

	useEffect(() => {
		if (queryStatusPending) {
			setStatusFilter("PENDING");
		} else {
			setStatusFilter("");
		}
		setPage(1);
	}, [location]);

	useEffect(() => {
		// Set the initial active tab based on navigation state
		setPage(1);

		getAPI(activeTab);

		if (queryStatusPending) {
			setStatusFilter("PENDING");
		} else {
			setStatusFilter("");
		}
	}, [activeTab, setActiveTab]);

	useEffect(() => {
		if (update) getAPI(activeTab);
	}, [update]);

	useEffect(() => {
		if ([0, 1, 2].includes(activeTab)) {
			if (statusFilter) {
				setFilteredData(Data.filter((item) => item.isStatus === statusFilter));
			} else {
				setFilteredData(Data);
			}
		} else if ([3, 4].includes(activeTab)) {
			if (statusFilter) {
				setFilteredData(Data.filter((item) => item.approval === statusFilter));
			} else {
				setFilteredData(Data);
			}
		} else if ([5].includes(activeTab)) {
			setFilteredData(Data);
		}
	}, [statusFilter, Data]);

	return (
		<WrapperOutlet
			headingText={"Coupons And Events"}
			headingChildren={
				<div className='flex flex-row items-center gap-2 self-start pb-2'>
					<label
						htmlFor='statusFilter'
						className='text-sm font-medium text-gray-700'
					>
						Filter by Status:
					</label>
					<select
						id='statusFilter'
						value={statusFilter}
						onChange={(e) => setStatusFilter(e.target.value)}
						className='border border-gray-300 rounded-md px-2 py-1 text-sm cursor-pointer'
					>
						<option value=''>All</option>
						<option value='PENDING'>Pending</option>
						<option value='APPROVED'>Approved</option>
						<option value='REJECTED'>Rejected</option>
					</select>
				</div>
			}
		>
			<div className='flex flex-col bg-white rounded-[10px] gap-5 shadow-[10px_10px_10px_lightgrey] px-5 py-2.5 h-full'>
				{/* start tab navigation */}
				<div
					className={
						"flex md:flex-row flex-col w-full md:items-center gap-4 justify-between"
					}
				>
					<div
						className={`flex flex-row overflow-x-scroll no-scrollbar gap-3 max-w-[90%] h-[50px] items-center`}
					>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"Active Coupons"}
							index={0}
						/>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"All Coupons"}
							index={2}
						/>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"Events"}
							index={1}
						/>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"Boosted Events"}
							index={3}
						/>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"Boosted Coupons"}
							index={4}
						/>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"Set Price for Boost"}
							index={5}
						/>
						<Tabs
							handleTabChange={handleTabChange}
							activeTab={activeTab}
							label={"Set ticket creation fee"}
							index={6}
						/>
					</div>
				</div>
				{/* end tab navigation */}
				<div className='h-full no-vertical-scrollbar overflow-y-scroll'>
					{pageLoading ? (
						<Loader />
					) : activeTab === 5 ? (
						<div className='w-full h-full'>
							<PricingUI />
						</div>
					) : activeTab === 6 ? (
						<div className='w-full h-full'>
							<ConvenienceFee />
						</div>
					) : filteredData?.length > 0 ? (
						<>
							<CDataGrid
								rows={filteredData}
								columns={columns(
									navigate,
									setShowApproveModel,
									setSelectedDataValue,
									activeTab
								).map((column, idx) => ({
									...column,
									indexId: idx + 1,
									editable: column.field === "globalRank" && activeTab !== 1,
								}))}
								pagination
								onCellEditCommit={handleCellEditCommit}
								filterModel={filterModel}
								loading={pageLoading}
							/>
						</>
					) : (
						<div className='h-full flex flex-col items-center justify-center select-none'>
							<p className='text-center hover:scale-105 transition-transform font-montserrat cursor-pointer'>
								No Data Available!
							</p>
						</div>
					)}

					<AcceptRejectModel
						handleClose={(_) => setShowApproveModel(false)}
						open={showApproveModel}
						onClick={approveData}
						title={`Approve ${activeTab === 0 ? "Coupon" : "Event"}`}
					/>
					<AcceptRejectModel
						showReject={true}
						title={`Reject ${activeTab == 0 ? "Coupon" : "Event"}`}
						handleClose={(_) => {
							setShowRejectionModel(false);
							setRejectMessage("");
						}}
						open={showRejectionModel}
						onClick={rejectData}
						rejectMessage={rejectMessage}
						setRejectMessage={setRejectMessage}
						reasonAdd={true}
					/>
				</div>
			</div>
		</WrapperOutlet>
	);
};

export default CouponsPage;
