import dayjs from "dayjs";
import CustomButton from "../../components/atoms/Button/Button";
import { toast } from "react-toastify";
import UsersIcon from "../../assets/svgs/user_admaster.svg";

const tabs = [
	{
		text: "All",
		value: "all",
		title: "View data for all customers",
	},
	{
		text: "Not Onboarded",
		value: "nonkyc",
		title: "Users not onboarded or failed KYC verification",
	},
	{
		text: "Freemium",
		value: "freemium",
		title:
			"Users onboarded without completing KYC (SKIP BUTTON CLICK ON INITIAL ONBOARDING)",
	},

	{
		text: "Freemium (KYC Failed / Awaiting)",
		value: "kycfreemium",
		title: "Users who skipped KYC or whose KYC is pending/failed",
	},
	{
		text: "Banking",
		value: "kyc",
		title: "Fully onboarded users with access to financial services",
	},
];

const columns = (
	navigate,
	setShowApproveModel,
	setSelectedDataValue,
	activeTab
) => [
	{
		field: "status",
		headerName: "Status",
		width: 100,
		renderCell: (params) => {
			const isExpired = params.row.status === "expired";
			const statusColor = isExpired ? "bg-red-500" : "bg-green-500";
			const statusText = isExpired ? "Expired" : "Active";
			return (
				<div className='flex items-center gap-2'>
					<div
						className={`w-3 h-3 rounded-full ${statusColor} ${
							isExpired ? "animate-pulse duration-500" : "animate-pulse"
						}`}
					></div>
					<span className='select-none'>{statusText}</span>
				</div>
			);
		},
	},
	...(activeTab === 0
		? [
				//active coupons
				{
					field: "poster",
					headerName: "Poster",
					width: 80,
					renderCell: (params) => (
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.poster}`}
							alt='Poster'
							className='w-10 h-10 rounded-full'
						/>
					),
				},
				{
					field: "instituteName",
					headerName: "Company Name",
					width: 150,
					renderCell: (params) => {
						// console.log(params);
						return (
							<div
								onClick={(_) => {
									if (!params.row?.user?.id)
										return toast("User is not exist or deleted");
									navigate(
										`/estu-ads/discount-coupons/${params.row?.user?.id}`,
										{
											state: {
												from: activeTab === 1 ? "event" : "",
												lastPage: "/coupons-management",
											},
										}
									);
								}}
								className={
									"flex flex-row gap-2 align-center justify-center p-2 items-center"
								}
							>
								{params.row?.user?.profilePhoto ? (
									<div className='flex items-center justify-center shrink-0'>
										<img
											src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.user?.profilePhoto}`}
											className='w-10 h-10 rounded-full'
										/>
									</div>
								) : (
									<div className='flex items-center justify-center'>
										<img src={UsersIcon} className='w-10 h-10 rounded-full' />
									</div>
								)}
								<div>
									<p className='w-28 text-ellipsis whitespace-nowrap overflow-hidden'>
										{params.row?.user?.instituteName || "-----"}
									</p>
								</div>
							</div>
						);
					},
					// Custom filter function to enable filtering on instituteName
					renderCell: (params) => params.row?.user?.instituteName || "",
				},
				{
					field: "headline",
					headerName: "Headline",
					width: 200,
				},
				{
					field: "caption",
					headerName: "Caption",
					width: 200,
					renderCell: (params) => params.row?.caption || "---",
					// hide: activeTab === 1, // Hide for events tab
				},
		  ]
		: activeTab === 1
		? [
				//events
				{
					field: "poster",
					headerName: "Poster",
					width: 80,
					renderCell: (params) => (
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.poster}`}
							alt='Poster'
							className='w-10 h-10 rounded-full'
						/>
					),
				},
				{
					field: "instituteName",
					headerName: "Company Name",
					width: 150,
					renderCell: (params) => {
						// console.log(params);
						return (
							<div
								onClick={(_) => {
									if (!params.row?.user?.id)
										return toast("User is not exist or deleted");
									navigate(
										`/estu-ads/discount-coupons/${params.row?.user?.id}`,
										{
											state: {
												from: activeTab === 1 ? "event" : "",
												lastPage: "/coupons-management",
											},
										}
									);
								}}
								className={
									"flex flex-row gap-2 align-center justify-center p-2 items-center"
								}
							>
								{params.row?.user?.profilePhoto ? (
									<div className='flex items-center justify-center shrink-0'>
										<img
											src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.user?.profilePhoto}`}
											className='w-10 h-10 rounded-full'
										/>
									</div>
								) : (
									<div className='flex items-center justify-center'>
										<img src={UsersIcon} className='w-10 h-10 rounded-full' />
									</div>
								)}
								<div>
									<p className='w-28 text-ellipsis whitespace-nowrap overflow-hidden'>
										{params.row?.user?.instituteName || "-----"}
									</p>
								</div>
							</div>
						);
					},
					// Custom filter function to enable filtering on instituteName
					renderCell: (params) => params.row?.user?.instituteName || "",
				},
				{
					field: "headline",
					headerName: "Headline",
					width: 150,
				},
				{
					field: "ticketTitle",
					headerName: "Ticket Title",
					width: 200,
					renderCell: (params) => params.row?.ticketTitle || "---",
					hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "when",
					headerName: "Start Date (EST)",
					width: 130,
					// renderCell: (params) => params.row?.when || "---",
					renderCell: (params) =>
						dayjs(params?.row?.when)
							?.tz("America/New_York")
							.format("YYYY-MM-DD") || "---",

					// hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "tillDate",
					headerName: "Till Date (EST)",
					width: 130,
					// renderCell: (params) => params.row?.tillDate || "---",
					renderCell: (params) =>
						dayjs(params?.row?.tillDate)
							?.tz("America/New_York")
							.format("YYYY-MM-DD") || "---",
				},
				{
					field: "time",
					headerName: "Start Time",
					width: 100,
					renderCell: (params) => params.row?.time || "---",
					// hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "till",
					headerName: "End Time",
					width: 100,
					renderCell: (params) => params.row?.till || "---",
					// hide: activeTab !== 1, // Hide for events tab
				},
		  ]
		: activeTab === 2
		? [
				//all coupons
				{
					field: "poster",
					headerName: "Poster",
					width: 80,
					renderCell: (params) => (
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.poster}`}
							alt='Poster'
							className='w-10 h-10 rounded-full'
						/>
					),
				},
				{
					field: "instituteName",
					headerName: "Company Name",
					width: 150,
					renderCell: (params) => {
						// console.log(params);
						return (
							<div
								onClick={(_) => {
									if (!params.row?.user?.id)
										return toast("User is not exist or deleted");
									navigate(
										`/estu-ads/discount-coupons/${params.row?.user?.id}`,
										{
											state: {
												from: activeTab === 1 ? "event" : "",
												lastPage: "/coupons-management",
											},
										}
									);
								}}
								className={
									"flex flex-row gap-2 align-center justify-center p-2 items-center"
								}
							>
								{params.row?.user?.profilePhoto ? (
									<div className='flex items-center justify-center shrink-0'>
										<img
											src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.user?.profilePhoto}`}
											className='w-10 h-10 rounded-full'
										/>
									</div>
								) : (
									<div className='flex items-center justify-center'>
										<img src={UsersIcon} className='w-10 h-10 rounded-full' />
									</div>
								)}
								<div>
									<p className='w-28 text-ellipsis whitespace-nowrap overflow-hidden'>
										{params.row?.user?.instituteName || "-----"}
									</p>
								</div>
							</div>
						);
					},
					// Custom filter function to enable filtering on instituteName
					renderCell: (params) => params.row?.user?.instituteName || "",
				},
				{
					field: "headline",
					headerName: "Headline",
					width: 200,
				},
				{
					field: "caption",
					headerName: "Caption",
					width: 200,
					renderCell: (params) => params.row?.caption || "---",
					// hide: activeTab === 1, // Hide for events tab
				},
		  ]
		: activeTab === 3
		? [
				//boosted events
				{
					field: "eventPoster",
					headerName: "Poster",
					width: 100,
					renderCell: (params) => (
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.eventPoster}`}
							alt='Poster'
							className='w-10 h-10 rounded-full'
						/>
					),
				},
				{
					field: "instituteName",
					headerName: "Company Name",
					width: 150,
					renderCell: (params) => {
						// console.log(params);
						return (
							<div
								onClick={(_) => {
									if (!params.row?.userId)
										return toast("User is not exist or deleted");
									navigate(`/estu-ads/discount-coupons/${params.row?.userId}`, {
										state: {
											from: activeTab === 1 ? "event" : "",
											lastPage: "/coupons-management",
										},
									});
								}}
								className={
									"flex flex-row gap-2 align-center justify-center p-2 items-center"
								}
							>
								{params.row?.profilePhoto ? (
									<div className='flex items-center justify-center shrink-0'>
										<img
											src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.profilePhoto}`}
											className='w-10 h-10 rounded-full'
										/>
									</div>
								) : (
									<div className='flex items-center justify-center'>
										<img src={UsersIcon} className='w-10 h-10 rounded-full' />
									</div>
								)}
								<div>
									<p className='w-28 text-ellipsis whitespace-nowrap overflow-hidden'>
										{params.row?.instituteName || "-----"}
									</p>
								</div>
							</div>
						);
					},
					// Custom filter function to enable filtering on instituteName
					renderCell: (params) => params.row?.instituteName || "",
				},
				{
					field: "eventName",
					headerName: "Event",
					width: 150,
					renderCell: (params) => params.row?.eventName || "---",
					// hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "start",
					headerName: "Start Date (EST)",
					width: 150,
					// renderCell: (params) => params.row?.when || "---",
					renderCell: (params) =>
						dayjs(params?.row?.start)
							?.tz("America/New_York")
							.format("YYYY-MM-DD") || "---",

					// hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "end",
					headerName: "Till Date (EST)",
					width: 150,
					// renderCell: (params) => params.row?.tillDate || "---",
					renderCell: (params) =>
						dayjs(params?.row?.end)
							?.tz("America/New_York")
							.format("YYYY-MM-DD") || "---",

					// hide: activeTab !== 1, // Hide for events tab
					// hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "cost",
					headerName: "Budget(USD)",
					type: "number",
					width: 100,
				},

				{
					field: "university",
					headerName: "University/College",
					renderCell: (params) => {
						const university = params?.row?.targetMetaData?.university;
						return (
							<div className='text-center'>
								{university ? university : "---"}
							</div>
						);
					},
					width: 160,
					editable: false,
				},
				{
					field: "state",
					headerName: "State",
					renderCell: (params) => {
						const state = params?.row?.targetMetaData?.state;
						return <div className='text-center'>{state ? state : "---"}</div>;
					},
					width: 110,
					editable: false,
				},
				{
					field: "city",
					headerName: "City",
					renderCell: (params) => {
						const city = params?.row?.targetMetaData?.city;
						return <div className='text-center'>{city ? city : "---"}</div>;
					},
					width: 110,
					editable: false,
				},
				{
					field: "approval",
					headerName: "Action",
					width: 180,
					// hide: statusFilter !== "PENDING",
					renderCell: (param) => {
						const data = param.row;

						const row = {
							...data,
						};

						return (
							<div className={``}>
								{row?.approval ? (
									row?.approval?.toLowerCase() === "pending" ? (
										<div className={`flex flex-row gap-2 select-none`}>
											{/* {" "} */}
											<CustomButton
												onClick={(_) => {
													setShowApproveModel(true);
													setSelectedDataValue(row);
												}}
											>
												Approve
											</CustomButton>
											<CustomButton
												onClick={(_) => {
													setShowRejectionModel(true);
													setSelectedDataValue(row);
												}}
												color='red'
												variant={"contained"}
											>
												Reject
											</CustomButton>
										</div>
									) : (
										<div>{row?.approval?.toUpperCase()}</div>
									)
								) : null}
							</div>
						);
					},
				},
		  ]
		: activeTab === 4
		? [
				//boosted coupons
				{
					field: "dealPoster",
					headerName: "Poster",
					width: 80,
					renderCell: (params) => (
						<img
							src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.dealPoster}`}
							alt='Poster'
							className='w-10 h-10 rounded-full'
						/>
					),
				},
				{
					field: "instituteName",
					headerName: "Company Name",
					width: 150,
					renderCell: (params) => {
						// console.log(params);
						return (
							<div
								onClick={(_) => {
									if (!params.row?.userId)
										return toast("User is not exist or deleted");
									navigate(`/estu-ads/discount-coupons/${params.row?.userId}`, {
										state: {
											from: activeTab === 1 ? "event" : "",
											lastPage: "/coupons-management",
										},
									});
								}}
								className={
									"flex flex-row gap-2 align-center justify-center p-2 items-center"
								}
							>
								{params.row?.profilePhoto ? (
									<div className='flex items-center justify-center shrink-0'>
										<img
											src={`${process.env.REACT_APP_IMAGE_BASEURL}${params.row?.profilePhoto}`}
											className='w-10 h-10 rounded-full'
										/>
									</div>
								) : (
									<div className='flex items-center justify-center'>
										<img src={UsersIcon} className='w-10 h-10 rounded-full' />
									</div>
								)}
								<div>
									<p className='w-28 text-ellipsis whitespace-nowrap overflow-hidden'>
										{params.row?.instituteName || "-----"}
									</p>
								</div>
							</div>
						);
					},
					// Custom filter function to enable filtering on instituteName
					renderCell: (params) => params.row?.instituteName || "",
				},
				{
					field: "dealName",
					headerName: "Deal",
					width: 150,
					renderCell: (params) => params.row?.dealName || "---",
					// hide: activeTab !== 1, // Hide for events tab
				},
				{
					field: "start",
					headerName: "Start Date (EST)",
					width: 150,
					renderCell: (params) =>
						dayjs(params?.row?.start)
							?.tz("America/New_York")
							.format("YYYY-MM-DD") || "---",
				},
				{
					field: "end",
					headerName: "Till Date (EST)",
					width: 150,
					renderCell: (params) =>
						dayjs(params?.row?.end)
							?.tz("America/New_York")
							.format("YYYY-MM-DD") || "---",
				},
				{
					field: "cost",
					headerName: "Budget(USD)",
					type: "number",
					width: 110,
				},

				{
					field: "university",
					headerName: "University/College",
					renderCell: (params) => {
						const university = params?.row?.targetMetaData?.university;
						return (
							<div className='text-center'>
								{university ? university : "---"}
							</div>
						);
					},
					width: 160,
					editable: false,
				},
				{
					field: "state",
					headerName: "State",
					renderCell: (params) => {
						const state = params?.row?.targetMetaData?.state;
						return <div className='text-center'>{state ? state : "---"}</div>;
					},
					width: 110,
					editable: false,
				},
				{
					field: "city",
					headerName: "City",
					renderCell: (params) => {
						const city = params?.row?.targetMetaData?.city;
						return <div className='text-center'>{city ? city : "---"}</div>;
					},
					width: 110,
					editable: false,
				},
				{
					field: "approval",
					headerName: "Action",
					width: 180,
					// hide: statusFilter !== "PENDING",
					renderCell: (param) => {
						const data = param.row;

						const row = {
							...data,
						};

						return (
							<div className={``}>
								{row?.approval ? (
									row?.approval?.toLowerCase() === "pending" ? (
										<div className={`flex flex-row gap-2 select-none`}>
											{" "}
											<CustomButton
												onClick={(_) => {
													setShowApproveModel(true);
													setSelectedDataValue(row);
												}}
											>
												Approve
											</CustomButton>
											<CustomButton
												onClick={(_) => {
													setShowRejectionModel(true);
													setSelectedDataValue(row);
												}}
												color='red'
												variant={"contained"}
											>
												Reject
											</CustomButton>
										</div>
									) : (
										<div>{row?.approval?.toUpperCase()}</div>
									)
								) : null}
							</div>
						);
					},
				},
		  ]
		: [
				{
					field: "isStatus",
					headerName: "Action",
					width: 200,
					hide: statusFilter !== "PENDING",
					renderCell: (param) => {
						const data = param.row;

						const row = {
							...data,
						};

						return (
							<div className={``}>
								{row?.isStatus ? (
									row?.isStatus?.toLowerCase() === "pending" ? (
										<div className={`flex flex-row gap-2 select-none`}>
											{" "}
											<CustomButton
												onClick={(_) => {
													setShowApproveModel(true);
													setSelectedDataValue(row);
												}}
											>
												Approve
											</CustomButton>
											<CustomButton
												onClick={(_) => {
													setShowRejectionModel(true);
													setSelectedDataValue(row);
												}}
												color='red'
												variant={"contained"}
											>
												Reject
											</CustomButton>
										</div>
									) : (
										row.isStatus
									)
								) : null}
							</div>
						);
					},
				},
		  ]),
];

export { tabs, columns };
