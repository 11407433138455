// import { useStateContext } from "../../../contexts/ContextProvider";

// const MenuChip = ({ navigate, url, name, icon }) => {
//   const { menuToggle } = useStateContext();

//   return (
//     <div
//       onClick={() => navigate(url)}
//       className={` ${
//         window?.location?.pathname === url ? "bg-white shadow-lg " : ""
//       } flex items-center py-[11px] cursor-pointer hover:bg-[#e9eeef] hover:shadow-lg rounded-[8px] whitespace-nowrap w-full
//     ${menuToggle ? "pl-[16px]" : " justify-center"}`}
//     >
//       {icon && (
//         <div
//           title={name}
//           className={`${
//             window?.location?.pathname === url
//               ? "bg-[#2CCCD3] text-white"
//               : "text-black"
//           }  rounded-[8px] shadow p-[10px] `}
//         >
//           {icon ? icon : null}
//         </div>
//       )}
//       {menuToggle && <div className="pl-[14px] pr-2 w-fit">{name}</div>}
//     </div>
//   );
// };

// export default MenuChip;
import { useStateContext } from "../../../contexts/ContextProvider";

const MenuChip = ({ navigate, url, name, icon, onClick = () => {} }) => {
  const { menuToggle } = useStateContext();
  const handleOnClick = () => {
    onClick();
    navigate(url);
  };
  return (
    <div
      onClick={handleOnClick}
      className={`${
        window?.location?.pathname === url ? "bg-white shadow-lg" : ""
      } flex items-center py-[11px] cursor-pointer hover:bg-[#e9eeef] hover:shadow-lg rounded-[8px] whitespace-nowrap w-full px-2
        ${
          menuToggle
            ? "pl-[16px] md:w-it"
            : "md:justify-start text-center sm:pl-[8px] md:pr-[8px] md:w-fit"
        } 
        sm:py-[8px] sm:px-[10px] sm:mb-2`}
    >
      {icon && (
        <div
          title={name}
          className={`${
            window?.location?.pathname === url
              ? "bg-[#2CCCD3] text-white"
              : "text-black"
          } rounded-[8px] shadow p-[10px] sm:p-[8px] ${
            menuToggle ? "md:mr-2" : "mr-0"
          }`}
        >
          {icon}
        </div>
      )}
      {/* {menuToggle && (
        <div className="pl-[14px] pr-2 w-fit sm:text-[14px] sm:font-medium">
          {name}
        </div>
      )} */}

      <div
        className={`${
          menuToggle ? "md:block" : "md:hidden block"
        } pl-[14px] pr-2 w-fit sm:text-[14px] sm:font-medium`}
      >
        {name}
      </div>
    </div>
  );
};

export default MenuChip;
