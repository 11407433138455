import React from "react";
import PropTypes from "prop-types";
import { CrossIcon, PrevButtonIcon } from "../../../../assets/svgs/svgs"; // Replace with your SVGs or icons

const EstuModal = ({
  open,
  handleClose,
  title,
  subTitle,
  children,
  prevButton,
  onClickPrevButton,
  widthFull = true,
  heightFull = false,
  outsideClickable = true,
  bgColor = "#F2F8FA",
}) => {
  if (!open) return null;

  return (
    <div
      className="fixed inset-0 flex items-center no-scrollbar justify-center bg-black bg-opacity-50 z-50"
      onClick={() => outsideClickable && handleClose()}
    >
      <div
        className={`max-w-[98vw] rounded-lg shadow-lg ${
          widthFull ? "w-fit mx-2" : "max-w-[400px]"
        } ${
          heightFull ? "h-full" : "max-h-[98vh]"
        } overflow-y-auto no-scrollbar relative`}
        onClick={(e) => e.stopPropagation()}
        style={{ backgroundColor: bgColor }}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <div className="flex items-center gap-2">
            {prevButton && (
              <button
                onClick={onClickPrevButton}
                className="p-2 rounded-full hover:bg-gray-200 transition duration-300"
              >
                <PrevButtonIcon />
              </button>
            )}
            <h2 className="text-xl font-semibold text-gray-800">{title}</h2>
          </div>
          <button
            onClick={handleClose}
            className="p-2 rounded-full hover:bg-gray-200 transition duration-300"
          >
            <CrossIcon />
          </button>
        </div>

        {subTitle && (
          <div className="p-4 text-sm text-gray-600 border-b border-gray-200">
            {subTitle}
          </div>
        )}

        <div className="p-4 w-fit overflow-x-scroll no-scrollbar max-w-[98vw] text-black">
          {children}
        </div>
      </div>
    </div>
  );
};

EstuModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  prevButton: PropTypes.bool,
  onClickPrevButton: PropTypes.func,
  widthFull: PropTypes.bool,
  heightFull: PropTypes.bool,
  outsideClickable: PropTypes.bool,
};

export default EstuModal;
