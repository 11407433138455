import React from "react";
import TagCreator from "./components/TagCreator";

const ColorTagCreator = ({
  heading,
  buttonText,
  iconVisible,
  buttonVisible,
  onSubmit,
  onChange,
  placeholder,
  placeholderCss,
  errorMessage,
}) => {
  return (
    <div className="flex w-full flex-col gap-3">
      {heading && (
        <p className="font-normal font-montserrat text-sm  text-[#2C3030]">
          {heading}
        </p>
      )}
      <TagCreator
        iconVisible={iconVisible}
        buttonVisible={buttonVisible}
        onSubmit={onSubmit}
        onChange={onChange}
        placeholder={placeholder}
        placeholderCss={
          placeholderCss ||
          "placeholder:font-montserrat placeholder:text-sm placeholder:text-[#2C3030]"
        }
        buttonText={buttonText}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ColorTagCreator;
