import React from "react";
import { CircularProgress } from "@mui/material";
import DotLoading from "../ui/Loading/DotLoading";

const Loader = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <CircularProgress style={{ color: "#2cccd3" }} /> */}
      <DotLoading />
    </div>
  );
};

export default Loader;
