import React, { useEffect, useState } from "react";
import DotLoading from "../../../../components/ui/Loading/DotLoading";
import { getSupportActivityAPI } from "../../../../api/api";

const Activity = ({ selectedActivity }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const getAPI = async () => {
    try {
      setLoading(true);
      const response = await getSupportActivityAPI({
        id: selectedActivity?.id,
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  return (
    <div className="flex flex-col md:gap-4 gap-2 min-h-[400px] md:min-w-[400px] min-w-[80vw] max-h-[500px] overflow-y-scroll">
      {loading ? (
        <div className="my-auto">
          <DotLoading />
        </div>
      ) : data?.length === 0 ? (
        <p className="text-center w-full font-montserrat text-base self-center my-auto justify-self-center">
          No Activities Found!
        </p>
      ) : (
        <>
          {data?.map((msg, idx) => (
            <Message data={msg} key={idx} />
          ))}
        </>
      )}
    </div>
  );
};

const Message = ({ data }) => {
  return (
    <div className="flex flex-col gap-2 items-start justify-start">
      <div className="flex flex-row font-montserrat text-sm font-normal w-full bg-[#F2F8FA] rounded-lg p-2.5">
        <span>{data.value1}</span>&nbsp;
        <span className="font-bold uppercase">{data?.value2}</span>
      </div>
      <div className="flex flex-row items-start font-montserrat text-[10px] font-medium text-[#526163]">
        {/* {data?.updatedAt || "date"} */}
        {data?.updatedAt
          ? new Date(data.updatedAt).toLocaleString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "numeric",
              minute: "2-digit",
              hour12: true,
            })
          : "date"}
      </div>
    </div>
  );
};

export default Activity;
