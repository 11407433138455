import React, { useEffect, useState } from "react";
import CommentContainer from "../Comment/CommentContainer";
import AddInputContainer from "../AddInputContainer/AddInputContainer";
import RadioButtonContainer from "../RadioButtonContainer/RadioButtonContainer";
import {
  addIssueTypeForTicketAPI,
  getIssueTypeListAPI,
  updateSupportTIcketTagsAPI,
} from "../../../../api/api";
import ColorTagCreator from "../../../../components/ui/ColorTagCreator/ColorTagCreator";
import { toast } from "react-toastify";

const IssueType = ({ ticket, onChange = () => {} }) => {
  console.log(ticket, "hh issue type");
  const [statusErrorMessage, setStatusErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIssueTypeId, setSelectedIssueTypeId] = useState(
    ticket?.data?.issueType?.id
  );
  const handleAddIssueType = async (data) => {
    if (!data?.name) {
      toast.error("Please enter a IssueType name.");
      return;
    }

    try {
      const response = await addIssueTypeForTicketAPI({ name: data?.name });
      console.log(response);
      if (response?.status === "success") {
        toast.success(
          response?.data?.message || "IssueType added successfully"
        );
        // getAPI();
        setData((prev) => [...prev, response?.data]);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const handleInputChange = (value) => {
    setStatusErrorMessage("");
  };

  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await getIssueTypeListAPI();
      const fetchedData = response.data || [];
      setData(fetchedData);
    } catch (error) {
      console.error(error);
      setStatusErrorMessage("Failed to fetch issue types.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectIssueType = async (issueTypeId) => {
    console.log(issueTypeId, ticket, "hh");
    if (issueTypeId === selectedIssueTypeId) {
      return toast.info("please select another status");
    }
    try {
      const response = await updateSupportTIcketTagsAPI(ticket?.data?.id, {
        issueType: issueTypeId,
      });
      if (onChange) onChange();
      setSelectedIssueTypeId(issueTypeId);
      // console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("Failed to update status");
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  return (
    <div className="flex flex-col min-h-[400px] md:min-w-[400px] min-w-[80vw] max-h-[500px] md:gap-4 gap-4">
      <RadioButtonContainer
        data={data}
        name="Issue Type"
        loading={loading}
        selectedData={selectedIssueTypeId}
        onSelect={handleSelectIssueType}
      />
      <hr />
      <ColorTagCreator
        heading="Add new issue type"
        headingCss="font-normal font-montserrat text-sm font-bold text-[#2C3030]"
        buttonText="Add"
        iconVisible={false}
        buttonVisible={true}
        onSubmit={handleAddIssueType}
        onChange={handleInputChange}
        placeholder="Issue type"
        placeholderCss={
          "placeholder:font-montserrat placeholder:text-xs placeholder:font-normal placeholder:text-[#8F8F8F]"
        }
        errorMessage={statusErrorMessage}
      />
    </div>
  );
};

export default IssueType;
