const handleNonNegativeChange = (e, setter) => {
  const value = e?.target?.value;
  if (value === "" || parseFloat(value) >= 0) {
    setter(value);
  }
};
const calculatePercentages = (data, total) => {
  return data.map((item) => ({
    ...item,
    percentage: total ? ((item.total / total) * 100).toFixed(2) : 0,
  }));
};
const hexToRgb = (hex) => {
  // Remove the hash at the start if it's there
  hex = hex.replace("#", "");

  // Parse the r, g, b values
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
};

const calculateLuminance = (r, g, b) => {
  // Apply the luminance formula for human perception of lightness
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const getTextColor = (hex) => {
  const { r, g, b } = hexToRgb(hex);
  const luminance = calculateLuminance(r, g, b);

  // If luminance is below 150, it is considered a dark color, use light text color
  return luminance < 150 ? "#ffffff" : "#000000";
};

// NOTE:- use getTextColor if you have a background color and you want textColor for ui purpose
// // Example usage:
// const backgroundColor = '#333333'; // Dark background
// const textColor = getTextColor(backgroundColor);
// console.log(Background Color: ${backgroundColor}, Text Color: ${textColor});

export {
  handleNonNegativeChange,
  calculatePercentages,
  hexToRgb,
  calculateLuminance,
  getTextColor,
};
