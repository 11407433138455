import classNames from "classnames";
import React from "react";

const PageHeading = ({ text, className, ...rest }) => {
  return (
    <h2
      className={classNames(
        "text-[#344767] font-roboto font-bold text-center text-2xl bg-transparent leading-[auto]",
        className
      )}
      {...rest}
    >
      {text}
    </h2>
  );
};

export default PageHeading;
