import { CircularProgress, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import UserBrand from "./UserBrand";
import { getUsersAsBrandsAPI, searchByUserNameAPI } from "../../../api/api";
import Pagination from "../../../shared/pagination/Pagination";

const DraggableBrandContainer = ({
  brands,
  setBrands,
  selectedBrandId,
  setSelectedBrandId,
  selectedGroupId,
}) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);

  const debounce = (func, wait) => {
    let timeout;
    return function (...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const debouncedSearch = useCallback(
    debounce((query) => {
      callSearchByUserName(query);
    }, 1000),
    []
  );
  // const debouncedSearch1 = useCallback(
  // 	debounce(() => {
  // 		console.log(page);
  // 		getUsersAsBrands();
  // 	}, 1000),
  // 	[]
  // );
  const getUsersAsBrands = async () => {
    const response = await getUsersAsBrandsAPI({
      groupId: selectedGroupId,
      pageSize: 16,
      page: page,
    });
    setBrands(response?.data ? response?.data : []);
    console.log(response, "bharat");
    setTotalSize(response?.totalSize);
  };
  const callSearchByUserName = async (query) => {
    try {
      setLoading(true);
      const response = await searchByUserNameAPI(query);
      // console.log(response);
      if (response.status === "success") {
        setBrands(response.data);
      }
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setPage(1);
    setQuery(e.target.value);
  };
  const handlePageChange = (newPage) => {
    console.log(Math.ceil(totalSize / 16));
    if (newPage >= 1 && newPage <= Math.ceil(totalSize / 16)) {
      setPage(newPage);
    }
  };
  useEffect(() => {
    query && debouncedSearch(query);
    if (!query) {
      getUsersAsBrands();
    }
  }, [query, page]);
  console.log(selectedBrandId, "ppppp");

  return (
    <div className="bg-white h-full flex flex-col gap-4 py-[15px] px-[22px] rounded-lg">
      <div className="flex flex-col gap-4">
        <h4 className="text-[16px] font-semibold text-ellipsis">Brands List</h4>
        <TextField
          type="text"
          value={query}
          onChange={handleInputChange}
          placeholder="Search Brands by Name"
          className="w-full"
          sx={{
            "& .MuiFilledInput-root": {
              color: "#000",
              fontFamily: "Arial",
              fontWeight: "bold",
              backgroundColor: "#f4f4f4",
              borderTopLeftRadius: "7px",
              borderTopRightRadius: "7px",
            },
            "& .MuiInputLabel-filled": {
              color: "#2e2e2e",
              fontWeight: "bold",
            },
          }}
        />
      </div>
      {loading ? (
        <div className="w-full  flex justify-center items-center h-full">
          <DotLoading />
        </div>
      ) : brands?.length !== 0 ? (
        <div className="flex flex-col justify-between gap-2 max-h-[50vh] overflow-y-scroll">
          <div className="grid md:grid-cols-3 lg:grid-cols-4 grid-cols-1 gap-2 ">
            {brands.map((brand, idx) => {
              return (
                <UserBrand
                  selectedBrandId={selectedBrandId}
                  setSelectedBrandId={setSelectedBrandId}
                  key={idx}
                  {...brand}
                />
              );
            })}
          </div>
          {!query && (
            <Pagination
              currentPage={page}
              onPageChange={handlePageChange}
              totalPages={Math.ceil(totalSize / 16) || 1}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center w-full h-full max-h-[50vh]">
          No Brands
        </div>
      )}
    </div>
  );
};

export default DraggableBrandContainer;
