import axios from "axios";
import { toast } from "react-toastify";

const adminEmail = "adsadmin@estulife.com";

// comms startpoint
let commsUrl = "https://comms.estulife.com"; //prod
if (
	window.location.origin.includes("localhost") ||
	window.location.origin.includes("dev") ||
	window.location.origin.includes("staging")
) {
	commsUrl = "https://dev-comms.estulife.com";
}

// if (window.location.origin.includes("dev")) {
//   //dev url
//   commsUrl = "https://dev-comms.estulife.com";
// }

// if (window.location.origin.includes("staging")) {
//   commsUrl = "https://dev-comms.estulife.com";
// }

export const COMMS_API = axios.create({
	baseURL: commsUrl,
});

//comms endpoint

export const API = axios.create({
	baseURL: process.env.REACT_APP_BASEURL,
});

export const addInterceptors = () => {
	API.interceptors.request.use((req) => {
		if (localStorage.getItem("token")) {
			req.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
		}

		return req;
	});
};

// Auth endpoints
const login = (loginUser) => API.post("/api/v1/admin/login", loginUser);
const authenticate = () => API.get("/api/v1/admin/authenticate");

// Organization endpoints
const createOrg = (orgData) =>
	API.post("/api/v1/mainadmin/createOrganization", orgData);
const getAllOrgs = () => API.get(`/api/v1/mainadmin/getAllOrganization`);

const getAllRferrals = () => API.get(`/api/v1/mainadmin/refer-institute`);

const GetAllAllowedUsers = async () => {
	try {
		const response = await API.get("/api/v1/admin/get-allowed-users");
		return response.data.data.allowedUsers;
	} catch (error) {
		throw error;
	}
};

const GetAdsManagerUsers = async () => {
	try {
		const response = await API.get(`/api/v1/admin/users`);

		// Filter out the user with email adminEmail
		const filteredData = response?.data?.data?.filter(
			(user) => user.email !== adminEmail // hide from all over the project
		);

		// Return the filtered data
		return { data: filteredData, ...response?.data };
		// return response.data.data;
	} catch (error) {
		throw error;
	}
};

const GetAdsManagerUsersById = async (id) => {
	try {
		const response = await API.get(`/api/v1/admin/users/${id}`);
		return response.data.data;
	} catch (error) {
		throw error;
	}
};

const GetUserEventsAndDiscounts = async (type, id) => {
	// NOTE 0 discounts, 1 events, 2 amazon affiliate
	try {
		const url = `/api/v1/admin/${
			type === 0 ? "discounts" : type === 1 ? "events" : "discounts"
		}/${id}${type === 2 ? "?affiliate=true" : ""}`;
		const response = await API.get(url);
		return response.data.data;
	} catch (error) {
		throw error;
	}
};

const GetUserEventsAndDiscountsAPICALL = async (searchTerm, currentPage) => {
	try {
		const response = await API.get(`/api/v1/affiliate/offers`, {
			params: {
				page: currentPage,
				brand: searchTerm ? "" : "Optimum", // TODO NOTE: this brand key should be change with user institute name
				keyword: searchTerm ? searchTerm : "",
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const AddOfferAmazonAffiliate = async (data) => {
	try {
		const response = await API.post(`/api/v1/affiliate/add-offers`, data);
		return response.data;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		throw error;
	}
};

const SaveAdsManagerUsers = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/users`, data);
		return response.data;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		throw error;
	}
};

const GetAdCampaignById = async (campaignId) => {
	try {
		const response = await API.get(`/api/v1/admin/ad/${campaignId}`);
		const data = response.data.data;
		const metaData = JSON.parse(data.metadata.toString());
		return { data, metaData };
	} catch (error) {
		throw error;
	}
};

const GetReferalData = async () => {
	try {
		const response = await axios.get(
			"https://hmbxauzp5m.execute-api.us-east-1.amazonaws.com/dev/getAllReferral"
		);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};
const getUserWaitList = async () => {
	try {
		const response = await API.get("/api/v1/admin/waitlists");
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const GetAdsManagerData = async () => {
	try {
		const response = await API.get("/api/v1/admin/get-ads-moderator");
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const GetUserProfileDetails = async (profileId) => {
	try {
		const response = await API.get(`/api/v1/admin/users/profile/${profileId}/`);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};
const UpdateAdStatus = async (data1, profileId) => {
	try {
		const response = await API.post(`/api/v1/admin/update-ad-status/`, data1);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const GetUserProfile = async (userId) => {
	try {
		const response = await API.get(`/api/v1/admin/users/profile/${userId}/`);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const GetMessages = async (userId) => {
	try {
		const response = await API.get(
			`/api/v1/admin/chat/messages?user_id=${userId}&moderator_id=1`
		);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const Getthreads = async () => {
	try {
		const response = await API.get(`/api/v1/admin/chat/threads?moderator_id=1`);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const GetAllUsersProfile = async () => {
	try {
		const response = await API.get(`/api/v1/admin/users/profile`);
		const data = response.data.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const GetUserAds = async (userId) => {
	try {
		const response = await API.get(`/api/v1/admin/users/${userId}/ad`);
		const { data, user } = response.data;
		return { data, user };
	} catch (error) {
		throw error;
	}
};

const ToogleInstitute = async (formData) => {
	try {
		const response = await API.post(`/api/v1/admin/toggle-college`, formData);
		const { data } = response.data;
		return { ...data, message: "Field Updated" };
	} catch (error) {
		throw error;
	}
};

const GetInstituteList = async () => {
	try {
		const response = await API.post(`/api/v1/admin/fetch-college`, {
			isAdmin: true,
		});
		const { colleges } = response.data.data;
		return colleges;
	} catch (error) {
		throw error;
	}
};
const ToogleCountryStatus = async (formData) => {
	try {
		const response = await API.post(`/api/v1/admin/toggle-countries`, formData);
		const { data } = response.data;
		return data;
	} catch (error) {
		throw error;
	}
};
const GetCountryList = async () => {
	try {
		const response = await API.get(`/api/v1/admin/countries`, {
			isAdmin: true,
		});
		const { countries } = response.data.data;
		return countries;
	} catch (error) {
		throw error;
	}
};

const Getkyclogs = async () => {
	try {
		const response = await API.get(`/api/v1/admin/kyc-logs`);
		const { data } = response.data;
		return data;
	} catch (error) {
		throw error;
	}
};

const SaveTicket = async (formData) => {
	try {
		const response = await API.post(`/api/v1/admin/events`, formData);
		const { data } = response.data;
		return data;
	} catch (error) {
		toast.error(
			error?.response?.data?.error ? error?.response?.data?.error : "Failed"
		);
		throw error;
	}
};

const SaveDiscount = async (formData) => {
	try {
		const response = await API.post(`/api/v1/admin/discounts`, formData);
		const { data } = response.data;
		return data;
	} catch (error) {
		throw error;
	}
};

export const UpdateDiscount = async (formData, operation = "update") => {
	const URL = `/api/v1/admin/discounts/${formData.id}/`;
	if (operation === "update") {
		try {
			const response = await API.put(URL, formData);

			toast.success(
				response?.data?.message ? response?.data?.message : "success"
			);
			const { data } = response.data;
			return data;
		} catch (error) {
			throw error;
		}
	} else {
		try {
			delete formData.id;
			const response = await API.delete(URL);
			const { data } = response.data;
			return data;
		} catch (error) {
			throw error;
		}
	}
};

export const UpdateEvent = async (formData, operation = "update") => {
	const URL = `/api/v1/admin/events/${formData.id}/`;
	if (operation === "delete") {
		try {
			const response = await API.delete(URL);
			const { data } = response.data;
			return data;
		} catch (error) {
			throw error;
		}
	} else {
		try {
			delete formData.id;
			const response = await API.put(URL, formData);
			const { data } = response.data;
			return data;
		} catch (error) {
			throw error;
		}
	}
};

const CreateAllowedUser = async (formData) => {
	try {
		const response = await API.post(`/api/v1/admin/allow-user`, formData);
		const { data } = response.data;
		return data;
	} catch (error) {
		throw error;
	}
};

export const getSearchPlacesByQuery = async (query) => {
	try {
		const response = await API.post(`/api/v1/address/getByKeyword`, {
			keyWord: query,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const getLatLongByPlaceId = async (placeId) => {
	try {
		const response = await API.post(`/api/v1/address/getByID`, {
			place_id: placeId,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const getNonSSNDetails = async (params) => {
	try {
		const response = await API.get(`api/v1/admin/allow-ssn-users`, {
			params: params,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error;
	}
};

export const getBankDetails = async () => {
	try {
		const response = await API.get(`api/v1/admin/get-bank-details`);
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const updateBankDetails = async (data) => {
	try {
		const response = await API.post(`api/v1/admin/update-bank-details`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const updateAdmasterUser = async (data, userId) => {
	const id = data.id;
	try {
		const response = await API.put(`api/v1/admin/users/${userId}`, {
			...data,
		});
		console.log(response.data, "ssss");
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
		toast.error(
			error?.response?.data?.error ? error?.response?.data?.error : "Failed"
		);
		throw error;
	}
};

export const DeleteAdmasterUser = async (data) => {
	try {
		const response = await API.delete(`api/v1/admin/users`, {
			data: { ...data },
		});
		return response?.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const getSectors = async (data) => {
	try {
		const response = await API.get(`api/v1/admin/sector`);
		return response.data.body;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const UpdateSector = async (data) => {
	try {
		const response = await API.put(`api/v1/admin/sector/${data.id}`, {
			name: data.name,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const DeleteSector = async (data) => {
	// sectorId
	try {
		const response = await API.delete(`api/v1/admin/sector`, {
			data: { ...data },
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const CreateSector = async (data) => {
	// name
	try {
		const response = await API.post(`api/v1/admin/sector`, data);
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

export const UpdateUserStatusAdmaster = async (
	status,
	email,
	id,
	reason = ""
) => {
	try {
		const res = await API.patch(`/api/v1/admin/updateStatus/${id}`, {
			status: status,
			email: email,
			reason: reason,
		});
		return res.data;
	} catch (error) {
		console.log("error updating", error);
	}
};

export const UpdateCouponStatus = async (id, status, reason = "") => {
	try {
		const res = await API.patch(`/api/v1/admin/updatediscounts/${id}`, {
			status: status,
			reason: reason,
		});
		return res.data;
	} catch (error) {
		console.log("error updating", error);
	}
};

export const UpdateEventStatus = async (id, status, reason) => {
	try {
		const res = await API.patch(`/api/v1/admin/updateEvents/${id}`, {
			status: status,
			reason: reason,
		});
		return res.data;
	} catch (error) {
		console.log("error updating", error);
	}
};
export const UpdateBoostedSponsorRejectAPI = async (id, status, reason) => {
	try {
		const res = await API.patch(`/api/v1/admin/reject-sponsor-request/${id}`, {
			status: status,
			rejectionReason: reason,
		});
		return res.data;
	} catch (error) {
		console.log("error updating", error);
	}
};
export const UpdateBoostedSponsorApproveAPI = async (id, status, reason) => {
	try {
		const res = await API.patch(`/api/v1/admin/approve-sponsor-request/${id}`);
		return res.data;
	} catch (error) {
		console.log("error updating", error);
	}
};

const getTopBrands = async (data) => {
	try {
		const response = await API.get(`api/v1/admin/top-brands`);
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const getGroups = async () => {
	try {
		const response = await API.get(`api/v1/admin/groups`);
		return response.data.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const searchUserNameApi = async (userName) => {
	try {
		const response = await API.get(`api/v1/admin/search-user-name`, {
			params: { userName: userName },
		});
		return response.data.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const groupRankChangeApi = async (data) => {
	try {
		const response = await API.put(`api/v1/admin/group-rank/${data.id}`, {
			...data,
		});
		toast.success(response?.data?.message);
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const updateGroupRankNameApi = async (data) => {
	try {
		const response = await API.put(`/api/v1/admin/group-name/${data.id}`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const deleteGroupApi = async (data) => {
	try {
		const response = await API.delete(`/api/v1/admin/groups/${data.id}`);
		return response;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const addGroupApi = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/group`, {
			...data,
		});
		return response.data.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const groupToggleAPI = async (id) => {
	try {
		const response = await API.put(`/api/v1/admin/toggle-group/${id}`);
		return response;
	} catch (error) {
		console.log("Error message:", error);
	}
};
const getGroupCouponsAPI = async ({ groupId, pageSize = 20, page = 1 }) => {
	try {
		const response = await API.get(`/api/v1/admin/group-discounts/${groupId}`, {
			params: { pageSize, page },
		});
		return response.data;
	} catch (error) {
		console.log("Error Message:", error);
		toast.error("Error while getting data");
	}
};
const deleteGroupCouponsAPI = async (data) => {
	try {
		const response = await API.put(`/api/v1/admin/remove-coupon`, { ...data });
		return response.data;
	} catch (error) {
		console.log("Error Message:", error);
		toast.error("Error while getting data");
	}
};
const updateGroupCouponsOrderAPI = async (data) => {
	try {
		const response = await API.put(`/api/v1/admin/group-coupon-rank`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.log("Error Message:", error);
		toast.error("Error while updating order!");
	}
};

const addTopBrandApi = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/top-brands`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
		// console.log("bharat error", error);
		toast.error(
			error?.response?.data?.error ? error?.response?.data?.error : "Failed"
		);
	}
};
const updateTopBrandRankApi = async (data) => {
	try {
		const response = await API.put(`/api/v1/admin/top-brands/${data.id}`, {
			rank: data.rank,
		});
		return response;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const deleteTopBrandApi = async (data) => {
	try {
		const response = await API.delete(`/api/v1/admin/top-brands/${data}`);
		return response;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const searchUserByName = async (name) => {
	try {
		const response = await API.get(`/api/v1/admin/search-user-name`, {
			params: {
				userName: name,
			},
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getAdmasterUsersAsBrands = async (name) => {
	try {
		const response = await API.get(`/api/v1/admin/list-users`);
		// Keep the rest of the response intact, but filter the data array
		const filteredData = response.data.data.filter(
			(user) => user.instituteName !== "Estu Team" //TODO: this will remove because its not a good approach for now i have choice only on institute name
		);

		// Return the response with the filtered data array, maintaining the same structure
		return {
			...response.data,
			data: filteredData,
		};
		// return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const getUsersAsBrandsAPI = async ({ groupId, page, pageSize }) => {
	try {
		const response = await API.get(`/api/v1/admin/users`, {
			params: { groupId, page, pageSize: pageSize ? pageSize : 10 },
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const addCouponOrBrandAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/add-coupon`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const getCouponsForBrandAPI = async (brandId, groupId) => {
	try {
		const response = await API.get(`/api/v1/admin/discounts/${brandId}`, {
			params: { groupId },
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};
const searchByUserNameAPI = async (query) => {
	try {
		const response = await API.get(`/api/v1/admin/search-user-name`, {
			params: { userName: query },
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
	}
};

const updateRankOfDiscountCoupon = async (data) => {
	try {
		const response = await API.post("/api/v1/admin/rank-discounts", data);
		return response.data;
	} catch (error) {
		throw error;
	}
};
const getCouponsList = async (data) => {
	try {
		const response = await API.get(`/api/v1/admin/discounts`, { params: data });
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error;
	}
};
const getEventsList = async (data) => {
	try {
		const response = await API.get(`/api/v1/admin/events`, data);
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error;
	}
};
const updateCouponGlobalRank = async (data) => {
	try {
		const response = await API.put("/api/v1/admin/discounts/rank-all", {
			...data,
		});
		return response.data;
	} catch (error) {
		console.error("Error fetching data:", error);
		throw error;
	}
};
const getCSVFileViewAPI = async (fileKey) => {
	// const commsURL = "https://comms.estulife.com";
	// console.log(fileKey, "bbbb");
	// return;
	try {
		// const response = await axios.get(`${commsURL}/api/${fileKey}/view`);
		const response = await COMMS_API.get(`/api/view`, {
			params: { key: fileKey },
		});
		return response.data;
	} catch (error) {
		throw error;
	}
};
const getDashboardAnalyticsAPI = async () => {
	try {
		const response = await API.get("/api/v1/admin/get-statistics");
		return response.data;
	} catch (error) {
		throw error;
	}
};

const commsListAPI = async () => {
	try {
		// const response = await axios.get("https://comms.estulife.com/api/list");
		const response = await COMMS_API.get("/api/list");
		return response;
	} catch (error) {
		throw error;
	}
};
const commsStatusBoardAPI = async () => {
	try {
		const response = await COMMS_API.get("/api/stats");
		return response?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
const commsContentBoardAPI = async ({ status }) => {
	const params = status ? { status } : {};

	try {
		const response = await COMMS_API.get("/api/view", {
			params: params,
		});
		return response?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
const uploadCSVAPI = async (formData) => {
	try {
		const response = await COMMS_API.post("/api/upload", formData, {
			// headers: {
			//   "Content-Type": "multipart/form-data",
			// },
		});

		return response;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const startStopCommsMessagesAPI = async () => {
	try {
		const response = await COMMS_API.get("/optOutList");
		return response?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const createAd = async (data) => {
	try {
		const response = await API.post("/api/v1/admin/admin-ads", data);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getAdminAds = async () => {
	try {
		const response = await API.get("/api/v1/admin/admin-ads");
		return response.data;
	} catch (error) {
		throw error;
	}
};

const toggleActiveStatusAdsAPI = async (data, newStatus = false) => {
	try {
		const response = await API.post(`/api/v1/admin/admin-ads/toggleStatus`, {
			id: data.id,
			isActive: newStatus,
		});
		return response;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		throw error;
	}
};

const toggleActiveStatusCampaignAPI = async (data, newStatus = false) => {
	try {
		const response = await API.post(`/api/v1/campaign/toggle-status`, {
			id: data.id,
			isActive: newStatus,
		});
		return response;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		throw error;
	}
};
const toggleEstuFridayAPI = async (id, isEstuFriday) => {
	try {
		const response = await API.patch(`/api/v1/campaign/update`, {
			id,
			isEstuFriday,
		});
		return response;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
		}
		throw error;
	}
};

const getStudentReportsAPI = async (params) => {
	try {
		const response = await API.get("/api/v1/admin/students", {
			params: params,
		});
		console.log(response.data, params);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getCampaignListAPI = async () => {
	try {
		const response = await API.get("/api/v1/campaign/list");
		return response.data;
	} catch (error) {
		throw error;
	}
};
const getCampaignCodeAPI = async () => {
	try {
		const response = await API.get("/api/v1/campaign/codes");
		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
const getMatchingTransactionsAPI = async () => {
	try {
		const response = await API.get("/api/v1/campaign/matching-transactions");
		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
const processTransactionAPI = async ({ id }) => {
	try {
		const response = await API.post(
			`/api/v1/campaign/process-participation/${id}`
		);
		return response?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
const createCampaignCodeAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/campaign/codes`, {
			...data,
		});
		return response?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const updateCampaignCodeAPI = async ({ id, data }) => {
	try {
		const response = await API.patch(`/api/v1/campaign/codes/${id}`, {
			...data,
		});
		return response?.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const createCampaignAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/campaign/create`, {
			...data,
		});
		return response?.data;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		throw error;
	}
};
const deleteCampaignCodeAPI = async ({ id }) => {
	try {
		const response = await API.delete(`/api/v1/campaign/codes/${id}`);
		console.log(response);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const updateCampaignAPI = async (data) => {
	try {
		const response = await API.patch(`/api/v1/campaign/update`, {
			...data,
		});
		return response?.data;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		throw error;
	}
};

const getCampaignInsightAPI = async (id) => {
	try {
		const response = await API.get(`/api/v1/campaign/transactions/${id}`);
		return response.data;
	} catch (error) {
		throw error;
	}
};
const getReferralsListAPI = async (id) => {
	try {
		const response = await API.get(`/api/v1/campaign/referral`);
		return response.data;
	} catch (error) {
		throw error;
	}
};

const getParticipatedActiveUsersAPI = async (campaignId) => {
	if (!campaignId) {
		toast.error("campaign id not provided");
	} else {
		try {
			const response = await API.get(
				`/api/v1/campaign/activated/${campaignId}`
			);
			return response.data;
		} catch (error) {
			if (error?.response?.data?.error) {
				toast.error(error.response?.data?.error);
			} else {
				toast.error("Something went wrong");
				//toaster for rest error
			}
			console.log(error);
			throw error;
		}
	}
};
const createAnInstituteAPI = async (data) => {
	try {
		const response = await API.post("/api/v1/admin/add-college", data);
		return response.data;
	} catch (error) {
		if (error?.response?.data?.error) {
			toast.error(error.response?.data?.error);
		} else {
			toast.error("Something went wrong");
			//toaster for rest error
		}
		console.log(error);
		throw error;
	}
};

const getSponsorsAPI = async (params) => {
	try {
		const response = await API.get(`/api/v1/admin/sponsor-requests`, {
			params: params,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};
const getPricingTableDataAPI = async () => {
	try {
		const response = await API.get(`/api/v1/global/get-pricing-data`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error;
	}
};

const getMediaListAPI = async (params) => {
	try {
		const response = await API.get(`/api/v1/admin/get-media`);

		// const response = await API.post(`/api/v1/admin/create-media`, {
		//   headline: "Test Api 10",
		//   newsLink: "www.google.com",
		//   banner: "99847afd4fa62ec66763a66f80198f6f",
		// });
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const deleteSingleMediaAPI = async ({ id }) => {
	try {
		const response = await API.delete(`/api/v1/admin/delete-media/${id}`);
		console.log(response);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

const addNewMediaAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/create-media`, { ...data });
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const editNewMediaAPI = async (data) => {
	try {
		const { id, ...payload } = data;
		const response = await API.patch(
			`/api/v1/admin/update-media/${data?.id}`,
			payload
		);
		return response.data;
	} catch (error) {
		console.error("Error updating media:", error);
		throw error?.response?.data || { message: "An unknown error occurred" };
	}
};

const getSupportTicketsAPI = async () => {
	try {
		const response = await API.get(`/api/v1/admin/support-tickets`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const getSupportActivityAPI = async ({ id }) => {
	try {
		const response = await API.get(`/api/v1/admin/get-support-activity/${id}`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const savePricingDataAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/global/save-pricing-data`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

const getIssueTypeListAPI = async () => {
	try {
		const response = await API.get(`/api/v1/admin/list-issue-types`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const getStatusListAPI = async () => {
	try {
		const response = await API.get(`/api/v1/admin/list-ticket-status`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const getPriorityListAPI = async () => {
	try {
		const response = await API.get(`/api/v1/admin/list-ticket-priority`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

const updateSupportTIcketTagsAPI = async (ticketId, data) => {
	//   --data '{
	//     "status":2,
	//     "issueType":3
	// }'
	try {
		const res = await API.patch(
			`/api/v1/admin/update-support-ticket/${ticketId}`,
			{
				...data,
			}
		);
		return res.data;
	} catch (error) {
		console.log("error updating", error);
		throw error;
	}
};
const addStatusForTicketAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/create-ticket-status`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};
const addPriorityForTicketAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/create-ticket-priority`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

const addIssueTypeForTicketAPI = async (data) => {
	try {
		const response = await API.post(`/api/v1/admin/create-issue-type`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

const saveCharges = async (data) => {
	try {
		const response = await API.post(`/api/v1/global/save-charges`, {
			...data,
		});
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

const getCharges = async () => {
	try {
		const response = await API.get(`/api/v1/global/get-charges`);
		return response.data;
	} catch (error) {
		console.log(error);
		throw error?.response?.data;
	}
};

export {
	getStudentReportsAPI,
	login,
	authenticate,
	createOrg,
	getAllOrgs,
	getAllRferrals,
	GetAllAllowedUsers,
	SaveAdsManagerUsers,
	GetAdsManagerUsersById,
	GetUserEventsAndDiscounts,
	GetUserEventsAndDiscountsAPICALL,
	GetAdsManagerUsers,
	GetAdCampaignById,
	GetReferalData,
	getUserWaitList,
	GetAdsManagerData,
	GetUserProfileDetails,
	UpdateAdStatus,
	GetUserProfile,
	GetMessages,
	Getthreads,
	GetAllUsersProfile,
	GetUserAds,
	ToogleInstitute,
	GetInstituteList,
	ToogleCountryStatus,
	GetCountryList,
	Getkyclogs,
	SaveTicket,
	SaveDiscount,
	CreateAllowedUser,
	AddOfferAmazonAffiliate,
	getTopBrands,
	getGroups,
	groupRankChangeApi,
	addGroupApi,
	deleteGroupApi,
	updateGroupRankNameApi,
	groupToggleAPI,
	addTopBrandApi,
	updateTopBrandRankApi,
	deleteTopBrandApi,
	searchUserNameApi,
	searchUserByName,
	getAdmasterUsersAsBrands,
	getUsersAsBrandsAPI,
	addCouponOrBrandAPI,
	getCouponsForBrandAPI,
	searchByUserNameAPI,
	getGroupCouponsAPI,
	deleteGroupCouponsAPI,
	updateGroupCouponsOrderAPI,
	updateRankOfDiscountCoupon,
	getCouponsList,
	getEventsList,
	updateCouponGlobalRank,
	getCSVFileViewAPI,
	getDashboardAnalyticsAPI,
	commsListAPI,
	commsStatusBoardAPI,
	commsContentBoardAPI,
	uploadCSVAPI,
	startStopCommsMessagesAPI,
	createAd,
	getAdminAds,
	toggleActiveStatusAdsAPI,
	toggleActiveStatusCampaignAPI,
	getCampaignListAPI,
	createCampaignAPI,
	getCampaignInsightAPI,
	updateCampaignAPI,
	getReferralsListAPI,
	getParticipatedActiveUsersAPI,
	createAnInstituteAPI,
	getSponsorsAPI,
	toggleEstuFridayAPI,
	getMediaListAPI,
	deleteSingleMediaAPI,
	addNewMediaAPI,
	editNewMediaAPI,
	getCampaignCodeAPI,
	createCampaignCodeAPI,
	updateCampaignCodeAPI,
	deleteCampaignCodeAPI,
	getSupportTicketsAPI,
	getSupportActivityAPI,
	getPricingTableDataAPI,
	savePricingDataAPI,
	getMatchingTransactionsAPI,
	processTransactionAPI,
	getIssueTypeListAPI,
	getStatusListAPI,
	getPriorityListAPI,
	updateSupportTIcketTagsAPI,
	addStatusForTicketAPI,
	addPriorityForTicketAPI,
	addIssueTypeForTicketAPI,
	saveCharges,
	getCharges,
};
