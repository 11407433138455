const Tabs = ({ handleTabChange, activeTab, label, index }) => {
	return (
		<div
			onClick={(_) => handleTabChange(index)}
			className={`cursor-pointer w-[148px] h-[43px] py-3 px-[19px] flex flex-row justify-center items-center gap-1 text-[#67748e] font-montserrat text-xs font-semibold leading-4 [letter-spacing:_0.34px] text-center transition-[0.2s_all_ease-in-out] whitespace-nowrap ${
				activeTab === index &&
				"font-montserrat border-b border-b-tab text-xs transition-[0.2_all_ease-in-out] font-bold leading-4 [letter-spacing:_0.34px;] text-center"
			}`}
		>
			{label}
		</div>
	);
};

export default Tabs;
