import React from "react";
import { FaSpinner } from "react-icons/fa";

const DotLoading = ({ color = "#2cccd3", size = "4xl" }) => {
	return (
		<div className='flex justify-center items-center'>
			<FaSpinner className={`animate-spin text-${color} text-${size}`} />
			&nbsp;Loading...
		</div>
	);
};

export default DotLoading;
