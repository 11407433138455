import React from "react";

const ImageGridPreview = ({ images, handleClose }) => {
  const hasImages = images && images.length > 0;

  return (
    <div
      onClick={handleClose}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="relative bg-white rounded-lg p-4 max-w-[80vw] max-h-[80vh] overflow-auto"
      >
        <button
          onClick={handleClose}
          className="absolute top-2 right-2 text-gray-500 bg-white bg-opacity-80 rounded-full py-1 px-3 hover:text-gray-800"
        >
          ✕
        </button>

        {hasImages ? (
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {images.map((image, index) => (
              <a
                key={index}
                href={`${process.env.REACT_APP_IMAGE_BASEURL}${image}`}
                target="_blank"
                rel="noopener noreferrer"
                className="w-full h-auto"
              >
                <img
                  src={`${process.env.REACT_APP_IMAGE_BASEURL}${image}`}
                  alt={`Attachment ${index + 1}`}
                  className="w-full h-auto rounded-md hover:opacity-90"
                />
              </a>
            ))}
          </div>
        ) : (
          <div className="text-center text-gray-600 min-h-4 min-w-5 p-4">
            <p>No images to display.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageGridPreview;
