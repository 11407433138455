import React, { useCallback, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const InputComponent = ({
	label,
	type,
	name,
	id,
	placeholder,
	required,
	onChange,
	min,
	max,
	widthFull = false,
	...props
}) => {
	const [showPassword, setShowPassword] = useState(false);
	const today = new Date()?.toISOString()?.split("T")[0];
	//default max date
	const defaultMaxDate = "9999-12-31";

	const togglePasswordVisibility = useCallback(() => {
		setShowPassword((prevState) => !prevState);
	}, []);

	if (type === "password") {
		// Password field with visibility toggle
		return (
			<div className='flex flex-col w-full relative'>
				<label htmlFor={id} className='text-lg'>
					{label}
				</label>
				<input
					type={showPassword ? "text" : "password"}
					name={name}
					id={id}
					onChange={onChange}
					required={required}
					placeholder={placeholder}
					className='p-3 border disabled:cursor-not-allowed disabled:bg-slate-200 border-black focus:ring-2 focus:ring-[#2cccd3] focus:outline-none rounded-md outline-none w-full'
					{...props}
				/>
				{/* Add an eye icon for password fields */}
				<span
					onClick={togglePasswordVisibility}
					className='absolute right-3 top-1/2 transform  cursor-pointer text-gray-600'
				>
					{showPassword ? <VisibilityOff /> : <Visibility />}
				</span>
			</div>
		);
	}

	return (
		<div className={`flex flex-col gap-1 ${widthFull ? "w-full" : "w-100"}`}>
			<label htmlFor={id} {...props} className='text-lg'>
				{label}
			</label>
			<input
				type={type}
				name={name}
				onChange={onChange}
				{...props}
				id={id}
				required={required}
				className={`p-3 border disabled:cursor-not-allowed disabled:bg-slate-200 border-black focus:ring-2 focus:ring-[#2cccd3] focus:outline-none rounded-md outline-none w-100 ${
					type === "email" ? "lowercase" : ""
				}`}
				min={type === "date" && !min ? today : min} // min date to today if none provided
				max={type === "date" && !max ? defaultMaxDate : max}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default InputComponent;
