let dummyData = [
  {
    id: "#estu-events-001",
    updatedAt: "24/12/24 -12:34PM",
    subject: "Problem in events",
    description: "Worem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Events",
    issueType: "Bug",
    image: null,
    status: "new",
    priority: "Urgent",
  },
  {
    id: "#estu-events-002",
    updatedAt: "24/12/24 -12:34PM",
    subject: "Problem in events",
    description: "Worem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Events",
    issueType: "Performance",
    image: "https://example.com/image1.jpg",
    status: "open",
    priority: "High",
  },
  {
    id: "#estu-events-003",
    updatedAt: "24/12/24 -12:34PM",
    subject: "Problem in events",
    description: "Worem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Events",
    issueType: "Bug",
    image: null,
    status: "Pending",
    priority: "Normal",
  },
  {
    id: "#estu-events-004",
    updatedAt: "24/12/24 -12:34PM",
    subject: "Problem in events",
    description: "Worem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Events",
    issueType: "Bug",
    image: null,
    status: "On hold",
    priority: "Urgent",
  },
  {
    id: "#estu-events-005",
    updatedAt: "24/12/24 -12:34PM",
    subject: "Problem in events",
    description: "Worem ipsum dolor sit amet, consectetur adipiscing elit.",
    category: "Events",
    issueType: "Bug",
    image: null,
    status: "Solved",
    priority: "Low",
  },
];
const priorityStyles = {
  urgent: { bgColor: "#FFD8D9", color: "#D23333" },
  high: { bgColor: "#FFD7D7", color: "#E14747" },
  normal: { bgColor: "#D7F4FF", color: "#1573C3" },
  low: { bgColor: "#D6D6D6", color: "#727272" },
};
// const status = ["new", "open", "pending", "on hold", "solved"];

// const priority = ["urgent", "high", "normal", "low"];
const statusStyles = {
  new: { bgColor: "#E8F4FF", color: "#1473E6" },
  open: { bgColor: "#FFF5D8", color: "#D69312" },
  pending: { bgColor: "#FFEDD8", color: "#C37523" },
  "on hold": { bgColor: "#FFD8E3", color: "#D21C64" },
  solved: { bgColor: "#D8FFD9", color: "#27A22C" },
};
const activity = [
  {
    message: "Issue type changed to",
    key: "BUg",
    date: "Dec 6, 2024, 10:52 PM",
  },
  {
    message: "Priority changed to",
    key: "Urgent",
    date: "Dec 6, 2024, 10:52 PM",
  },
  {
    message: "Status changed to",
    key: "On hold",
    date: "Dec 6, 2024, 10:52 PM",
  },
];

const allStatus = [];
const allIssueTypes = [];

export {
  statusStyles,
  priorityStyles,
  dummyData,
  activity,
  allStatus,
  allIssueTypes,
};
