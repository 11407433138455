const tabs = [
  {
    text: "Campaign",
    value: "campaign",
  },
  {
    text: "Codes",
    value: "codes",
  },
  {
    text: "Transactions",
    value: "transactions",
  },
];

export { tabs };
