import React, { useEffect, useState } from "react";
import CommentContainer from "../Comment/CommentContainer";
import AddInputContainer from "../AddInputContainer/AddInputContainer";
import RadioButtonContainer from "../RadioButtonContainer/RadioButtonContainer";
import {
  addStatusForTicketAPI,
  getStatusListAPI,
  updateSupportTIcketTagsAPI,
} from "../../../../api/api";
import { toast } from "react-toastify";
import ColorTagCreator from "../../../../components/ui/ColorTagCreator/ColorTagCreator";

const Status = ({ ticket, onChange = () => {} }) => {
  console.log(ticket);

  const [statusErrorMessage, setStatusErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedStatusId, setSelectedStatusId] = useState(
    ticket?.data?.status?.id
  );
  const handleAddStatus = async (data) => {
    if (!data?.color) {
      toast.error("Please select a color.");
      return;
    } else if (!data?.name) {
      toast.error("Please enter a status name.");
      return;
    }

    try {
      const response = await addStatusForTicketAPI(data);
      console.log(response);
      if (response?.status === "success") {
        toast.success(response?.data?.message || "Status added successfully");
        // getAPI();
        setData((prev) => [...prev, response?.data]);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };
  const handleInputChange = (value) => {
    setStatusErrorMessage("");
  };
  const getAPI = async () => {
    setLoading(true);
    try {
      const response = await getStatusListAPI();
      const fetchedData = response.data || [];
      setData(fetchedData);
    } catch (error) {
      console.error(error);
      setStatusErrorMessage("Failed to fetch issue types.");
    } finally {
      setLoading(false);
    }
  };

  const handleSelectStatus = async (statusId) => {
    if (statusId === selectedStatusId) {
      return toast.info("please select another status");
    }
    try {
      const response = await updateSupportTIcketTagsAPI(ticket?.data?.id, {
        status: statusId,
      });
      if (onChange) onChange();
      setSelectedStatusId(statusId);
      // console.log(response);
    } catch (error) {
      console.log(error);
      toast.error("Failed to update status");
    }
  };
  useEffect(() => {
    getAPI();
  }, []);
  return (
    <div className="flex flex-col min-h-[400px] md:min-w-[400px] min-w-[80vw] max-h-[500px] md:gap-4 gap-4">
      <RadioButtonContainer
        data={data}
        name="Status"
        loading={loading}
        selectedData={selectedStatusId}
        onSelect={handleSelectStatus}
      />
      <hr />
      <ColorTagCreator
        heading="Add custom status"
        headingCss="font-normal font-montserrat text-sm font-bold text-[#2C3030]"
        buttonText="Add Status"
        iconVisible={true}
        buttonVisible={true}
        onSubmit={handleAddStatus}
        onChange={handleInputChange}
        placeholder="Status title"
        placeholderCss={
          "placeholder:font-montserrat placeholder:text-xs placeholder:font-normal placeholder:text-[#8F8F8F]"
        }
        errorMessage={statusErrorMessage}
      />
      <hr />
      <CommentContainer />
    </div>
  );
};

export default Status;
