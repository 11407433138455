import CottageIcon from "@mui/icons-material/Cottage";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import {
  AdUnits as AdUnitsIcon,
  Group as GroupIcon,
  School as SchoolIcon,
  Public as PublicIcon,
  LocalOffer as LocalOfferIcon,
  LocalMall as LocalMallIcon,
  ErrorOutline as ErrorOutlineIcon,
  VerifiedUser as VerifiedUserIcon,
  AccountBalance as AccountBalanceIcon,
  PersonOff as PersonOffIcon,
  Email as EmailIcon,
  ReportGmailerrorredTwoTone,
  ScaleOutlined,
  ReportOffRounded,
  SupportAgent,
} from "@mui/icons-material";
import SupportTicketManagement from "../../pages/supportTicketManagement/SupportTicketManagement";
const menuItems = [
  {
    url: "/dashboard",
    name: "Dashboard",
    icon: <CottageIcon />,
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    url: "/customer-reports",
    name: "Customer Reports",
    icon: <ReportGmailerrorredTwoTone />,
    department: ["ADMIN", "SUPERADMIN", "VISITOR"],
  },

  {
    icon: <AdUnitsIcon />,
    url: "/ads",
    name: "Ads Manager",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    url: "/estu-ads",
    icon: <GroupIcon />,
    name: "Partners",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <LocalOfferIcon />,
    url: "/coupons-management",
    name: "Coupons & Events",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <LocalMallIcon />,
    url: "/deals",
    name: "Deals",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <NewspaperIcon />,
    url: "/media",
    name: "Media",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <ReportOffRounded />,
    url: "/referrals",
    name: "Referrals",
    department: ["ADMIN", "SUPERADMIN"],
  },

  {
    icon: <ErrorOutlineIcon />,
    url: "/kycerrors",
    name: "KYC Errors",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <ScaleOutlined />,
    url: "/campaigns-management",
    name: "Campaigns",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <PersonOffIcon />,
    url: "/international-student",
    name: "International Student",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <VerifiedUserIcon />,
    url: "/allowedUsers",
    name: "Allowed Users",
    department: ["ADMIN", "SUPERADMIN"],
  },
  // {
  //   icon: <AccountBalanceIcon />,
  //   url: "/mbanq",
  //   name: "MBanq",
  //   department: ["ADMIN","SUPERADMIN"],
  // },
  {
    icon: <SupportAgent />,
    url: "/support",
    name: "Support Tickets",
    department: ["ADMIN", "SUPERADMIN"],
  },

  {
    // icon: <AdsIcon />,
    icon: <EmailIcon />,
    url: "/comms",
    name: "COMMS",
    department: ["ADMIN", "SUPERADMIN", "MARKETING"],
  },
  // {
  //   url: "/waitlist",
  //   name: "Wait List",
  //   icon: <ListAltIcon />,
  //   department: ["ADMIN","SUPERADMIN"],
  // },
  {
    icon: <SchoolIcon />,
    url: "/instituteList",
    name: "Institute List",
    department: ["ADMIN", "SUPERADMIN"],
  },
  {
    icon: <PublicIcon />,
    url: "/countryList",
    name: "Country List",
    department: ["ADMIN", "SUPERADMIN"],
  },
];

export { menuItems };
