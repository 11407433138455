import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import Box from "@mui/material/Box";
import { SelectIcon } from "../../assets/svgs/selectIcon";
import { DataGrid } from "@mui/x-data-grid";
import Loader from "../../components/atoms/Loader";
import { GetCountryList, ToogleCountryStatus } from "../../api/api";
import dayjs from "dayjs";
import CDataGrid from "../../components/ui/CDataGrid/CDataGrid";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";

const CountryList = () => {
  const [data, setData] = useState();
  const [selectedData, setSelectedData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [selectAll, setSelectAll] = useState(true);
  const [page, setPage] = useState(0);

  const updateCountryStatus = async (updatedRow) => {
    try {
      const res = await ToogleCountryStatus({
        ...updatedRow,
      });
      const updateRows = data.map((item) =>
        item.id == updatedRow.id
          ? { ...updatedRow, status: updatedRow.status }
          : { ...item }
      );
      setData(updateRows);
    } catch (err) {
      console.log("err", err);
      setData(data);
    }
  };

  const setStatus = async (row, id) => {
    try {
      const updatedData = data.map((item) =>
        item.id === id
          ? { ...row, status: row.status === "A" ? "R" : "A" }
          : item
      );

      setData(updatedData);
      await updateCountryStatus({
        ...row,
        status: row.status === "A" ? "R" : "A",
      });
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Country Name",
      width: 140,
      editable: true,
      resizable: true,
    },
    {
      field: "code",
      headerName: "Country Code",
      width: 80,
      editable: true,
      resizable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At (EST)",
      width: 150,

      renderCell: (params) => (
        <p className="" title="yyyy-mm-dd:hh:mm:ss time format EST">
          {dayjs(params?.row?.createdAt)
            ?.tz("America/New_York")
            .format("YYYY-MM-DD:HH:mm:ss")}
        </p>
      ),
    },
    {
      field: "action",
      headerName: "Action",
      width: 80,
      resizable: true,
      renderCell: (param) => {
        const data = param.row;

        const row = {
          ...data,
        };

        return (
          <div className={`flex flex-row gap-2`}>
            <Switch
              onChange={(_) => setStatus(row, data.id)}
              checked={data.status === "A" ? true : false}
            />
          </div>
        );
      },
    },
  ];

  const getAPI = async () => {
    setLoading(true);
    try {
      const res = await GetCountryList();
      console.log(res);
      setData(res);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const handleSelectionChange = (selection) => {
    if (!selectAll) {
      setSelectedData(selection.selectionModel);
    } else {
      setSelectedData([]);
    }
  };

  const handleButtonClick = () => {
    if (selectAll) {
      setSelectAll(false);
      const newSelectionModel = data.map((row) => row.id);
      setSelectedData(newSelectionModel);
    } else {
      setSelectedData([]);
      setSelectAll(true);
    }
  };
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <WrapperOutlet
      headingText={"International Permitted Country List"}
      headingChildren={
        <div className={`flex flex-row align-center gap-2 self-start`}>
          <button
            onClick={handleButtonClick}
            className={`${style.selectRows} ${
              !selectAll ? style.rowsActive : ""
            }`}
          >
            <SelectIcon />
            Select
          </button>
        </div>
      }
    >
      <div className={`flex flex-column gap-2 align-center h-full`}>
        <Box className="bg-white rounded-lg shadow-md p-4 flex-1">
          {loading ? (
            <Loader />
          ) : (
            <CDataGrid
              rows={data}
              columns={columns}
              checkboxSelection={!selectAll}
              onSelectionModelChange={handleSelectionChange}
              selectionModel={selectedData}
              pagination
              page={page}
              onPageChange={handlePageChange}
              loading={loading}
              onCellFocusOut={() => console.log("akljsldkf")}
              onCellEditCommit={(params) => {
                console.log("params", params, typeof params.value);
                let updatedRow = data.map((item) =>
                  item.id === params.id ? item : null
                );

                const updatedRows = data.map((item) => {
                  if (item.id === params.id) {
                    return {
                      ...updatedRow,
                    };
                  }
                  return item;
                });

                updateCountryStatus(updatedRow);
                setData(updatedRows);
              }}
            />
          )}
        </Box>
      </div>
    </WrapperOutlet>
  );
};

export default CountryList;
