import React, { useEffect, useState } from "react";
import { getDashboardAnalyticsAPI } from "../../api/api";
import DashboardSection from "../../components/dashboard/DashboardSection";
import ChartComponent from "../../shared/Charts/Chart";
import { calculatePercentages } from "../../utils/utils";
import PageHeading from "../../components/ui/Heading/PageHeading/PageHeading";
import WrapperOutlet from "../../components/ui/Outlets/WrapperOutlet";

const DashboardPage = () => {
  const [data, setData] = useState({
    partners: [
      { name: "approved", desc: "Approved", total: 0 },
      { name: "pending", desc: "pending", total: 0 },
      { name: "rejected", desc: "rejected", total: 0 },
    ],

    events: [
      { name: "totalEvents", desc: "Total", total: 0 },
      { name: "pendingEvents", desc: "pending", total: 0 },
      { name: "expiredEvents", desc: "rejected", total: 0 },
    ],
    coupons: [
      { name: "totalCoupons", desc: "Total Events", total: 0 },
      { name: "pendingCoupons", desc: "pending Events", total: 0 },
      { name: "expiredCoupons", desc: "rejected Events", total: 0 },
    ],
    estu_app: [
      { name: "freemium_customers", desc: "Freemium Customers", total: 0 },
      { name: "banking_customers", desc: "Banking Customers", total: 0 },
      { name: "non_kyc_customers", desc: "Non KYC Customers", total: 0 },
      {
        name: "kyc_freemium_customers",
        desc: "KYC Freemium Customers",
        total: 0,
      },
      { name: "totalInstitutions", desc: "TOTAL INSTITUTIONS", total: 0 },
    ],
    i20_students: [
      { name: "approved", desc: "Approved", total: 0 },
      { name: "pending", desc: "pending", total: 0 },
      { name: "rejected", desc: "rejected", total: 0 },
    ],
  });

  const getAPI = async () => {
    try {
      const response = await getDashboardAnalyticsAPI();
      setData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAPI();
  }, []);

  const estuAppData = data.estu_app.filter(
    (item) =>
      item?.name === "freemium_customers" ||
      item?.name === "banking_customers" ||
      item?.name === "kyc_freemium_customers" ||
      item?.name === "non_kyc_customers"
  );
  const totalCustomers =
    data?.estu_app?.find((item) => item?.name === "total_customers")?.total ||
    0;

  const estuAppWithPercentages = calculatePercentages(
    estuAppData,
    totalCustomers
  );

  const chartData = {
    partners: {
      labels: data?.partners?.map((item) => item?.desc),
      datasets: [
        {
          label: "Partners",
          data: data?.partners?.map((item) => item?.total),
          backgroundColor: ["#12A58C", "#E0AF00", "#F6285F"],
        },
      ],
    },

    coupons: {
      labels: data?.coupons?.map((item) => item?.desc),
      datasets: [
        {
          label: "Coupons & Events",
          data: data?.coupons?.map((item) => item?.total),
          backgroundColor: ["#E0AF00", "#F6285F", "#F6285F"],
        },
      ],
    },
    events: {
      labels: data?.events?.map((item) => item?.desc),
      datasets: [
        {
          label: "Events",
          data: data?.events?.map((item) => item?.total),
          backgroundColor: ["#E0AF00", "#F6285F", "#F6285F"],
        },
      ],
    },
    i20_students: {
      labels: data?.i20_students?.map((item) => item?.desc),
      datasets: [
        {
          label: "I20 Students",
          data: data?.i20_students?.map((item) => item?.total),
          backgroundColor: ["#2E86C1", "#AED6F1", "#5DADE2"],
        },
      ],
    },
    estu_app: {
      labels: estuAppWithPercentages?.map((item) => item?.desc),
      datasets: [
        {
          label: "Estu Customers",
          data: estuAppWithPercentages?.map((item) => item?.percentage),
          backgroundColor: ["#12A58C", "#2CCCD3", "#dcde4a", "#F39C12"],
        },
      ],
    },
    totalInstitutions: {
      labels: ["Total Institutions"],
      datasets: [
        {
          label: "Institutions",
          data: [
            data?.estu_app?.find((item) => item?.name === "totalInstitutions")
              ?.total || 0,
          ],
          backgroundColor: ["#E0AF00"],
        },
      ],
    },
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Analytics Chart",
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context?.label || "";
            const value = context?.raw;
            return `${label}: ${value}%`;
          },
        },
      },
    },
  };

  return (
    <WrapperOutlet headingText={"Dashboard"}>
      <div className="flex flex-col flex-1 no-scrollbar">
        <DashboardSection
          title={"Partners"}
          data={data?.partners}
          type="partner"
        />
        <DashboardSection
          title={"Coupon & events"}
          data={data?.coupons}
          type="coupon"
        />
        <DashboardSection data={data?.events} type="event" />
        <DashboardSection
          title={"International Students"}
          data={data?.i20_students}
          type="i20_students"
        />
        <DashboardSection
          title={"Estu Customers"}
          data={data?.estu_app}
          type="estu_app"
        />

        <div className="mt-6 overflow-x-scroll">
          <h3 className="text-lg font-bold mb-4">Analytics Charts</h3>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-white shadow-md rounded-lg p-4 chart-wrapper">
              <h3 className="text-lg font-bold text-gray-700 mb-4">
                Estu Customers (
                {data?.estu_app?.find(
                  (item) => item?.name === "total_customers"
                )?.total || 0}
                )
              </h3>

              <ChartComponent
                type="pie"
                data={chartData?.estu_app}
                options={chartOptions}
              />
            </div>
          </div>
        </div>
      </div>
    </WrapperOutlet>
  );
};

export default DashboardPage;
