import React from "react";
import SoftDialog from "../../../components/molecules/dialogs/index";
import SnackBar from "../../../components/atoms/Snackbar";
import { useStateContext } from "../../../contexts/ContextProvider";
import EstuModal from "../../../components/ui/Modal/EstuModal/EstuModal";

export default function ErrorlogsDialog({ open, handleClose, data }) {
  const { setSnackbar } = useStateContext();
  return (
    <EstuModal title={"Errors"} open={open} handleClose={handleClose}>
      <div className="w-96 max-h-96 p-8 rounded-lg flex flex-col gap-3 items-center justify-center">
        <table className="w-full border-collapse border border-gray-300">
          <thead>
            <tr className="bg-gray-100">
              <th className="border border-gray-300 py-2">Error Object</th>
              <th className="border border-gray-300 py-2">Step</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data?.map((item, index) => (
                <tr key={index} className="hover:bg-gray-100">
                  <td className="border border-gray-300 p-2 max-h-30 overflow-y-scroll">
                    <button
                      onClick={() => {
                        navigator.clipboard.writeText(item?.error_object);
                        setSnackbar({
                          active: true,
                          status: "success",
                          message: "Copied to Clipboard",
                        });
                      }}
                      className="w-10 h-10 bg-black text-white"
                    >
                      Copy
                    </button>
                  </td>
                  <td className="border border-gray-300 p-2">{item?.step}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <SnackBar />
    </EstuModal>
  );
}
