import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DownloadCSV from "../../../../shared/downloadCSV/DownloadCSV";
import { CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import CDataGrid from "../../../../components/ui/CDataGrid/CDataGrid";
import DotLoading from "../../../../components/ui/Loading/DotLoading";

const ContentBoard = ({ selectedStatus, csvData, columns, loading }) => {
  return (
    <div className="flex h-full justify-center flex-col gap-9">
      <div className="justify-self-end flex justify-end items-center">
        <DownloadCSV
          fileName={selectedStatus}
          columns={columns}
          data={csvData}
          fieldsToInclude={[
            "email",
            "phoneNumber",
            "headline",
            "smsMessage",
            "subject",
            "emailBody",
            "image",
            "status",
          ]}
        />
      </div>

      <div className="flex-grow">
        {loading ? (
          <div className="h-full flex flex-col justify-center items-center">
            <DotLoading />
          </div>
        ) : (
          <ContentTable data={csvData} columns={columns} />
        )}
      </div>
    </div>
  );
};

const ContentTable = ({ data = [], columns }) => {
  const [filter, setFilter] = useState("All");
  const [loading, setLoading] = useState(true);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setLoading(true);
    setFilteredData(data);
    setLoading(false);
  }, [data, filter]);

  return (
    <div
      className="data-grid-container flex-grow h-full"
      // style={{ height: 500, width: "100%" }}
    >
      {loading ? (
        <div className="flex items-center justify-center w-full h-full">
          <DotLoading />
        </div>
      ) : (
        <CDataGrid
          rows={filteredData}
          pagination
          columns={columns}
          sx={{
            boxShadow: 2,
            border: 2,
            padding: 1,
            backgroundColor: "white",
            borderColor: "primary.light",
            "& .MuiDataGrid-cell:hover": {},
          }}
          getRowId={(row) => row?.id || row?.email}
        />
      )}
    </div>
  );
};

export default ContentBoard;
