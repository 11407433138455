import { CircularProgress } from "@mui/material";
import { UploadIcon } from "../../assets/svgs/svgs";

const AddEditMedia = ({
	formData,
	handleChange,
	addLoading,
	handleSubmit,
	handleBannerUpload,
	buttonText,
	handleOnBlurDate = () => {},
	validForm = false,
	errors,
	handleOnBlur,
	bannerLoading,
}) => {
	return (
		<div className='flex flex-col gap-6 h-full'>
			<div className='flex flex-col gap-6 overflow-y-scroll max-h-[70vh] px-2'>
				{/* Outlet Input */}
				<div className='flex justify-start items-start gap-4 flex-col'>
					<label
						htmlFor='outlet'
						className='block text-sm font-bold font-montserrat text-gray-600'
					>
						Media Outlet&nbsp;*
					</label>
					<div className='w-full'>
						<input
							type='text'
							id='outlet'
							name='outlet'
							placeholder='Enter media outlet name'
							className='w-full py-2 px-3.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2cccd3]'
							value={formData?.outlet}
							onChange={handleChange}
							onBlur={handleOnBlur}
						/>
						{errors?.outlet && (
							<p className='text-red-500 pl-1 text-sm mt-1'>{errors?.outlet}</p>
						)}
					</div>
				</div>

				{/* Headline Input */}
				<div className='flex justify-start items-start gap-4 flex-col'>
					<label
						htmlFor='headline'
						className='block text-sm font-bold font-montserrat text-gray-600'
					>
						Headline&nbsp;*
					</label>
					<div className='w-full'>
						<input
							type='text'
							id='headline'
							name='headline'
							placeholder='Enter news headline'
							className='w-full py-2 px-3.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2cccd3]'
							value={formData?.headline}
							onChange={handleChange}
							onBlur={handleOnBlur}
						/>
						{errors?.headline && (
							<p className='text-red-500 pl-1 text-sm mt-1'>
								{errors?.headline}
							</p>
						)}
					</div>
				</div>

				{/* Website URL Input */}
				<div className='flex justify-start items-start gap-4 flex-col'>
					<label
						htmlFor='newsLink'
						className='block text-sm font-bold font-montserrat text-gray-600 mb-2'
					>
						Website&nbsp;*
					</label>
					<div className='w-full'>
						<input
							type='url'
							id='newsLink'
							name='newsLink'
							value={formData?.newsLink}
							onChange={handleChange}
							onBlur={handleOnBlur}
							placeholder='eg:- https://www.estulife.com or www.estulife.com'
							className='w-full py-2 px-3.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2cccd3]'
							// pattern="https?://.*"
							title='Please enter a valid URL starting with http:// or https://'
						/>
						{errors?.newsLink && (
							<p className='text-red-500 pl-1 text-sm mt-1'>
								{errors?.newsLink}
							</p>
						)}
					</div>
				</div>

				{/* Publish Date Input */}
				<div className='flex justify-start items-start gap-4 flex-col'>
					<label
						htmlFor='publish_date'
						className='block text-sm font-bold font-montserrat text-gray-600'
					>
						Publish Date&nbsp;*
					</label>
					<div className='w-full'>
						<input
							type='date'
							id='publish_date'
							name='publish_date'
							className='w-full py-2 px-3.5 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2cccd3]'
							value={formData?.publish_date}
							onChange={handleChange}
							onBlur={handleOnBlurDate}
							min='1999-01-01'
							max='2050-12-31'
						/>
						{errors?.publish_date && (
							<p className='text-red-500 pl-1 text-sm mt-1'>
								{errors?.publish_date}
							</p>
						)}
					</div>
				</div>

				{/* Logo Upload */}
				<div className='flex justify-start items-start gap-4 flex-col w-full'>
					<label
						htmlFor='logo'
						className='block text-sm font-bold font-montserrat text-gray-600 mb-2'
					>
						Logo&nbsp;*
					</label>
					<div className='relative w-full'>
						{!bannerLoading && (
							<input
								type='file'
								id='banner'
								className='absolute inset-0 opacity-0 cursor-pointer'
								accept='image/*'
								onChange={handleBannerUpload}
								onBlur={handleOnBlur}
								disabled={bannerLoading}
							/>
						)}

						{formData?.banner ? (
							<img
								src={`${process.env.REACT_APP_IMAGE_BASEURL}${formData.banner}`}
								alt='Banner Preview'
								className='w-full min-h-[80px] border-white border-2 min-w-[80px] h-auto rounded-md'
								style={{ maxHeight: "200px" }} // Restricting max height for the image
							/>
						) : (
							<div className='flex items-center w-full justify-start py-2 px-3.5 border border-dashed border-gray-300 rounded-lg bg-white cursor-pointer hover:border-[#2cccd3]'>
								<span className='text-[#2cccd3] mr-2'>
									<UploadIcon />
								</span>
								<span className='text-sm font-bold font-roboto text-[#2cccd3] w-full'>
									{bannerLoading ? "Uploading..." : "Upload Image"}
								</span>
							</div>
						)}
						{errors?.banner && (
							<p className='text-red-500 pl-1 text-sm mt-1'>{errors?.banner}</p>
						)}
					</div>
				</div>
			</div>
			<div className='px-2'>
				{/* Submit Button */}
				<button
					onClick={handleSubmit}
					disabled={addLoading || !validForm || bannerLoading}
					className='py-3.5 text-center w-full disabled:bg-[#bec9ca] disabled:cursor-not-allowed bg-[#2cccd3] text-white rounded-lg font-roboto font-bold font-montserrat text-sm'
				>
					{addLoading ? (
						<span className='flex items-center justify-center gap-2 flex-row'>
							<CircularProgress size={"20px"} style={{ color: "#ffffff" }} />{" "}
							Loading...
						</span>
					) : buttonText ? (
						buttonText
					) : (
						"Upload News"
					)}
				</button>
			</div>
		</div>
	);
};

export default AddEditMedia;
