import classNames from "classnames";
import React, { useState } from "react";
import SearchIconRound from "../../../assets/imgs/search_rainbow_round.png";
const SearchBoxWithControls = ({
  iconVisible = true,
  buttonVisible = true,
  onSubmit,
  onChange,
  placeholder = "Search...",
  buttonText = "Search",
  errorMessage = "",
  placeholderCss = "",
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e.target.value);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (onSubmit) {
      if (onSubmit(inputValue)) {
        setInputValue("");
      }
    }
  };

  return (
    <div>
      <div className="flex items-center gap-2 justify-between">
        {iconVisible && (
          <div className="flex items-center max-w-9 max-h-9">
            <img src={SearchIconRound} alt="logo" />
          </div>
        )}

        <div className="flex-1 relative">
          <form onSubmit={handleFormSubmit}>
            <input
              type="text"
              placeholder={placeholder}
              value={inputValue}
              onChange={handleInputChange}
              style={{
                border: errorMessage ? "1px solid red" : "",
              }}
              className={classNames(
                "bg-[#F2F8FA] w-full p-2.5 outline-none rounded-lg",
                placeholderCss
              )}
            />
          </form>
        </div>

        {/* Button */}
        {buttonVisible && (
          <button
            onClick={handleFormSubmit}
            className="bg-[#2cccd3] text-white rounded-lg text-center p-2.5 cursor-pointer font-montserrat text-xs font-bold h-full"
          >
            {buttonText}
          </button>
        )}
      </div>
      {errorMessage && (
        <span className="text-red-500 text-xs mt-1 mx-auto w-fit block">
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default SearchBoxWithControls;
