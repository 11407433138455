import React from "react";
import ColorButton from "../../../../components/atoms/Button/ColorButton/ColorButton";

const RadioButton = ({
  isChecked = false,
  onClick,
  buttonText = "Click Me",
  buttonColor = "#38415F",
  buttonBgColor = "#E8F0F2",
}) => {
  const onButtonClick = () => {};
  return (
    <div
      className="flex flex-row gap-2 items-center justify-between cursor-pointer w-full group"
      onClick={onClick}
    >
      {/* Radio Input */}
      <div>
        <input
          type="radio"
          checked={isChecked}
          name={buttonText}
          className="appearance-none w-3 h-3 border-2 border-[#2cccd3] rounded-full checked:border-[#2cccd3] checked:bg-[#2cccd3] focus:outline-none pointer-events-none"
        />
      </div>

      <ColorButton
        className={"w-full"}
        text={buttonText}
        color={buttonColor}
        bgColor={buttonBgColor}
        onClick={onButtonClick}
      />
    </div>
  );
};

export default RadioButton;
