import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { MdDeleteOutline } from "react-icons/md";
import { CSS } from "@dnd-kit/utilities";

const DraggableItem = ({ item, handleDeleteTopBrand, index }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.user_id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      // draggable
      {...listeners}
      {...attributes}
      style={style}
      // className='relative bg-white hover:bg-[#f2f2f3] touch-none w-[80px]'
    >
      <div className="bg-white w-[80px] border-1 border-[#92A9AD] rounded-lg overflow-hidden h-28 flex flex-col items-center justify-center">
        <div className="flex gap-2 justify-between items-center bg-[#2CCCD3] text-white py-1 px-2 text-center w-full">
          <span>{index + 1}</span>
          <button onClick={() => handleDeleteTopBrand(item.id)}>
            <MdDeleteOutline color="#fff" />
          </button>
        </div>
        <div className="h-full w-full flex items-center justify-center">
          <p className="p-2 text-ellipsis whitespace-nowrap overflow-hidden text-sm font-medium">
            {item.brand_name}
          </p>
        </div>
      </div>
      {/* <button onClick={() => onDelete(item.id)}>Delete</button> */}
    </div>
  );
};

export default DraggableItem;
