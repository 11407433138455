import { genPreviewSwitchStyle } from "antd/es/image/style";
import React from "react";
import { CameraIcon } from "../../../../assets/svgs/svgs";

const Preview = ({
  handleClosePreview = () => {},
  logo,
  selectedCampaign,
  campaignOffer,
}) => {
  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50  flex justify-center items-center z-50"
      onClick={() => handleClosePreview()}
    >
      <div
        className="relative max-w-sm min-w-[384px] overflow-hidden cursor-pointer p-4 rounded-lg flex flex-col gap-5 bg-white"
        onClick={(e) => {
          e.stopPropagation();
        }}
        style={{ color: "#FFFFFF", backgroundColor: "#2CCBD1" }}
      >
        {/* Background Image */}
        <div className="absolute inset-0 left-0 top-0 opacity-90 z-10">
          <img
            src={logo}
            alt="Background Logo"
            loading="lazy"
            className="h-full w-[180px]"
            style={{ objectFit: "contain" }}
          />
        </div>
        <div className="flex justify-between gap-4 max-h-40 z-20">
          <div className="flex flex-col gap-2 flex-1">
            <p
              className="font-montserrat text-base font-extrabold line-clamp-2 overflow-hidden text-ellipsis"
              style={{ color: "#FFFFFF" }}
              title={selectedCampaign?.headline || "---"}
            >
              {selectedCampaign?.headline || "---"}
            </p>
            <p
              className="font-montserrat text-sm font-semibold line-clamp-2 overflow-hidden text-ellipsis"
              style={{ color: "#FFFFFF" }}
              title={selectedCampaign?.description || "---"}
            >
              {selectedCampaign?.description || "---"}
            </p>
          </div>
          <div className="flex flex-col gap-2 w-12 h-12 justify-center items-center self-stretch rounded bg-transparent">
            {selectedCampaign?.banner ? (
              <img
                className="w-[48px] h-[48px]  rounded-[10px] inset-2 cursor-pointer hover:scale-105 duration-300"
                src={`${process.env.REACT_APP_IMAGE_BASEURL}${selectedCampaign?.banner}`}
                alt="logo"
              />
            ) : (
              <div>
                <CameraIcon />
              </div>
            )}
          </div>
        </div>
        {/* button start */}
        <div className="flex justify-between items-end relative z-20">
          <button
            className="font-semibold text-xs px-4 py-2 flex-1 rounded-lg shadow-md hover:scale-105 duration-300 active:scale-100"
            style={{ color: "#FFFFFF", backgroundColor: "#58D8DD" }}
          >
            Activate your offer
          </button>
          <img
            src={campaignOffer}
            alt="offer"
            className="w-[80px] h-[80px] -translate-y-1 animate-bounce"
          />
        </div>
      </div>
    </div>
  );
};

export default Preview;
