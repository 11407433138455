import React, { useEffect, useState } from "react";
import InputComponent from "./atoms/InputComponent";
import { toast } from "react-toastify";
import { getCharges, saveCharges } from "../api/api";
import { FaSpinner } from "react-icons/fa";

const ConvenienceFee = () => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [formData, setFormData] = useState({
		convenienceFee: "",
		salesTax: "",
	});
	const [fetchCharges, setFetchCharges] = useState(true);

	const getAPI = async () => {
		setLoading(true);
		try {
			const response = await getCharges();
			setData(response?.data || {});
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!formData.convenienceFee || !formData.salesTax) {
			toast.error("Please fill all the fields");
			return;
		}
		setLoading(true);
		try {
			const response = await saveCharges(formData);
			if (response.success) {
				toast.success(response.message);
				setFetchCharges(true);
				setFormData({
					convenienceFee: "",
					salesTax: "",
				});
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (fetchCharges) {
			getAPI();
			setFetchCharges(false);
		}
	}, [fetchCharges]);

	return (
		<div className='w-full h-full'>
			<div className='border border-[#E3E3E3] rounded-lg min-h-[30rem] px-4 md:px-9 py-4 md:py-9 flex flex-col md:gap-10 gap-6'>
				<h4 className='font-montserrat md:text-xl text-lg font-semibold text-[#2C3030]'>
					Specify the additional fee for partners creating event tickets in
					estuads:
				</h4>
				<form
					onSubmit={handleSubmit}
					className='flex flex-col gap-4 max-w-[80%]'
				>
					<div className='flex flex-col md:flex-row gap-4'>
						<InputComponent
							label={"Convenience fee (% of total ticket price)"}
							widthFull
							name='convenienceFee'
							placeholder={"Enter percentage"}
							value={formData.convenienceFee}
							onChange={handleInputChange}
							type={"number"}
							disabled={loading}
							id={"convenienceFee"}
							onInput={(e) => {
								if (e.target.value) {
									e.target.value = Math.abs(e.target.value);
								}
							}}
						/>
						<InputComponent
							label={"Sales tax (% of total ticket price)"}
							widthFull
							name='salesTax'
							placeholder={"Enter percentage"}
							value={formData.salesTax}
							onChange={handleInputChange}
							type={"number"}
							disabled={loading}
							id={"salesTax"}
							onInput={(e) => {
								if (e.target.value) {
									e.target.value = Math.abs(e.target.value);
								}
							}}
						/>
					</div>
					<button
						disabled={loading || !formData.convenienceFee || !formData.salesTax}
						className='button_primary'
					>
						{loading ? (
							<>
								<FaSpinner className={`animate-spin text-white text-sm`} />{" "}
								Saving...
							</>
						) : (
							"Save"
						)}
					</button>
				</form>
				<hr />
				{data?.CONVENIENCE_FEE || data?.SALES_TAX ? (
					<div className='flex flex-col md:gap-6 gap-4'>
						<p>Present value:</p>
						<div className='flex flex-row gap-4'>
							<div className='flex flex-col gap-4'>
								{data?.CONVENIENCE_FEE && <p>Convenience fee:</p>}
								{data?.SALES_TAX && <p>Sales tax:</p>}
							</div>
							<div className='flex flex-col gap-4'>
								{data?.CONVENIENCE_FEE && <p>{data?.CONVENIENCE_FEE}%</p>}
								{data?.SALES_TAX && <p>{data?.SALES_TAX}%</p>}
							</div>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default ConvenienceFee;
