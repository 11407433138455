import React from "react";
import { CameraAltRounded } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "../../../../utils/dayjs";

import { handleNonNegativeChange } from "../../../../utils/utils";
const OpenAndEdit = ({
  handleSubmit = () => {},
  viewMode,
  setFormData = () => {},
  formData,
  isUpdateState,
  handleCloseModal = () => {},
  handleEndDateChange = () => {},
  handleStartDateChange = () => {},
  handleBannerUpload = () => {},
  codes = [],
}) => {
  const CustomRadio = styled(Radio)(({ theme }) => ({
    color: "#2cccd3",
    "&.Mui-checked": {
      color: "#2cccd3",
    },
  }));
  const handleTypeChange = (event) => {
    const type = event.target.value;
    console.log(type);

    if (type === "percentage") {
      setFormData({
        ...formData,
        type,
        discountPercentage: "",
        maxDiscountAmount: "",
        fixedAmount: 0,
      });
    } else if (type === "fixed") {
      setFormData({
        ...formData,
        type,
        discountPercentage: 0,
        maxDiscountAmount: 0,
        fixedAmount: "",
      });
    } else {
      setFormData({
        ...formData,
        type,
        discountPercentage: "",
        maxDiscountAmount: "",
        fixedAmount: "",
      });
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50  flex justify-center items-center z-50">
      <div className="bg-white p-8 rounded shadow-lg w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">
          {viewMode ? "View Campaign" : "Create Campaign"}
        </h2>
        <form className="" onSubmit={handleSubmit}>
          <div className="max-h-[70vh] overflow-y-scroll no-scrollbar">
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="name">
                Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter name"
                className="w-full p-2 border rounded"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                required
                disabled={viewMode}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="headline">
                Headline
              </label>
              <input
                type="text"
                id="headline"
                placeholder="Enter headline"
                className="w-full p-2 border rounded"
                value={formData.headline}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    headline: e.target.value,
                  })
                }
                required
                disabled={viewMode}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="description">
                Description
              </label>
              <textarea
                id="description"
                placeholder="Enter description"
                className="w-full p-2 border rounded"
                value={formData.description}
                rows={4}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    description: e.target.value,
                  })
                }
                required
                disabled={viewMode}
              ></textarea>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 mb-2"
                htmlFor="transactionCode"
              >
                Campaign Brand
              </label>
              <FormControl fullWidth>
                <InputLabel id="transactionCode-label">
                  Select Campaign Brand
                </InputLabel>
                <Select
                  labelId="transactionCode-label"
                  id="transactionCode"
                  value={formData.transactionCode}
                  label="Select Campaign Brand"
                  required
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      transactionCode: e.target.value,
                    })
                  }
                  disabled={viewMode}
                >
                  {codes.map((code) => (
                    <MenuItem key={code.id} value={code.id}>
                      {code.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="banner">
                Banner Image
              </label>
              <input
                id="banner"
                type="file"
                onChange={handleBannerUpload}
                className={
                  formData?.banner
                    ? "hidden"
                    : isUpdateState
                    ? "hidden"
                    : "block"
                }
              />
              {formData.banner && (
                <div
                  className="relative group cursor-pointer mt-4"
                  onClick={() => document.getElementById("banner").click()}
                  style={{ display: "inline-block", maxWidth: "100%" }}
                >
                  <img
                    src={`${process.env.REACT_APP_IMAGE_BASEURL}${formData.banner}`}
                    alt="Banner Preview"
                    className="w-full min-h-[80px] min-w-[80px] h-auto rounded-md"
                    style={{ maxHeight: "200px" }} // Restricting max height for the image
                  />

                  <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300">
                    <CameraAltRounded
                      className="h-12 w-12 text-white opacity-0
                        group-hover:opacity-100 transition duration-300"
                      fontSize="large"
                      color="white"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-1">Type</label>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={formData.type || "percentage"}
                  onChange={handleTypeChange}
                >
                  <FormControlLabel
                    value="percentage"
                    control={<CustomRadio />}
                    label="Percentage"
                    disabled={viewMode}
                  />
                  <FormControlLabel
                    value="fixed"
                    control={<CustomRadio />}
                    label="Fixed"
                    disabled={viewMode}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            {formData.type === "fixed" ? (
              <div className="mb-4">
                <label
                  className="block text-gray-700 mb-2"
                  htmlFor="fixedAmount"
                >
                  Fixed Amount
                </label>
                <input
                  type="number"
                  id="fixedAmount"
                  placeholder="Enter fixed amount"
                  className="w-full p-2 border rounded"
                  value={formData.fixedAmount}
                  onChange={(e) =>
                    handleNonNegativeChange(e, (value) =>
                      setFormData({ ...formData, fixedAmount: value })
                    )
                  }
                  required={formData?.type !== "percentage"}
                  disabled={viewMode}
                />
              </div>
            ) : (
              <>
                {" "}
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="discountPercentage"
                  >
                    Discount Percentage (%)
                  </label>
                  <input
                    type="number"
                    id="discountPercentage"
                    placeholder="Enter discount percentage"
                    className="w-full p-2 border rounded"
                    value={formData.discountPercentage}
                    onChange={(e) =>
                      handleNonNegativeChange(e, (value) =>
                        setFormData({
                          ...formData,
                          discountPercentage: value,
                        })
                      )
                    }
                    required={formData?.type === "percentage"}
                    disabled={viewMode}
                  />
                </div>
                <div className="mb-4">
                  <label
                    className="block text-gray-700 mb-2"
                    htmlFor="maxDiscountAmount"
                  >
                    Maximum Discount Amount
                  </label>
                  <input
                    type="number"
                    id="maxDiscountAmount"
                    placeholder="Enter maximum discount amount"
                    className="w-full p-2 border rounded"
                    value={formData.maxDiscountAmount}
                    onChange={(e) =>
                      handleNonNegativeChange(e, (value) =>
                        setFormData({
                          ...formData,
                          maxDiscountAmount: value,
                        })
                      )
                    }
                    required={formData?.type === "percentage"}
                    disabled={viewMode}
                  />
                </div>
              </>
            )}
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="totalBudget">
                Minimum Order Amount
              </label>
              <input
                type="number"
                id="minOrderAmount"
                placeholder="Enter minimum order amount"
                className="w-full p-2 border rounded"
                value={formData.minOrderAmount}
                onChange={(e) =>
                  handleNonNegativeChange(e, (value) =>
                    setFormData({
                      ...formData,
                      minOrderAmount: value,
                    })
                  )
                }
                required
                disabled={viewMode}
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 mb-2" htmlFor="totalBudget">
                Total Budget
              </label>
              <input
                type="number"
                id="totalBudget"
                placeholder="Enter total budget"
                className="w-full p-2 border rounded"
                value={formData.totalBudget}
                onChange={(e) =>
                  handleNonNegativeChange(e, (value) =>
                    setFormData({
                      ...formData,
                      totalBudget: value,
                    })
                  )
                }
                required
                disabled={viewMode}
              />
            </div>

            <div className="mb-4 flex justify-between items-center gap-3">
              <DatePicker
                label="Start Date"
                value={
                  formData.startDate
                    ? dayjs(formData.startDate, "YYYY-MM-DD")
                    : null
                }
                onChange={handleStartDateChange}
                renderInput={(params) => <TextField {...params} />}
                minDate={!isUpdateState ? dayjs() : undefined}
                maxDate={dayjs("9999-12-31")}
              />
              <DatePicker
                label="End Date"
                value={formData.endDate ? dayjs(formData.endDate) : null}
                onChange={handleEndDateChange}
                format="DD/MM/YYYY"
                renderInput={(params) => <TextField {...params} />}
                minDate={
                  !isUpdateState
                    ? formData.startDate
                      ? dayjs(formData.startDate)
                      : dayjs()
                    : undefined
                }
                maxDate={dayjs("9999-12-31")}
              />
            </div>
          </div>

          <div className="flex justify-end bg-white pt-4 border-t-2">
            <button
              type="button"
              className="bg-red-500 text-white px-4 py-2 rounded mr-2"
              onClick={handleCloseModal}
            >
              {viewMode ? "Close" : "Cancel"}
            </button>
            {!viewMode && (
              <button
                type="submit"
                className="bg-[#2cccd3] text-white px-4 py-2 rounded"
              >
                {isUpdateState ? "Update" : "Save"}
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default OpenAndEdit;
