import React, { useState, useEffect, useRef } from "react";
import style from "./Threads.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import { SendThreadIcon, AttachmentIcon } from "../../../assets/svgs/svgs";
import { GetMessages, GetUserProfile, Getthreads } from "../../../api/api";
import { calculateElapsedTime } from "../../../utils/dateCalculator";
import { generateImageUrl } from "../../../utils/func";

export default function Threads() {
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [socket, setSocket] = useState(null);
  const [activeChats, setActiveChats] = useState([]);
  const userId = location.search.split("=")[1];
  const [selectedImage, setSelectedImage] = useState();

  const fileRef = useRef();

  useEffect(() => {
    if (userId) {
      const socket = io(process.env.REACT_APP_BASEURL);
      setSocket(socket);

      socket.on(userId, (message) => {
        setChats((prevMessages) => [
          ...prevMessages,
          {
            message: message.message,
            is_sent_by_moderator: message?.is_sent_by_moderator,
            isUserLastMessage: false,
            isSameUser: false,
            chat_type:
              message?.is_sent_by_moderator === true ? "sent" : "reply",
            chat_time: message?.createdAt?.split("T")[1].slice(0, 5),
          },
        ]);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetUserProfile(userId);
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [location]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const messages = await GetMessages(userId);
        const dataChats = messages.map((message, index) => {
          return {
            message: message.message,
            is_sent_by_moderator: message.is_sent_by_moderator,
            isUserLastMessage:
              index !== 0 &&
              messages[index - 1].is_sent_by_moderator ===
                message.is_sent_by_moderator
                ? false
                : true,
            isSameUser:
              index !== 0 &&
              messages[index - 1].is_sent_by_moderator ===
                message.is_sent_by_moderator
                ? true
                : false,
            chat_type: message.is_sent_by_moderator ? "sent" : "reply",
            chat_time: message.createdAt.split("T")[1].slice(0, 5),
          };
        });

        setChats(dataChats.reverse());
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchMessages();
  }, [userId, location]);

  const sendMessage = () => {
    if (message.length > 0) {
      socket.emit("chat message", {
        message: message,
        user_id: userId,
        moderator_id: `1`,
        type: "text", // media
        is_sent_by_moderator: true,
      });

      const lastMessageIndex = chats.length - 1;
      setChats((prevMessages) => [
        ...prevMessages.slice(0, lastMessageIndex),
        {
          ...prevMessages[lastMessageIndex],
          isUserLastMessage: false,
          isSameUser:
            prevMessages[lastMessageIndex - 1]?.is_sent_by_moderator ?? false,
        },
        {
          message: message,
          is_sent_by_moderator: true,
          isUserLastMessage: true,
          isSameUser: true,
          chat_type: "sent",
          chat_time: new Date().toLocaleTimeString().slice(0, 5),
        },
      ]);
      setMessage("");
    } else {
      alert("Message cannot be empty");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const threadsData = await Getthreads();
        setActiveChats(threadsData);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [location]);

  const OpenFile = () => {
    fileRef.current && fileRef.current.click();
  };

  return (
    <div className="flex flex-col w-full h-full">
      <div className={"title_threads_top gap-1 flex flex-row items-center"}>
        <p onClick={(_) => navigate("/ads")}>Ads manager</p>/
        <p onClick={(_) => navigate("/adsprofile")}>Profiles</p>
        /Threads
      </div>
      <div className={style.container}>
        <div className={style.chats_list}>
          <div className={style.top}>
            <div className={style.title}>
              Messages 🔽
              <div className={style.badge}>{activeChats.length}</div>
            </div>
          </div>
          <div className={style.list}>
            {activeChats.map((data) => (
              <ChatCard
                generateImageUrl={generateImageUrl}
                data={data}
                active={userId === data.user_id ? true : false}
                navigate={navigate}
              />
            ))}
          </div>
        </div>
        <div className={style.message_seaction}>
          <div className={style.top}>
            <div className={style.chat_name}>
              <div className={style.user_profile}>
                <img src={generateImageUrl(data?.profilePhoto)} />
              </div>
              <div className={style.user_name}>
                <p>{data?.instituteName}</p>
              </div>
            </div>
          </div>
          <div className={style.messages}>
            {chats.map((chat) => (
              <Chat
                chat={chat}
                data={data}
                image={generateImageUrl(data?.profilePhoto)}
              />
            ))}
            <p id="121"></p>
          </div>
          <div className={style.send_message_section}>
            <div className={style.messageContainer_send}>
              <div onClick={OpenFile}>
                <AttachmentIcon />
              </div>
              <input type="file" ref={fileRef} hidden />
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                rows={
                  message?.length < 100
                    ? 1
                    : message?.length < 200
                    ? 2
                    : message?.length < 300
                    ? 3
                    : 5
                }
                cols="100"
                placeholder={"Message"}
                className={style.message_input}
              ></textarea>
              <button onClick={sendMessage} className={style.sendThread}>
                <SendThreadIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const ChatCard = ({ active, data, navigate, generateImageUrl }) => (
  <div
    onClick={(_) => navigate(`/threads?chat=${data.user_id}#121`)}
    className={`${style.chat_view_card} ${active ? style.active_card : ""}`}
  >
    <div className={style.user_info}>
      <div className={style.profile}>
        {data?.user?.profilePhoto && (
          <img src={generateImageUrl(data?.user?.profilePhoto)} />
        )}
      </div>
      <div className={style.details}>
        <div className={style.title}>
          <p>{data?.user?.instituteName}</p>
        </div>
        <div className={style.des}>
          <p>
            {data.lastMessage.message?.length > 20
              ? `${data.lastMessage.message?.slice(0, 17)}...`
              : data.lastMessage.message}
          </p>
        </div>
      </div>
    </div>
    <div className={style.active_time}>
      <p>{calculateElapsedTime(data.updatedAt)}</p>
    </div>
  </div>
);

export const Chat = ({ chat, data, image }) => (
  <div className={style.chat_container}>
    <div
      className={`${style.chat} ${
        !chat.is_sent_by_moderator ? null : style.anotherchat
      } ${chat.chat_type === "reply" ? style.replay_message : ""}`}
    >
      <div className={`${style.profile} `}>
        {!chat.isSameUser ? <img src={image} /> : ""}
      </div>
      <div className={style.chat_content}>
        <div className={style.message}>{chat.message}</div>
        {chat.isUserLastMessage ? (
          <div className={style.chat_time}>{chat.chat_time}</div>
        ) : null}
      </div>
    </div>
  </div>
);
