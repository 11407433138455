import classNames from "classnames";
import React from "react";

const ColorButton = ({
  text,
  className,
  color,
  bgColor,
  children,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        `px-4 py-1 rounded-full group-hover:shadow-sm overflow-hidden`,
        className
        // bgColor && `bg-[${bgColor}]`,
        // color && `text-[${color}]`
      )}
      {...rest}
      style={{
        color: color || undefined,
        backgroundColor: bgColor || undefined,
      }}
    >
      {children || text}
    </button>
  );
};

export default ColorButton;
